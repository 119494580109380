export const arrayOfnames = [
  'Aaran',
  'Aaren',
  'Aarez',
  'Aarman',
  'Aaron',
  'Aaron-James',
  'Aarron',
  'Aaryan',
  'Aaryn',
  'Aayan',
  'Aazaan',
  'Abaan',
  'Abbas',
  'Abdallah',
  'Abdalroof',
  'Abdihakim',
  'Abdirahman',
  'Abdisalam',
  'Abdul',
  'Abdul-Aziz',
  'Abdulbasir',
  'Abdulkadir',
  'Abdulkarem',
  'Abdulkhader',
  'Abdullah',
  'Abdul-Majeed',
  'Abdulmalik',
  'Abdul-Rehman',
  'Abdur',
  'Abdurraheem',
  'Abdur-Rahman',
  'Abdur-Rehmaan',
  'Abel',
  'Abhinav',
  'Abhisumant',
  'Abid',
  'Abir',
  'Abraham',
  'Abu',
  'Abubakar',
  'Ace',
  'Adain',
  'Adam',
  'Adam-James',
  'Addison',
  'Addisson',
  'Adegbola',
  'Adegbolahan',
  'Aden',
  'Adenn',
  'Adie',
  'Adil',
  'Aditya',
  'Adnan',
  'Adrian',
  'Adrien',
  'Aedan',
  'Aedin',
  'Aedyn',
  'Aeron',
  'Afonso',
  'Ahmad',
  'Ahmed',
  'Ahmed-Aziz',
  'Ahoua',
  'Ahtasham',
  'Aiadan',
  'Aidan',
  'Aiden',
  'Aiden-Jack',
  'Aiden-Vee',
  'Aidian',
  'Aidy',
  'Ailin',
  'Aiman',
  'Ainsley',
  'Ainslie',
  'Airen',
  'Airidas',
  'Airlie',
  'AJ',
  'Ajay',
  'A-Jay',
  'Ajayraj',
  'Akan',
  'Akram',
  'Al',
  'Ala',
  'Alan',
  'Alanas',
  'Alasdair',
  'Alastair',
  'Alber',
  'Albert',
  'Albie',
  'Aldred',
  'Alec',
  'Aled',
  'Aleem',
  'Aleksandar',
  'Aleksander',
  'Aleksandr',
  'Aleksandrs',
  'Alekzander',
  'Alessandro',
  'Alessio',
  'Alex',
  'Alexander',
  'Alexei',
  'Alexx',
  'Alexzander',
  'Alf',
  'Alfee',
  'Alfie',
  'Alfred',
  'Alfy',
  'Alhaji',
  'Al-Hassan',
  'Ali',
  'Aliekber',
  'Alieu',
  'Alihaider',
  'Alisdair',
  'Alishan',
  'Alistair',
  'Alistar',
  'Alister',
  'Aliyaan',
  'Allan',
  'Allan-Laiton',
  'Allen',
  'Allesandro',
  'Allister',
  'Ally',
  'Alphonse',
  'Altyiab',
  'Alum',
  'Alvern',
  'Alvin',
  'Alyas',
  'Amaan',
  'Aman',
  'Amani',
  'Ambanimoh',
  'Ameer',
  'Amgad',
  'Ami',
  'Amin',
  'Amir',
  'Ammaar',
  'Ammar',
  'Ammer',
  'Amolpreet',
  'Amos',
  'Amrinder',
  'Amrit',
  'Amro',
  'Anay',
  'Andrea',
  'Andreas',
  'Andrei',
  'Andrejs',
  'Andrew',
  'Andy',
  'Anees',
  'Anesu',
  'Angel',
  'Angelo',
  'Angus',
  'Anir',
  'Anis',
  'Anish',
  'Anmolpreet',
  'Annan',
  'Anndra',
  'Anselm',
  'Anthony',
  'Anthony-John',
  'Antoine',
  'Anton',
  'Antoni',
  'Antonio',
  'Antony',
  'Antonyo',
  'Anubhav',
  'Aodhan',
  'Aon',
  'Aonghus',
  'Apisai',
  'Arafat',
  'Aran',
  'Arandeep',
  'Arann',
  'Aray',
  'Arayan',
  'Archibald',
  'Archie',
  'Arda',
  'Ardal',
  'Ardeshir',
  'Areeb',
  'Areez',
  'Aref',
  'Arfin',
  'Argyle',
  'Argyll',
  'Ari',
  'Aria',
  'Arian',
  'Arihant',
  'Aristomenis',
  'Aristotelis',
  'Arjuna',
  'Arlo',
  'Armaan',
  'Arman',
  'Armen',
  'Arnab',
  'Arnav',
  'Arnold',
  'Aron',
  'Aronas',
  'Arran',
  'Arrham',
  'Arron',
  'Arryn',
  'Arsalan',
  'Artem',
  'Arthur',
  'Artur',
  'Arturo',
  'Arun',
  'Arunas',
  'Arved',
  'Arya',
  'Aryan',
  'Aryankhan',
  'Aryian',
  'Aryn',
  'Asa',
  'Asfhan',
  'Ash',
  'Ashlee-jay',
  'Ashley',
  'Ashton',
  'Ashton-Lloyd',
  'Ashtyn',
  'Ashwin',
  'Asif',
  'Asim',
  'Aslam',
  'Asrar',
  'Ata',
  'Atal',
  'Atapattu',
  'Ateeq',
  'Athol',
  'Athon',
  'Athos-Carlos',
  'Atli',
  'Atom',
  'Attila',
  'Aulay',
  'Aun',
  'Austen',
  'Austin',
  'Avani',
  'Averon',
  'Avi',
  'Avinash',
  'Avraham',
  'Awais',
  'Awwal',
  'Axel',
  'Ayaan',
  'Ayan',
  'Aydan',
  'Ayden',
  'Aydin',
  'Aydon',
  'Ayman',
  'Ayomide',
  'Ayren',
  'Ayrton',
  'Aytug',
  'Ayub',
  'Ayyub',
  'Azaan',
  'Azedine',
  'Azeem',
  'Azim',
  'Aziz',
  'Azlan',
  'Azzam',
  'Azzedine',
  'Babatunmise',
  'Babur',
  'Bader',
  'Badr',
  'Badsha',
  'Bailee',
  'Bailey',
  'Bailie',
  'Bailley',
  'Baillie',
  'Baley',
  'Balian',
  'Banan',
  'Barath',
  'Barkley',
  'Barney',
  'Baron',
  'Barrie',
  'Barry',
  'Bartlomiej',
  'Bartosz',
  'Basher',
  'Basile',
  'Baxter',
  'Baye',
  'Bayley',
  'Beau',
  'Beinn',
  'Bekim',
  'Believe',
  'Ben',
  'Bendeguz',
  'Benedict',
  'Benjamin',
  'Benjamyn',
  'Benji',
  'Benn',
  'Bennett',
  'Benny',
  'Benoit',
  'Bentley',
  'Berkay',
  'Bernard',
  'Bertie',
  'Bevin',
  'Bezalel',
  'Bhaaldeen',
  'Bharath',
  'Bilal',
  'Bill',
  'Billy',
  'Binod',
  'Bjorn',
  'Blaike',
  'Blaine',
  'Blair',
  'Blaire',
  'Blake',
  'Blazej',
  'Blazey',
  'Blessing',
  'Blue',
  'Blyth',
  'Bo',
  'Boab',
  'Bob',
  'Bobby',
  'Bobby-Lee',
  'Bodhan',
  'Boedyn',
  'Bogdan',
  'Bohbi',
  'Bony',
  'Bowen',
  'Bowie',
  'Boyd',
  'Bracken',
  'Brad',
  'Bradan',
  'Braden',
  'Bradley',
  'Bradlie',
  'Bradly',
  'Brady',
  'Bradyn',
  'Braeden',
  'Braiden',
  'Brajan',
  'Brandan',
  'Branden',
  'Brandon',
  'Brandonlee',
  'Brandon-Lee',
  'Brandyn',
  'Brannan',
  'Brayden',
  'Braydon',
  'Braydyn',
  'Breandan',
  'Brehme',
  'Brendan',
  'Brendon',
  'Brendyn',
  'Breogan',
  'Bret',
  'Brett',
  'Briaddon',
  'Brian',
  'Brodi',
  'Brodie',
  'Brody',
  'Brogan',
  'Broghan',
  'Brooke',
  'Brooklin',
  'Brooklyn',
  'Bruce',
  'Bruin',
  'Bruno',
  'Brunon',
  'Bryan',
  'Bryce',
  'Bryden',
  'Brydon',
  'Brydon-Craig',
  'Bryn',
  'Brynmor',
  'Bryson',
  'Buddy',
  'Bully',
  'Burak',
  'Burhan',
  'Butali',
  'Butchi',
  'Byron',
  'Cabhan',
  'Cadan',
  'Cade',
  'Caden',
  'Cadon',
  'Cadyn',
  'Caedan',
  'Caedyn',
  'Cael',
  'Caelan',
  'Caelen',
  'Caethan',
  'Cahl',
  'Cahlum',
  'Cai',
  'Caidan',
  'Caiden',
  'Caiden-Paul',
  'Caidyn',
  'Caie',
  'Cailaen',
  'Cailean',
  'Caileb-John',
  'Cailin',
  'Cain',
  'Caine',
  'Cairn',
  'Cal',
  'Calan',
  'Calder',
  'Cale',
  'Calean',
  'Caleb',
  'Calen',
  'Caley',
  'Calib',
  'Calin',
  'Callahan',
  'Callan',
  'Callan-Adam',
  'Calley',
  'Callie',
  'Callin',
  'Callum',
  'Callun',
  'Callyn',
  'Calum',
  'Calum-James',
  'Calvin',
  'Cambell',
  'Camerin',
  'Cameron',
  'Campbel',
  'Campbell',
  'Camron',
  'Caolain',
  'Caolan',
  'Carl',
  'Carlo',
  'Carlos',
  'Carrich',
  'Carrick',
  'Carson',
  'Carter',
  'Carwyn',
  'Casey',
  'Casper',
  'Cassy',
  'Cathal',
  'Cator',
  'Cavan',
  'Cayden',
  'Cayden-Robert',
  'Cayden-Tiamo',
  'Ceejay',
  'Ceilan',
  'Ceiran',
  'Ceirin',
  'Ceiron',
  'Cejay',
  'Celik',
  'Cephas',
  'Cesar',
  'Cesare',
  'Chad',
  'Chaitanya',
  'Chang-Ha',
  'Charles',
  'Charley',
  'Charlie',
  'Charly',
  'Chase',
  'Che',
  'Chester',
  'Chevy',
  'Chi',
  'Chibudom',
  'Chidera',
  'Chimsom',
  'Chin',
  'Chintu',
  'Chiqal',
  'Chiron',
  'Chris',
  'Chris-Daniel',
  'Chrismedi',
  'Christian',
  'Christie',
  'Christoph',
  'Christopher',
  'Christopher-Lee',
  'Christy',
  'Chu',
  'Chukwuemeka',
  'Cian',
  'Ciann',
  'Ciar',
  'Ciaran',
  'Ciarian',
  'Cieran',
  'Cillian',
  'Cillin',
  'Cinar',
  'CJ',
  'C-Jay',
  'Clark',
  'Clarke',
  'Clayton',
  'Clement',
  'Clifford',
  'Clyde',
  'Cobain',
  'Coban',
  'Coben',
  'Cobi',
  'Cobie',
  'Coby',
  'Codey',
  'Codi',
  'Codie',
  'Cody',
  'Cody-Lee',
  'Coel',
  'Cohan',
  'Cohen',
  'Colby',
  'Cole',
  'Colin',
  'Coll',
  'Colm',
  'Colt',
  'Colton',
  'Colum',
  'Colvin',
  'Comghan',
  'Conal',
  'Conall',
  'Conan',
  'Conar',
  'Conghaile',
  'Conlan',
  'Conley',
  'Conli',
  'Conlin',
  'Conlly',
  'Conlon',
  'Conlyn',
  'Connal',
  'Connall',
  'Connan',
  'Connar',
  'Connel',
  'Connell',
  'Conner',
  'Connolly',
  'Connor',
  'Connor-David',
  'Conor',
  'Conrad',
  'Cooper',
  'Copeland',
  'Coray',
  'Corben',
  'Corbin',
  'Corey',
  'Corey-James',
  'Corey-Jay',
  'Cori',
  'Corie',
  'Corin',
  'Cormac',
  'Cormack',
  'Cormak',
  'Corran',
  'Corrie',
  'Cory',
  'Cosmo',
  'Coupar',
  'Craig',
  'Craig-James',
  'Crawford',
  'Creag',
  'Crispin',
  'Cristian',
  'Crombie',
  'Cruiz',
  'Cruz',
  'Cuillin',
  'Cullen',
  'Cullin',
  'Curtis',
  'Cyrus',
  'Daanyaal',
  'Daegan',
  'Daegyu',
  'Dafydd',
  'Dagon',
  'Dailey',
  'Daimhin',
  'Daithi',
  'Dakota',
  'Daksh',
  'Dale',
  'Dalong',
  'Dalton',
  'Damian',
  'Damien',
  'Damon',
  'Dan',
  'Danar',
  'Dane',
  'Danial',
  'Daniel',
  'Daniele',
  'Daniel-James',
  'Daniels',
  'Daniil',
  'Danish',
  'Daniyal',
  'Danniel',
  'Danny',
  'Dante',
  'Danyal',
  'Danyil',
  'Danys',
  'Daood',
  'Dara',
  'Darach',
  'Daragh',
  'Darcy',
  "D'arcy",
  'Dareh',
  'Daren',
  'Darien',
  'Darius',
  'Darl',
  'Darn',
  'Darrach',
  'Darragh',
  'Darrel',
  'Darrell',
  'Darren',
  'Darrie',
  'Darrius',
  'Darroch',
  'Darryl',
  'Darryn',
  'Darwyn',
  'Daryl',
  'Daryn',
  'Daud',
  'Daumantas',
  'Davi',
  'David',
  'David-Jay',
  'David-Lee',
  'Davie',
  'Davis',
  'Davy',
  'Dawid',
  'Dawson',
  'Dawud',
  'Dayem',
  'Daymian',
  'Deacon',
  'Deagan',
  'Dean',
  'Deano',
  'Decklan',
  'Declain',
  'Declan',
  'Declyan',
  'Declyn',
  'Dedeniseoluwa',
  'Deecan',
  'Deegan',
  'Deelan',
  'Deklain-Jaimes',
  'Del',
  'Demetrius',
  'Denis',
  'Deniss',
  'Dennan',
  'Dennin',
  'Dennis',
  'Denny',
  'Dennys',
  'Denon',
  'Denton',
  'Denver',
  'Denzel',
  'Deon',
  'Derek',
  'Derick',
  'Derin',
  'Dermot',
  'Derren',
  'Derrie',
  'Derrin',
  'Derron',
  'Derry',
  'Derryn',
  'Deryn',
  'Deshawn',
  'Desmond',
  'Dev',
  'Devan',
  'Devin',
  'Devlin',
  'Devlyn',
  'Devon',
  'Devrin',
  'Devyn',
  'Dex',
  'Dexter',
  'Dhani',
  'Dharam',
  'Dhavid',
  'Dhyia',
  'Diarmaid',
  'Diarmid',
  'Diarmuid',
  'Didier',
  'Diego',
  'Diesel',
  'Diesil',
  'Digby',
  'Dilan',
  'Dilano',
  'Dillan',
  'Dillon',
  'Dilraj',
  'Dimitri',
  'Dinaras',
  'Dion',
  'Dissanayake',
  'Dmitri',
  'Doire',
  'Dolan',
  'Domanic',
  'Domenico',
  'Domhnall',
  'Dominic',
  'Dominick',
  'Dominik',
  'Donald',
  'Donnacha',
  'Donnie',
  'Dorian',
  'Dougal',
  'Douglas',
  'Dougray',
  'Drakeo',
  'Dre',
  'Dregan',
  'Drew',
  'Dugald',
  'Duncan',
  'Duriel',
  'Dustin',
  'Dylan',
  'Dylan-Jack',
  'Dylan-James',
  'Dylan-John',
  'Dylan-Patrick',
  'Dylin',
  'Dyllan',
  'Dyllan-James',
  'Dyllon',
  'Eadie',
  'Eagann',
  'Eamon',
  'Eamonn',
  'Eason',
  'Eassan',
  'Easton',
  'Ebow',
  'Ed',
  'Eddie',
  'Eden',
  'Ediomi',
  'Edison',
  'Eduardo',
  'Eduards',
  'Edward',
  'Edwin',
  'Edwyn',
  'Eesa',
  'Efan',
  'Efe',
  'Ege',
  'Ehsan',
  'Ehsen',
  'Eiddon',
  'Eidhan',
  'Eihli',
  'Eimantas',
  'Eisa',
  'Eli',
  'Elias',
  'Elijah',
  'Eliot',
  'Elisau',
  'Eljay',
  'Eljon',
  'Elliot',
  'Elliott',
  'Ellis',
  'Ellisandro',
  'Elshan',
  'Elvin',
  'Elyan',
  'Emanuel',
  'Emerson',
  'Emil',
  'Emile',
  'Emir',
  'Emlyn',
  'Emmanuel',
  'Emmet',
  'Eng',
  'Eniola',
  'Enis',
  'Ennis',
  'Enrico',
  'Enrique',
  'Enzo',
  'Eoghain',
  'Eoghan',
  'Eoin',
  'Eonan',
  'Erdehan',
  'Eren',
  'Erencem',
  'Eric',
  'Ericlee',
  'Erik',
  'Eriz',
  'Ernie-Jacks',
  'Eroni',
  'Eryk',
  'Eshan',
  'Essa',
  'Esteban',
  'Ethan',
  'Etienne',
  'Etinosa',
  'Euan',
  'Eugene',
  'Evan',
  'Evann',
  'Ewan',
  'Ewen',
  'Ewing',
  'Exodi',
  'Ezekiel',
  'Ezra',
  'Fabian',
  'Fahad',
  'Faheem',
  'Faisal',
  'Faizaan',
  'Famara',
  'Fares',
  'Farhaan',
  'Farhan',
  'Farren',
  'Farzad',
  'Fauzaan',
  'Favour',
  'Fawaz',
  'Fawkes',
  'Faysal',
  'Fearghus',
  'Feden',
  'Felix',
  'Fergal',
  'Fergie',
  'Fergus',
  'Ferre',
  'Fezaan',
  'Fiachra',
  'Fikret',
  'Filip',
  'Filippo',
  'Finan',
  'Findlay',
  'Findlay-James',
  'Findlie',
  'Finlay',
  'Finley',
  'Finn',
  'Finnan',
  'Finnean',
  'Finnen',
  'Finnlay',
  'Finnley',
  'Fintan',
  'Fionn',
  'Firaaz',
  'Fletcher',
  'Flint',
  'Florin',
  'Flyn',
  'Flynn',
  'Fodeba',
  'Folarinwa',
  'Forbes',
  'Forgan',
  'Forrest',
  'Fox',
  'Francesco',
  'Francis',
  'Francisco',
  'Franciszek',
  'Franco',
  'Frank',
  'Frankie',
  'Franklin',
  'Franko',
  'Fraser',
  'Frazer',
  'Fred',
  'Freddie',
  'Frederick',
  'Fruin',
  'Fyfe',
  'Fyn',
  'Fynlay',
  'Fynn',
  'Gabriel',
  'Gallagher',
  'Gareth',
  'Garren',
  'Garrett',
  'Garry',
  'Gary',
  'Gavin',
  'Gavin-Lee',
  'Gene',
  'Geoff',
  'Geoffrey',
  'Geomer',
  'Geordan',
  'Geordie',
  'George',
  'Georgia',
  'Georgy',
  'Gerard',
  'Ghyll',
  'Giacomo',
  'Gian',
  'Giancarlo',
  'Gianluca',
  'Gianmarco',
  'Gideon',
  'Gil',
  'Gio',
  'Girijan',
  'Girius',
  'Gjan',
  'Glascott',
  'Glen',
  'Glenn',
  'Gordon',
  'Grady',
  'Graeme',
  'Graham',
  'Grahame',
  'Grant',
  'Grayson',
  'Greg',
  'Gregor',
  'Gregory',
  'Greig',
  'Griffin',
  'Griffyn',
  'Grzegorz',
  'Guang',
  'Guerin',
  'Guillaume',
  'Gurardass',
  'Gurdeep',
  'Gursees',
  'Gurthar',
  'Gurveer',
  'Gurwinder',
  'Gus',
  'Gustav',
  'Guthrie',
  'Guy',
  'Gytis',
  'Habeeb',
  'Hadji',
  'Hadyn',
  'Hagun',
  'Haiden',
  'Haider',
  'Hamad',
  'Hamid',
  'Hamish',
  'Hamza',
  'Hamzah',
  'Han',
  'Hansen',
  'Hao',
  'Hareem',
  'Hari',
  'Harikrishna',
  'Haris',
  'Harish',
  'Harjeevan',
  'Harjyot',
  'Harlee',
  'Harleigh',
  'Harley',
  'Harman',
  'Harnek',
  'Harold',
  'Haroon',
  'Harper',
  'Harri',
  'Harrington',
  'Harris',
  'Harrison',
  'Harry',
  'Harvey',
  'Harvie',
  'Harvinder',
  'Hasan',
  'Haseeb',
  'Hashem',
  'Hashim',
  'Hassan',
  'Hassanali',
  'Hately',
  'Havila',
  'Hayden',
  'Haydn',
  'Haydon',
  'Haydyn',
  'Hcen',
  'Hector',
  'Heddle',
  'Heidar',
  'Heini',
  'Hendri',
  'Henri',
  'Henry',
  'Herbert',
  'Heyden',
  'Hiro',
  'Hirvaansh',
  'Hishaam',
  'Hogan',
  'Honey',
  'Hong',
  'Hope',
  'Hopkin',
  'Hosea',
  'Howard',
  'Howie',
  'Hristomir',
  'Hubert',
  'Hugh',
  'Hugo',
  'Humza',
  'Hunter',
  'Husnain',
  'Hussain',
  'Hussan',
  'Hussnain',
  'Hussnan',
  'Hyden',
  'I',
  'Iagan',
  'Iain',
  'Ian',
  'Ibraheem',
  'Ibrahim',
  'Idahosa',
  'Idrees',
  'Idris',
  'Iestyn',
  'Ieuan',
  'Igor',
  'Ihtisham',
  'Ijay',
  'Ikechukwu',
  'Ikemsinachukwu',
  'Ilyaas',
  'Ilyas',
  'Iman',
  'Immanuel',
  'Inan',
  'Indy',
  'Ines',
  'Innes',
  'Ioannis',
  'Ireayomide',
  'Ireoluwa',
  'Irvin',
  'Irvine',
  'Isa',
  'Isaa',
  'Isaac',
  'Isaiah',
  'Isak',
  'Isher',
  'Ishwar',
  'Isimeli',
  'Isira',
  'Ismaeel',
  'Ismail',
  'Israel',
  'Issiaka',
  'Ivan',
  'Ivar',
  'Izaak',
  'J',
  'Jaay',
  'Jac',
  'Jace',
  'Jack',
  'Jacki',
  'Jackie',
  'Jack-James',
  'Jackson',
  'Jacky',
  'Jacob',
  'Jacques',
  'Jad',
  'Jaden',
  'Jadon',
  'Jadyn',
  'Jae',
  'Jagat',
  'Jago',
  'Jaheim',
  'Jahid',
  'Jahy',
  'Jai',
  'Jaida',
  'Jaiden',
  'Jaidyn',
  'Jaii',
  'Jaime',
  'Jai-Rajaram',
  'Jaise',
  'Jak',
  'Jake',
  'Jakey',
  'Jakob',
  'Jaksyn',
  'Jakub',
  'Jamaal',
  'Jamal',
  'Jameel',
  'Jameil',
  'James',
  'James-Paul',
  'Jamey',
  'Jamie',
  'Jan',
  'Jaosha',
  'Jardine',
  'Jared',
  'Jarell',
  'Jarl',
  'Jarno',
  'Jarred',
  'Jarvi',
  'Jasey-Jay',
  'Jasim',
  'Jaskaran',
  'Jason',
  'Jasper',
  'Jaxon',
  'Jaxson',
  'Jay',
  'Jaydan',
  'Jayden',
  'Jayden-James',
  'Jayden-Lee',
  'Jayden-Paul',
  'Jayden-Thomas',
  'Jaydn',
  'Jaydon',
  'Jaydyn',
  'Jayhan',
  'Jay-Jay',
  'Jayke',
  'Jaymie',
  'Jayse',
  'Jayson',
  'Jaz',
  'Jazeb',
  'Jazib',
  'Jazz',
  'Jean',
  'Jean-Lewis',
  'Jean-Pierre',
  'Jebadiah',
  'Jed',
  'Jedd',
  'Jedidiah',
  'Jeemie',
  'Jeevan',
  'Jeffrey',
  'Jensen',
  'Jenson',
  'Jensyn',
  'Jeremy',
  'Jerome',
  'Jeronimo',
  'Jerrick',
  'Jerry',
  'Jesse',
  'Jesuseun',
  'Jeswin',
  'Jevan',
  'Jeyun',
  'Jez',
  'Jia',
  'Jian',
  'Jiao',
  'Jimmy',
  'Jincheng',
  'JJ',
  'Joaquin',
  'Joash',
  'Jock',
  'Jody',
  'Joe',
  'Joeddy',
  'Joel',
  'Joey',
  'Joey-Jack',
  'Johann',
  'Johannes',
  'Johansson',
  'John',
  'Johnathan',
  'Johndean',
  'Johnjay',
  'John-Michael',
  'Johnnie',
  'Johnny',
  'Johnpaul',
  'John-Paul',
  'John-Scott',
  'Johnson',
  'Jole',
  'Jomuel',
  'Jon',
  'Jonah',
  'Jonatan',
  'Jonathan',
  'Jonathon',
  'Jonny',
  'Jonothan',
  'Jon-Paul',
  'Jonson',
  'Joojo',
  'Jordan',
  'Jordi',
  'Jordon',
  'Jordy',
  'Jordyn',
  'Jorge',
  'Joris',
  'Jorryn',
  'Josan',
  'Josef',
  'Joseph',
  'Josese',
  'Josh',
  'Joshiah',
  'Joshua',
  'Josiah',
  'Joss',
  'Jostelle',
  'Joynul',
  'Juan',
  'Jubin',
  'Judah',
  'Jude',
  'Jules',
  'Julian',
  'Julien',
  'Jun',
  'Junior',
  'Jura',
  'Justan',
  'Justin',
  'Justinas',
  'Kaan',
  'Kabeer',
  'Kabir',
  'Kacey',
  'Kacper',
  'Kade',
  'Kaden',
  'Kadin',
  'Kadyn',
  'Kaeden',
  'Kael',
  'Kaelan',
  'Kaelin',
  'Kaelum',
  'Kai',
  'Kaid',
  'Kaidan',
  'Kaiden',
  'Kaidinn',
  'Kaidyn',
  'Kaileb',
  'Kailin',
  'Kain',
  'Kaine',
  'Kainin',
  'Kainui',
  'Kairn',
  'Kaison',
  'Kaiwen',
  'Kajally',
  'Kajetan',
  'Kalani',
  'Kale',
  'Kaleb',
  'Kaleem',
  'Kal-el',
  'Kalen',
  'Kalin',
  'Kallan',
  'Kallin',
  'Kalum',
  'Kalvin',
  'Kalvyn',
  'Kameron',
  'Kames',
  'Kamil',
  'Kamran',
  'Kamron',
  'Kane',
  'Karam',
  'Karamvir',
  'Karandeep',
  'Kareem',
  'Karim',
  'Karimas',
  'Karl',
  'Karol',
  'Karson',
  'Karsyn',
  'Karthikeya',
  'Kasey',
  'Kash',
  'Kashif',
  'Kasim',
  'Kasper',
  'Kasra',
  'Kavin',
  'Kayam',
  'Kaydan',
  'Kayden',
  'Kaydin',
  'Kaydn',
  'Kaydyn',
  'Kaydyne',
  'Kayleb',
  'Kaylem',
  'Kaylum',
  'Kayne',
  'Kaywan',
  'Kealan',
  'Kealon',
  'Kean',
  'Keane',
  'Kearney',
  'Keatin',
  'Keaton',
  'Keavan',
  'Keayn',
  'Kedrick',
  'Keegan',
  'Keelan',
  'Keelin',
  'Keeman',
  'Keenan',
  'Keenan-Lee',
  'Keeton',
  'Kehinde',
  'Keigan',
  'Keilan',
  'Keir',
  'Keiran',
  'Keiren',
  'Keiron',
  'Keiryn',
  'Keison',
  'Keith',
  'Keivlin',
  'Kelam',
  'Kelan',
  'Kellan',
  'Kellen',
  'Kelso',
  'Kelum',
  'Kelvan',
  'Kelvin',
  'Ken',
  'Kenan',
  'Kendall',
  'Kendyn',
  'Kenlin',
  'Kenneth',
  'Kensey',
  'Kenton',
  'Kenyon',
  'Kenzeigh',
  'Kenzi',
  'Kenzie',
  'Kenzo',
  'Kenzy',
  'Keo',
  'Ker',
  'Kern',
  'Kerr',
  'Kevan',
  'Kevin',
  'Kevyn',
  'Kez',
  'Khai',
  'Khalan',
  'Khaleel',
  'Khaya',
  'Khevien',
  'Khizar',
  'Khizer',
  'Kia',
  'Kian',
  'Kian-James',
  'Kiaran',
  'Kiarash',
  'Kie',
  'Kiefer',
  'Kiegan',
  'Kienan',
  'Kier',
  'Kieran',
  'Kieran-Scott',
  'Kieren',
  'Kierin',
  'Kiern',
  'Kieron',
  'Kieryn',
  'Kile',
  'Killian',
  'Kimi',
  'Kingston',
  'Kinneil',
  'Kinnon',
  'Kinsey',
  'Kiran',
  'Kirk',
  'Kirwin',
  'Kit',
  'Kiya',
  'Kiyonari',
  'Kjae',
  'Klein',
  'Klevis',
  'Kobe',
  'Kobi',
  'Koby',
  'Koddi',
  'Koden',
  'Kodi',
  'Kodie',
  'Kody',
  'Kofi',
  'Kogan',
  'Kohen',
  'Kole',
  'Konan',
  'Konar',
  'Konnor',
  'Konrad',
  'Koray',
  'Korben',
  'Korbyn',
  'Korey',
  'Kori',
  'Korrin',
  'Kory',
  'Koushik',
  'Kris',
  'Krish',
  'Krishan',
  'Kriss',
  'Kristian',
  'Kristin',
  'Kristofer',
  'Kristoffer',
  'Kristopher',
  'Kruz',
  'Krzysiek',
  'Krzysztof',
  'Ksawery',
  'Ksawier',
  'Kuba',
  'Kurt',
  'Kurtis',
  'Kurtis-Jae',
  'Kyaan',
  'Kyan',
  'Kyde',
  'Kyden',
  'Kye',
  'Kyel',
  'Kyhran',
  'Kyie',
  'Kylan',
  'Kylar',
  'Kyle',
  'Kyle-Derek',
  'Kylian',
  'Kym',
  'Kynan',
  'Kyral',
  'Kyran',
  'Kyren',
  'Kyrillos',
  'Kyro',
  'Kyron',
  'Kyrran',
  'Lachlainn',
  'Lachlan',
  'Lachlann',
  'Lael',
  'Lagan',
  'Laird',
  'Laison',
  'Lakshya',
  'Lance',
  'Lancelot',
  'Landon',
  'Lang',
  'Lasse',
  'Latif',
  'Lauchlan',
  'Lauchlin',
  'Laughlan',
  'Lauren',
  'Laurence',
  'Laurie',
  'Lawlyn',
  'Lawrence',
  'Lawrie',
  'Lawson',
  'Layne',
  'Layton',
  'Lee',
  'Leigh',
  'Leigham',
  'Leighton',
  'Leilan',
  'Leiten',
  'Leithen',
  'Leland',
  'Lenin',
  'Lennan',
  'Lennen',
  'Lennex',
  'Lennon',
  'Lennox',
  'Lenny',
  'Leno',
  'Lenon',
  'Lenyn',
  'Leo',
  'Leon',
  'Leonard',
  'Leonardas',
  'Leonardo',
  'Lepeng',
  'Leroy',
  'Leven',
  'Levi',
  'Levon',
  'Levy',
  'Lewie',
  'Lewin',
  'Lewis',
  'Lex',
  'Leydon',
  'Leyland',
  'Leylann',
  'Leyton',
  'Liall',
  'Liam',
  'Liam-Stephen',
  'Limo',
  'Lincoln',
  'Lincoln-John',
  'Lincon',
  'Linden',
  'Linton',
  'Lionel',
  'Lisandro',
  'Litrell',
  'Liyonela-Elam',
  'LLeyton',
  'Lliam',
  'Lloyd',
  'Lloyde',
  'Loche',
  'Lochlan',
  'Lochlann',
  'Lochlan-Oliver',
  'Lock',
  'Lockey',
  'Logan',
  'Logann',
  'Logan-Rhys',
  'Loghan',
  'Lokesh',
  'Loki',
  'Lomond',
  'Lorcan',
  'Lorenz',
  'Lorenzo',
  'Lorne',
  'Loudon',
  'Loui',
  'Louie',
  'Louis',
  'Loukas',
  'Lovell',
  'Luc',
  'Luca',
  'Lucais',
  'Lucas',
  'Lucca',
  'Lucian',
  'Luciano',
  'Lucien',
  'Lucus',
  'Luic',
  'Luis',
  'Luk',
  'Luka',
  'Lukas',
  'Lukasz',
  'Luke',
  'Lukmaan',
  'Luqman',
  'Lyall',
  'Lyle',
  'Lyndsay',
  'Lysander',
  'Maanav',
  'Maaz',
  'Mac',
  'Macallum',
  'Macaulay',
  'Macauley',
  'Macaully',
  'Machlan',
  'Maciej',
  'Mack',
  'Mackenzie',
  'Mackenzy',
  'Mackie',
  'Macsen',
  'Macy',
  'Madaki',
  'Maddison',
  'Maddox',
  'Madison',
  'Madison-Jake',
  'Madox',
  'Mael',
  'Magnus',
  'Mahan',
  'Mahdi',
  'Mahmoud',
  'Maias',
  'Maison',
  'Maisum',
  'Maitlind',
  'Majid',
  'Makensie',
  'Makenzie',
  'Makin',
  'Maksim',
  'Maksymilian',
  'Malachai',
  'Malachi',
  'Malachy',
  'Malakai',
  'Malakhy',
  'Malcolm',
  'Malik',
  'Malikye',
  'Malo',
  "Ma'moon",
  'Manas',
  'Maneet',
  'Manmohan',
  'Manolo',
  'Manson',
  'Mantej',
  'Manuel',
  'Manus',
  'Marc',
  'Marc-Anthony',
  'Marcel',
  'Marcello',
  'Marcin',
  'Marco',
  'Marcos',
  'Marcous',
  'Marcquis',
  'Marcus',
  'Mario',
  'Marios',
  'Marius',
  'Mark',
  'Marko',
  'Markus',
  'Marley',
  'Marlin',
  'Marlon',
  'Maros',
  'Marshall',
  'Martin',
  'Marty',
  'Martyn',
  'Marvellous',
  'Marvin',
  'Marwan',
  'Maryk',
  'Marzuq',
  'Mashhood',
  'Mason',
  'Mason-Jay',
  'Masood',
  'Masson',
  'Matas',
  'Matej',
  'Mateusz',
  'Mathew',
  'Mathias',
  'Mathu',
  'Mathuyan',
  'Mati',
  'Matt',
  'Matteo',
  'Matthew',
  'Matthew-William',
  'Matthias',
  'Max',
  'Maxim',
  'Maximilian',
  'Maximillian',
  'Maximus',
  'Maxwell',
  'Maxx',
  'Mayeul',
  'Mayson',
  'Mazin',
  'Mcbride',
  'McCaulley',
  'McKade',
  'McKauley',
  'McKay',
  'McKenzie',
  'McLay',
  'Meftah',
  'Mehmet',
  'Mehraz',
  'Meko',
  'Melville',
  'Meshach',
  'Meyzhward',
  'Micah',
  'Michael',
  'Michael-Alexander',
  'Michael-James',
  'Michal',
  'Michat',
  'Micheal',
  'Michee',
  'Mickey',
  'Miguel',
  'Mika',
  'Mikael',
  'Mikee',
  'Mikey',
  'Mikhail',
  'Mikolaj',
  'Miles',
  'Millar',
  'Miller',
  'Milo',
  'Milos',
  'Milosz',
  'Mir',
  'Mirza',
  'Mitch',
  'Mitchel',
  'Mitchell',
  'Moad',
  'Moayd',
  'Mobeen',
  'Modoulamin',
  'Modu',
  'Mohamad',
  'Mohamed',
  'Mohammad',
  'Mohammad-Bilal',
  'Mohammed',
  'Mohanad',
  'Mohd',
  'Momin',
  'Momooreoluwa',
  'Montague',
  'Montgomery',
  'Monty',
  'Moore',
  'Moosa',
  'Moray',
  'Morgan',
  'Morgyn',
  'Morris',
  'Morton',
  'Moshy',
  'Motade',
  'Moyes',
  'Msughter',
  'Mueez',
  'Muhamadjavad',
  'Muhammad',
  'Muhammed',
  'Muhsin',
  'Muir',
  'Munachi',
  'Muneeb',
  'Mungo',
  'Munir',
  'Munmair',
  'Munro',
  'Murdo',
  'Murray',
  'Murrough',
  'Murry',
  'Musa',
  'Musse',
  'Mustafa',
  'Mustapha',
  'Muzammil',
  'Muzzammil',
  'Mykie',
  'Myles',
  'Mylo',
  'Nabeel',
  'Nadeem',
  'Nader',
  'Nagib',
  'Naif',
  'Nairn',
  'Narvic',
  'Nash',
  'Nasser',
  'Nassir',
  'Natan',
  'Nate',
  'Nathan',
  'Nathanael',
  'Nathanial',
  'Nathaniel',
  'Nathan-Rae',
  'Nawfal',
  'Nayan',
  'Neco',
  'Neil',
  'Nelson',
  'Neo',
  'Neshawn',
  'Nevan',
  'Nevin',
  'Ngonidzashe',
  'Nial',
  'Niall',
  'Nicholas',
  'Nick',
  'Nickhill',
  'Nicki',
  'Nickson',
  'Nicky',
  'Nico',
  'Nicodemus',
  'Nicol',
  'Nicolae',
  'Nicolas',
  'Nidhish',
  'Nihaal',
  'Nihal',
  'Nikash',
  'Nikhil',
  'Niki',
  'Nikita',
  'Nikodem',
  'Nikolai',
  'Nikos',
  'Nilav',
  'Niraj',
  'Niro',
  'Niven',
  'Noah',
  'Noel',
  'Nolan',
  'Noor',
  'Norman',
  'Norrie',
  'Nuada',
  'Nyah',
  'Oakley',
  'Oban',
  'Obieluem',
  'Obosa',
  'Odhran',
  'Odin',
  'Odynn',
  'Ogheneochuko',
  'Ogheneruno',
  'Ohran',
  'Oilibhear',
  'Oisin',
  'Ojima-Ojo',
  'Okeoghene',
  'Olaf',
  'Ola-Oluwa',
  'Olaoluwapolorimi',
  'Ole',
  'Olie',
  'Oliver',
  'Olivier',
  'Oliwier',
  'Ollie',
  'Olurotimi',
  'Oluwadamilare',
  'Oluwadamiloju',
  'Oluwafemi',
  'Oluwafikunayomi',
  'Oluwalayomi',
  'Oluwatobiloba',
  'Oluwatoni',
  'Omar',
  'Omri',
  'Oran',
  'Orin',
  'Orlando',
  'Orley',
  'Orran',
  'Orrick',
  'Orrin',
  'Orson',
  'Oryn',
  'Oscar',
  'Osesenagha',
  'Oskar',
  'Ossian',
  'Oswald',
  'Otto',
  'Owain',
  'Owais',
  'Owen',
  'Owyn',
  'Oz',
  'Ozzy',
  'Pablo',
  'Pacey',
  'Padraig',
  'Paolo',
  'Pardeepraj',
  'Parkash',
  'Parker',
  'Pascoe',
  'Pasquale',
  'Patrick',
  'Patrick-John',
  'Patrikas',
  'Patryk',
  'Paul',
  'Pavit',
  'Pawel',
  'Pawlo',
  'Pearce',
  'Pearse',
  'Pearsen',
  'Pedram',
  'Pedro',
  'Peirce',
  'Peiyan',
  'Pele',
  'Peni',
  'Peregrine',
  'Peter',
  'Phani',
  'Philip',
  'Philippos',
  'Phinehas',
  'Phoenix',
  'Phoevos',
  'Pierce',
  'Pierre-Antoine',
  'Pieter',
  'Pietro',
  'Piotr',
  'Porter',
  'Prabhjoit',
  'Prabodhan',
  'Praise',
  'Pranav',
  'Pravin',
  'Precious',
  'Prentice',
  'Presley',
  'Preston',
  'Preston-Jay',
  'Prinay',
  'Prince',
  'Prithvi',
  'Promise',
  'Puneetpaul',
  'Pushkar',
  'Qasim',
  'Qirui',
  'Quinlan',
  'Quinn',
  'Radmiras',
  'Raees',
  'Raegan',
  'Rafael',
  'Rafal',
  'Rafferty',
  'Rafi',
  'Raheem',
  'Rahil',
  'Rahim',
  'Rahman',
  'Raith',
  'Raithin',
  'Raja',
  'Rajab-Ali',
  'Rajan',
  'Ralfs',
  'Ralph',
  'Ramanas',
  'Ramit',
  'Ramone',
  'Ramsay',
  'Ramsey',
  'Rana',
  'Ranolph',
  'Raphael',
  'Rasmus',
  'Rasul',
  'Raul',
  'Raunaq',
  'Ravin',
  'Ray',
  'Rayaan',
  'Rayan',
  'Rayane',
  'Rayden',
  'Rayhan',
  'Raymond',
  'Rayne',
  'Rayyan',
  'Raza',
  'Reace',
  'Reagan',
  'Reean',
  'Reece',
  'Reed',
  'Reegan',
  'Rees',
  'Reese',
  'Reeve',
  'Regan',
  'Regean',
  'Reggie',
  'Rehaan',
  'Rehan',
  'Reice',
  'Reid',
  'Reigan',
  'Reilly',
  'Reily',
  'Reis',
  'Reiss',
  'Remigiusz',
  'Remo',
  'Remy',
  'Ren',
  'Renars',
  'Reng',
  'Rennie',
  'Reno',
  'Reo',
  'Reuben',
  'Rexford',
  'Reynold',
  'Rhein',
  'Rheo',
  'Rhett',
  'Rheyden',
  'Rhian',
  'Rhoan',
  'Rholmark',
  'Rhoridh',
  'Rhuairidh',
  'Rhuan',
  'Rhuaridh',
  'Rhudi',
  'Rhy',
  'Rhyan',
  'Rhyley',
  'Rhyon',
  'Rhys',
  'Rhys-Bernard',
  'Rhyse',
  'Riach',
  'Rian',
  'Ricards',
  'Riccardo',
  'Ricco',
  'Rice',
  'Richard',
  'Richey',
  'Richie',
  'Ricky',
  'Rico',
  'Ridley',
  'Ridwan',
  'Rihab',
  'Rihan',
  'Rihards',
  'Rihonn',
  'Rikki',
  'Riley',
  'Rio',
  'Rioden',
  'Rishi',
  'Ritchie',
  'Rivan',
  'Riyadh',
  'Riyaj',
  'Roan',
  'Roark',
  'Roary',
  'Rob',
  'Robbi',
  'Robbie',
  'Robbie-lee',
  'Robby',
  'Robert',
  'Robert-Gordon',
  'Robertjohn',
  'Robi',
  'Robin',
  'Rocco',
  'Roddy',
  'Roderick',
  'Rodrigo',
  'Roen',
  'Rogan',
  'Roger',
  'Rohaan',
  'Rohan',
  'Rohin',
  'Rohit',
  'Rokas',
  'Roman',
  'Ronald',
  'Ronan',
  'Ronan-Benedict',
  'Ronin',
  'Ronnie',
  'Rooke',
  'Roray',
  'Rori',
  'Rorie',
  'Rory',
  'Roshan',
  'Ross',
  'Ross-Andrew',
  'Rossi',
  'Rowan',
  'Rowen',
  'Roy',
  'Ruadhan',
  'Ruaidhri',
  'Ruairi',
  'Ruairidh',
  'Ruan',
  'Ruaraidh',
  'Ruari',
  'Ruaridh',
  'Ruben',
  'Rubhan',
  'Rubin',
  'Rubyn',
  'Rudi',
  'Rudy',
  'Rufus',
  'Rui',
  'Ruo',
  'Rupert',
  'Ruslan',
  'Russel',
  'Russell',
  'Ryaan',
  'Ryan',
  'Ryan-Lee',
  'Ryden',
  'Ryder',
  'Ryese',
  'Ryhs',
  'Rylan',
  'Rylay',
  'Rylee',
  'Ryleigh',
  'Ryley',
  'Rylie',
  'Ryo',
  'Ryszard',
  'Saad',
  'Sabeen',
  'Sachkirat',
  'Saffi',
  'Saghun',
  'Sahaib',
  'Sahbian',
  'Sahil',
  'Saif',
  'Saifaddine',
  'Saim',
  'Sajid',
  'Sajjad',
  'Salahudin',
  'Salman',
  'Salter',
  'Salvador',
  'Sam',
  'Saman',
  'Samar',
  'Samarjit',
  'Samatar',
  'Sambrid',
  'Sameer',
  'Sami',
  'Samir',
  'Sami-Ullah',
  'Samual',
  'Samuel',
  'Samuela',
  'Samy',
  'Sanaullah',
  'Sandro',
  'Sandy',
  'Sanfur',
  'Sanjay',
  'Santiago',
  'Santino',
  'Satveer',
  'Saul',
  'Saunders',
  'Savin',
  'Sayad',
  'Sayeed',
  'Sayf',
  'Scot',
  'Scott',
  'Scott-Alexander',
  'Seaan',
  'Seamas',
  'Seamus',
  'Sean',
  'Seane',
  'Sean-James',
  'Sean-Paul',
  'Sean-Ray',
  'Seb',
  'Sebastian',
  'Sebastien',
  'Selasi',
  'Seonaidh',
  'Sephiroth',
  'Sergei',
  'Sergio',
  'Seth',
  'Sethu',
  'Seumas',
  'Shaarvin',
  'Shadow',
  'Shae',
  'Shahmir',
  'Shai',
  'Shane',
  'Shannon',
  'Sharland',
  'Sharoz',
  'Shaughn',
  'Shaun',
  'Shaunpaul',
  'Shaun-Paul',
  'Shaun-Thomas',
  'Shaurya',
  'Shaw',
  'Shawn',
  'Shawnpaul',
  'Shay',
  'Shayaan',
  'Shayan',
  'Shaye',
  'Shayne',
  'Shazil',
  'Shea',
  'Sheafan',
  'Sheigh',
  'Shenuk',
  'Sher',
  'Shergo',
  'Sheriff',
  'Sherwyn',
  'Shiloh',
  'Shiraz',
  'Shreeram',
  'Shreyas',
  'Shyam',
  'Siddhant',
  'Siddharth',
  'Sidharth',
  'Sidney',
  'Siergiej',
  'Silas',
  'Simon',
  'Sinai',
  'Skye',
  'Sofian',
  'Sohaib',
  'Sohail',
  'Soham',
  'Sohan',
  'Sol',
  'Solomon',
  'Sonneey',
  'Sonni',
  'Sonny',
  'Sorley',
  'Soul',
  'Spencer',
  'Spondon',
  'Stanislaw',
  'Stanley',
  'Stefan',
  'Stefano',
  'Stefin',
  'Stephen',
  'Stephenjunior',
  'Steve',
  'Steven',
  'Steven-lee',
  'Stevie',
  'Stewart',
  'Stewarty',
  'Strachan',
  'Struan',
  'Stuart',
  'Su',
  'Subhaan',
  'Sudais',
  'Suheyb',
  'Suilven',
  'Sukhi',
  'Sukhpal',
  'Sukhvir',
  'Sulayman',
  'Sullivan',
  'Sultan',
  'Sung',
  'Sunny',
  'Suraj',
  'Surien',
  'Sweyn',
  'Syed',
  'Sylvain',
  'Symon',
  'Szymon',
  'Tadd',
  'Taddy',
  'Tadhg',
  'Taegan',
  'Taegen',
  'Tai',
  'Tait',
  'Taiwo',
  'Talha',
  'Taliesin',
  'Talon',
  'Talorcan',
  'Tamar',
  'Tamiem',
  'Tammam',
  'Tanay',
  'Tane',
  'Tanner',
  'Tanvir',
  'Tanzeel',
  'Taonga',
  'Tarik',
  'Tariq-Jay',
  'Tate',
  'Taylan',
  'Taylar',
  'Tayler',
  'Taylor',
  'Taylor-Jay',
  'Taylor-Lee',
  'Tayo',
  'Tayyab',
  'Tayye',
  'Tayyib',
  'Teagan',
  'Tee',
  'Teejay',
  'Tee-jay',
  'Tegan',
  'Teighen',
  'Teiyib',
  'Te-Jay',
  'Temba',
  'Teo',
  'Teodor',
  'Teos',
  'Terry',
  'Teydren',
  'Theo',
  'Theodore',
  'Thiago',
  'Thierry',
  'Thom',
  'Thomas',
  'Thomas-Jay',
  'Thomson',
  'Thorben',
  'Thorfinn',
  'Thrinei',
  'Thumbiko',
  'Tiago',
  'Tian',
  'Tiarnan',
  'Tibet',
  'Tieran',
  'Tiernan',
  'Timothy',
  'Timucin',
  'Tiree',
  'Tisloh',
  'Titi',
  'Titus',
  'Tiylar',
  'TJ',
  'Tjay',
  'T-Jay',
  'Tobey',
  'Tobi',
  'Tobias',
  'Tobie',
  'Toby',
  'Todd',
  'Tokinaga',
  'Toluwalase',
  'Tom',
  'Tomas',
  'Tomasz',
  'Tommi-Lee',
  'Tommy',
  'Tomson',
  'Tony',
  'Torin',
  'Torquil',
  'Torran',
  'Torrin',
  'Torsten',
  'Trafford',
  'Trai',
  'Travis',
  'Tre',
  'Trent',
  'Trey',
  'Tristain',
  'Tristan',
  'Troy',
  'Tubagus',
  'Turki',
  'Turner',
  'Ty',
  'Ty-Alexander',
  'Tye',
  'Tyelor',
  'Tylar',
  'Tyler',
  'Tyler-James',
  'Tyler-Jay',
  'Tyllor',
  'Tylor',
  'Tymom',
  'Tymon',
  'Tymoteusz',
  'Tyra',
  'Tyree',
  'Tyrnan',
  'Tyrone',
  'Tyson',
  'Ubaid',
  'Ubayd',
  'Uchenna',
  'Uilleam',
  'Umair',
  'Umar',
  'Umer',
  'Umut',
  'Urban',
  'Uri',
  'Usman',
  'Uzair',
  'Uzayr',
  'Valen',
  'Valentin',
  'Valentino',
  'Valery',
  'Valo',
  'Vasyl',
  'Vedantsinh',
  'Veeran',
  'Victor',
  'Victory',
  'Vinay',
  'Vince',
  'Vincent',
  'Vincenzo',
  'Vinh',
  'Vinnie',
  'Vithujan',
  'Vladimir',
  'Vladislav',
  'Vrishin',
  'Vuyolwethu',
  'Wabuya',
  'Wai',
  'Walid',
  'Wallace',
  'Walter',
  'Waqaas',
  'Warkhas',
  'Warren',
  'Warrick',
  'Wasif',
  'Wayde',
  'Wayne',
  'Wei',
  'Wen',
  'Wesley',
  'Wesley-Scott',
  'Wiktor',
  'Wilkie',
  'Will',
  'William',
  'William-John',
  'Willum',
  'Wilson',
  'Windsor',
  'Wojciech',
  'Woyenbrakemi',
  'Wyatt',
  'Wylie',
  'Wynn',
  'Xabier',
  'Xander',
  'Xavier',
  'Xiao',
  'Xida',
  'Xin',
  'Xue',
  'Yadgor',
  'Yago',
  'Yahya',
  'Yakup',
  'Yang',
  'Yanick',
  'Yann',
  'Yannick',
  'Yaseen',
  'Yasin',
  'Yasir',
  'Yassin',
  'Yoji',
  'Yong',
  'Yoolgeun',
  'Yorgos',
  'Youcef',
  'Yousif',
  'Youssef',
  'Yu',
  'Yuanyu',
  'Yuri',
  'Yusef',
  'Yusuf',
  'Yves',
  'Zaaine',
  'Zaak',
  'Zac',
  'Zach',
  'Zachariah',
  'Zacharias',
  'Zacharie',
  'Zacharius',
  'Zachariya',
  'Zachary',
  'Zachary-Marc',
  'Zachery',
  'Zack',
  'Zackary',
  'Zaid',
  'Zain',
  'Zaine',
  'Zaineddine',
  'Zainedin',
  'Zak',
  'Zakaria',
  'Zakariya',
  'Zakary',
  'Zaki',
  'Zakir',
  'Zakk',
  'Zamaar',
  'Zander',
  'Zane',
  'Zarran',
  'Zayd',
  'Zayn',
  'Zayne',
  'Ze',
  'Zechariah',
  'Zeek',
  'Zeeshan',
  'Zeid',
  'Zein',
  'Zen',
  'Zendel',
  'Zenith',
  'Zennon',
  'Zeph',
  'Zerah',
  'Zhen',
  'Zhi',
  'Zhong',
  'Zhuo',
  'Zi',
  'Zidane',
  'Zijie',
  'Zinedine',
  'Zion',
  'Zishan',
  'Ziya',
  'Ziyaan',
  'Zohaib',
  'Zohair',
  'Zoubaeir',
  'Zubair',
  'Zubayr',
  'Zuriel',
];

export const nameList = ['Felix', 'Aneka'];
export const getrandomitem = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const getrandomColor = () => {
  return Math.floor(Math.random() * 16777215).toString(16);
};

export const optionsLoreleiTypes = {
  randomizeIds: true,
  seed: ['Felix', 'Aneka'],
  size: 1000,
  // background color
  backgroundType: ['gradientLinear', 'solid'],
  // transparent, gradientLinear
  backgroundColor: [
    'transparent',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  // include or exclude passed options.
  rotate: 0,
  scale: 50,
  radius: 0,
  beard: ['variant01', 'variant02'],
  beardProbability: 0,
  earrings: ['variant01', 'variant02', 'variant03'],
  earringsColor: ['000000'],
  earringsProbability: 0,
  eyebrows: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
  ],
  eyebrowsColor: ['000000'],
  eyes: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
    'variant16',
    'variant17',
    'variant18',
    'variant19',
    'variant20',
    'variant21',
    'variant22',
    'variant23',
    'variant24',
  ],
  eyesColor: ['000000'],
  freckles: ['variant01'],
  frecklesColor: ['000000'],
  frecklesProbability: 0,
  glasses: ['variant01', 'variant02', 'variant03', 'variant04', 'variant05'],
  glassesColor: ['000000'],
  glassesProbability: 0,
  hair: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
    'variant16',
    'variant17',
    'variant18',
    'variant19',
    'variant20',
    'variant21',
    'variant22',
    'variant23',
    'variant24',
    'variant25',
    'variant26',
    'variant27',
    'variant28',
    'variant29',
    'variant30',
    'variant31',
    'variant32',
    'variant33',
    'variant34',
    'variant35',
    'variant36',
    'variant37',
    'variant38',
    'variant39',
    'variant40',
    'variant41',
    'variant42',
    'variant43',
    'variant44',
    'variant45',
    'variant46',
    'variant47',
    'variant48',
  ],
  hairAccessories: ['flowers'],
  hairAccessoriesColor: ['000000'],
  hairAccessoriesProbability: 0,
  hairColor: ['000000'],
  head: ['variant01', 'variant02', 'variant03'],
  mouth: [
    'happy01',
    'happy02',
    'happy03',
    'happy04',
    'happy05',
    'happy06',
    'happy07',
    'happy08',
    'happy09',
    'happy10',
    'happy11',
    'happy12',
    'happy13',
    'happy14',
    'happy15',
    'happy16',
    'happy17',
    'happy18',
    'sad01',
    'sad02',
    'sad03',
    'sad04',
    'sad05',
    'sad06',
    'sad07',
    'sad08',
    'sad09',
  ],
  mouthColor: ['000000'],
  nose: ['variant01', 'variant02', 'variant03', 'variant04', 'variant05', 'variant06'],
  noseColor: ['000000'],
  skinColor: ['ffffff'],
};
export const optionsNotionistsTypes = {
  randomizeIds: true,
  seed: ['Felix', 'Aneka'],
  size: 1000,
  // background color
  backgroundType: ['gradientLinear', 'solid'],
  // transparent, gradientLinear
  backgroundColor: [
    'transparent',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  // include or exclude passed options.
  rotate: 0,
  scale: 50,
  radius: 0,
  base: ['variant01'],
  beard: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
  ],
  beardProbability: 0,
  body: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
    'variant16',
    'variant17',
    'variant18',
    'variant19',
    'variant20',
    'variant21',
    'variant22',
    'variant23',
    'variant24',
    'variant25',
  ],
  bodyIcon: ['electric', 'galaxy', 'saturn'],
  bodyIconProbability: 0,
  brows: ['variant01', 'variant02', 'variant03'],
  eyes: ['variant01', 'variant02', 'variant03', 'variant04', 'variant05'],
  gesture: [
    'hand',
    'handPhone',
    'ok',
    'okLongArm',
    'point',
    'pointLongArm',
    'waveLongArm',
    'waveLongArms',
    'waveOkLongArms',
    'wavePointLongArms',
  ],
  gestureProbability: 0,
  glasses: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
  ],
  glassesProbability: 0,
  hair: [
    'hat',
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
    'variant16',
    'variant17',
    'variant18',
    'variant19',
    'variant20',
    'variant21',
    'variant22',
    'variant23',
    'variant24',
    'variant25',
    'variant26',
    'variant27',
    'variant28',
    'variant29',
    'variant30',
    'variant31',
    'variant32',
    'variant33',
    'variant34',
    'variant35',
    'variant36',
    'variant37',
    'variant38',
    'variant39',
    'variant40',
    'variant41',
    'variant42',
    'variant43',
    'variant44',
    'variant45',
    'variant46',
    'variant47',
    'variant48',
    'variant49',
    'variant50',
    'variant51',
    'variant52',
    'variant53',
    'variant54',
    'variant55',
    'variant56',
    'variant57',
    'variant58',
    'variant59',
    'variant60',
    'variant61',
    'variant62',
    'variant63',
  ],
  lips: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
    'variant16',
    'variant17',
    'variant18',
    'variant19',
    'variant20',
    'variant21',
    'variant22',
    'variant23',
    'variant24',
    'variant25',
    'variant26',
    'variant27',
    'variant28',
    'variant29',
    'variant30',
  ],
  nose: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
    'variant16',
    'variant17',
    'variant18',
    'variant19',
    'variant20',
  ],
};
export const optionsOpenPeepsTypes = {
  randomizeIds: true,
  seed: ['Felix', 'Aneka'],
  size: 1000,
  // background color
  backgroundType: ['gradientLinear', 'solid'],
  // transparent, gradientLinear
  backgroundColor: [
    'transparent',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  // include or exclude passed options.
  rotate: 0,
  scale: 50,
  radius: 0,
  accessories: [
    'eyepatch',
    'glasses',
    'glasses2',
    'glasses3',
    'glasses4',
    'glasses5',
    'sunglasses',
    'sunglasses2',
  ],
  accessoriesProbability: 0,
  clothingColor: [
    'transparent',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  face: [
    'angryWithFang',
    'awe',
    'blank',
    'calm',
    'cheeky',
    'concerned',
    'concernedFear',
    'contempt',
    'cute',
    'cyclops',
    'driven',
    'eatingHappy',
    'explaining',
    'eyesClosed',
    'fear',
    'hectic',
    'lovingGrin1',
    'lovingGrin2',
    'monster',
    'old',
    'rage',
    'serious',
    'smile',
    'smileBig',
    'smileLOL',
    'smileTeethGap',
    'solemn',
    'suspicious',
    'tired',
    'veryAngry',
  ],
  facialHair: [
    'chin',
    'full',
    'full2',
    'full3',
    'full4',
    'goatee1',
    'goatee2',
    'moustache1',
    'moustache2',
    'moustache3',
    'moustache4',
    'moustache5',
    'moustache6',
    'moustache7',
    'moustache8',
    'moustache9',
  ],
  facialHairProbability: 0,
  head: [
    'afro',
    'bangs',
    'bangs2',
    'bantuKnots',
    'bear',
    'bun',
    'bun2',
    'buns',
    'cornrows',
    'cornrows2',
    'dreads1',
    'dreads2',
    'flatTop',
    'flatTopLong',
    'grayBun',
    'grayMedium',
    'grayShort',
    'hatBeanie',
    'hatHip',
    'hijab',
    'long',
    'longAfro',
    'longBangs',
    'longCurly',
    'medium1',
    'medium2',
    'medium3',
    'mediumBangs',
    'mediumBangs2',
    'mediumBangs3',
    'mediumStraight',
    'mohawk',
    'mohawk2',
    'noHair1',
    'noHair2',
    'noHair3',
    'pomp',
    'shaved1',
    'shaved2',
    'shaved3',
    'short1',
    'short2',
    'short3',
    'short4',
    'short5',
    'turban',
    'twists',
    'twists2',
  ],
  headContrastColor: [
    '2c1b18',
    '4a312c',
    '724133',
    'a55728',
    'b58143',
    'c93305',
    'd6b370',
    'e8e1e1',
    'ecdcbf',
    'f59797',
  ],
  mask: ['medicalMask', 'respirator'],
  maskProbability: 0,
  skinColor: [
    '694d3d',
    'ae5d29',
    'd08b5b',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
};
export const optionsAvataarsTypes = {
  randomizeIds: true,
  seed: ['Felix', 'Aneka'],
  size: 1000,
  // background color
  backgroundType: ['gradientLinear', 'solid'],
  // transparent, gradientLinear
  backgroundColor: [
    'transparent',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  // include or exclude passed options.
  rotate: 0,
  scale: 50,
  radius: 0,
  accessories: [
    'eyepatch',
    'kurt',
    'prescription01',
    'prescription02',
    'round',
    'sunglasses',
    'wayfarers',
  ],
  accessoriesColor: [
    'transparent',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  accessoriesProbability: 0,
  base: ['default'],
  clothesColor: [
    '3c4f5c',
    '65c9ff',
    '262e33',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  clothing: [
    'blazerAndShirt',
    'blazerAndSweater',
    'collarAndSweater',
    'graphicShirt',
    'hoodie',
    'overall',
    'shirtCrewNeck',
    'shirtScoopNeck',
    'shirtVNeck',
  ],
  clothingGraphic: [
    'bat',
    'bear',
    'cumbia',
    'deer',
    'diamond',
    'hola',
    'pizza',
    'resist',
    'skull',
    'skullOutline',
  ],
  eyebrows: [
    'angry',
    'angryNatural',
    'default',
    'defaultNatural',
    'flatNatural',
    'frownNatural',
    'raisedExcited',
    'raisedExcitedNatural',
    'sadConcerned',
    'sadConcernedNatural',
    'unibrowNatural',
    'upDown',
    'upDownNatural',
  ],
  eyes: [
    'closed',
    'cry',
    'default',
    'eyeRoll',
    'happy',
    'hearts',
    'side',
    'squint',
    'surprised',
    'wink',
    'winkWacky',
    'xDizzy',
  ],
  facialHair: ['beardLight', 'beardMajestic', 'beardMedium', 'moustacheMagnum', 'moustacheFancy'],
  facialHairColor: ['2c1b18', '4a312c', '724133'],
  facialHairProbability: 0,
  hairColor: [
    '2c1b18',
    '4a312c',
    '724133',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  hatColor: [
    '2c1b18',
    '4a312c',
    '724133',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  mouth: [
    'concerned',
    'default',
    'disbelief',
    'eating',
    'grimace',
    'sad',
    'screamOpen',
    'serious',
    'smile',
    'tongue',
    'twinkle',
    'vomit',
  ],
  nose: ['default'],
  skinColor: [
    '614335',
    'ae5d29',
    'd08b5b',
    '2c1b18',
    '4a312c',
    '724133',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  style: ['circle', 'default'],
  top: [
    'bigHair',
    'bob',
    'bun',
    'curly',
    'curvy',
    'dreads',
    'dreads01',
    'dreads02',
    'frida',
    'frizzle',
    'fro',
    'froBand',
    'hat',
    'hijab',
    'longButNotTooLong',
    'miaWallace',
    'shaggy',
    'shaggyMullet',
    'shavedSides',
    'shortCurly',
    'shortFlat',
    'shortRound',
    'shortWaved',
    'sides',
    'straight01',
    'straight02',
    'straightAndStrand',
    'theCaesar',
    'theCaesarAndSidePart',
    'turban',
    'winterHat1',
    'winterHat02',
    'winterHat03',
    'winterHat04',
  ],
  topProbability: 0,
};
export const optionsCroodlesTypes = {
  randomizeIds: true,
  seed: ['Felix', 'Aneka'],
  flip: true,
  size: 1000,
  // background color
  backgroundType: ['gradientLinear', 'solid'],
  beard: ['variant01', 'variant02', 'variant03', 'variant04', 'variant05'],
  beardProbability: 0,
  baseColor: ['6dbb58', '54d7c7', '456dff', 'f55d81', 'f3b63a', 'e24553', '7555ca'],
  // transparent, gradientLinear
  backgroundColor: ['6dbb58', '54d7c7', '456dff', 'f55d81', 'f3b63a', 'e24553', '7555ca'],
  clothingColor: ['6dbb58', '54d7c7', '456dff', 'f55d81', 'f3b63a', 'e24553', '7555ca'],
  eyes: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
    'variant16',
  ],
  face: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
  ],
  facialHairProbability: 10,
  mouth: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
    'variant16',
    'variant17',
    'variant18',
  ],
  mustache: ['variant01', 'variant02', 'variant03', 'variant04'],
  mustacheProbability: 0,
  nose: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
  ],
  top: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
    'variant16',
    'variant17',
    'variant18',
    'variant19',
    'variant20',
    'variant21',
    'variant22',
    'variant23',
    'variant24',
    'variant25',
    'variant26',
    'variant27',
    'variant28',
    'variant29',
  ],
  topColor: [
    '000000',
    '0fa958',
    '699bf7',
    '623d36',
    '92594b',
    'b16a5b',
    'd78774',
    'e5a07e',
    'e7a391',
    'eeb4a4',
  ],
};
export const optionsBottsTypes = {
  randomizeIds: true,
  seed: ['Felix', 'Aneka'],
  flip: true,
  size: 1000,
  // background color
  backgroundType: ['gradientLinear', 'solid'],
  // transparent, gradientLinear
  backgroundColor: [
    'transparent',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  rotate: 0,
  radius: 0,
  clip: true,
  baseColor: [
    'transparent',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
    '00acc1',
    '1e88e5',
    '5e35b1',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  eyes: [
    'bulging',
    'dizzy',
    'eva',
    'frame1',
    'frame2',
    'glow',
    'happy',
    'hearts',
    'robocop',
    'round',
    'roundFrame01',
    'roundFrame02',
    'sensor',
    'shade01',
  ],
  face: ['round01', 'round02', 'square01', 'square02', 'square03', 'square04'],
  mouth: [
    'bite',
    'diagram',
    'grill01',
    'grill02',
    'grill03',
    'smile01',
    'smile02',
    'square01',
    'square02',
  ],
  mouthProbability: 0,
  sides: ['antenna01', 'antenna02', 'cables01', 'cables02', 'round', 'square', 'squareAssymetric'],
  sidesProbability: 0,
  texture: ['camo01', 'camo02', 'circuits', 'dirty01', 'dirty02', 'dots', 'grunge01', 'grunge02'],
  textureProbability: 0,
  top: [
    'antenna',
    'antennaCrooked',
    'bulb01',
    'glowingBulb01',
    'glowingBulb02',
    'horns',
    'lights',
    'pyramid',
    'radar',
  ],
  topProbability: 0,
};
export const optionsminiavsTypes = {
  randomizeIds: true,
  seed: ['Felix', 'Aneka'],
  flip: true,
  size: 1000,
  // background color
  backgroundType: ['gradientLinear', 'solid'],
  // transparent, gradientLinear
  backgroundColor: [
    'transparent',
    '623d36',
    '92594b',
    'b16a5b',
    'eeb4a4',
    'e7a391',
    'e5a07e',
    'd78774',
  ],
  rotate: 0,
  radius: 0,
  clip: true,
  blushes: ['default'],
  blushesProbability: 0,
  body: ['golf', 'tShirt'],
  bodyColor: ['3633e0', 'e05a33', 'ff4dd8'],
  eyes: ['confident', 'happy', 'normal'],
  glasses: ['normal'],
  glassesProbability: 0,
  hair: [
    'balndess',
    'classic01',
    'classic02',
    'curly',
    'elvis',
    'long',
    'ponyTail',
    'slaughter',
    'stylish',
  ],
  hairColor: ['1b0b47', '47280b', 'ad3a20'],
  head: ['normal', 'thin', 'wide'],
  mouth: ['default', 'missingTooth'],
  mustache: ['freddy', 'horshoe', 'pencilThin', 'pencilThinBeard'],
  mustacheProbability: 0,
  skinColor: ['836055', 'f5d0c5', 'ffcb7e'],
};
export const optionsPersonaTypes = {
  randomizeIds: true,
  seed: ['Felix', 'Aneka'],
  flip: true,
  size: 1000,
  // background color
  backgroundType: ['gradientLinear', 'solid'],
  // transparent, gradientLinear
  backgroundColor: ['transparent'],
  body: ['checkered', 'rounded', 'small', 'squared'],
  clothingColor: ['6dbb58', '54d7c7', '456dff', 'f55d81', 'f3b63a', 'e24553', '7555ca'],
  eyes: ['glasses', 'happy', 'open', 'wink', 'sunglasses', 'sleep'],
  facialHair: ['beardMustache', 'goatee', 'pyramid', 'shadow', 'soulPatch', 'walrus'],
  facialHairProbability: 10,
  hair: [
    'bald',
    'balding',
    'beanie',
    'bobBangs',
    'bobCut',
    'bunUndercut',
    'buzzcut',
    'cap',
    'curly',
    'curlyBun',
    'curlyHighTop',
    'extraLong',
    'fade',
    'long',
    'mohawk',
    'pigtails',
    'shortCombover',
    'shortComboverChops',
    'sideShave',
    'straightBun',
  ],
  hairColor: ['6c4545', '362c47', 'dee1f5', 'e15c66', 'e16381', 'f27d65', 'f29c65'],
  mouth: ['bigSmile', 'frown', 'lips', 'pacifier', 'smile', 'smirk', 'surprise'],
  nose: ['mediumRound', 'smallRound', 'wrinkles'],
  skinColor: ['623d36', '92594b', 'b16a5b', 'd78774', 'e5a07e', 'e7a391', 'eeb4a4'],
};
export const optionsMicahTypes = {
  randomizeIds: true,
  seed: ['Felix', 'Aneka'],
  flip: true,
  size: 1000,
  // background color
  baseColor: ['77311d', 'ac6651', 'f9c9b6'],
  earrings: ['hoop', 'stud'],
  earringColor: [
    '6bd9e9',
    '9287ff',
    '77311d',
    'ac6651',
    'd2eff3',
    'e0ddff',
    'f4d150',
    'f9c9b6',
    'fc909f',
    'ffeba4',
    'ffedef',
    'ffffff',
  ],
  eyebrows: ['down', 'eyelashesDown', 'eyelashesUp', 'up'],
  eyebrowsColor: [
    '6bd9e9',
    '9287ff',
    '77311d',
    'ac6651',
    'd2eff3',
    'e0ddff',
    'f4d150',
    'f9c9b6',
    'fc909f',
    'ffeba4',
    'ffedef',
    'ffffff',
  ],
  eyeShadowColor: [
    '6bd9e9',
    '9287ff',
    '77311d',
    'ac6651',
    'd2eff3',
    'e0ddff',
    'f4d150',
    'f9c9b6',
    'fc909f',
    'ffeba4',
    'ffedef',
    'ffffff',
  ],
  ears: ['attached', 'detached'],
  backgroundType: ['gradientLinear', 'solid'],
  // transparent, gradientLinear
  backgroundColor: ['transparent'],
  body: ['checkered', 'rounded', 'small', 'squared'],
  clothingColor: ['6dbb58', '54d7c7', '456dff', 'f55d81', 'f3b63a', 'e24553', '7555ca'],
  eyes: ['eyes', 'eyesShadow', 'round', 'smilingShadow', 'smiling'],
  eyesColor: ['000000', 'ffffff'],
  facialHair: ['beard', 'scruff'],
  glasses: ['round', 'square'],
  glassesColor: [
    '6bd9e9',
    '9287ff',
    '77311d',
    'ac6651',
    'd2eff3',
    'e0ddff',
    'f4d150',
    'f9c9b6',
    'fc909f',
    'ffeba4',
    'ffedef',
    'ffffff',
  ],
  facialHairColor: [
    '6bd9e9',
    '9287ff',
    '77311d',
    'ac6651',
    'd2eff3',
    'e0ddff',
    'f4d150',
    'f9c9b6',
    'fc909f',
    'ffeba4',
    'ffedef',
    'ffffff',
  ],
  facialHairProbability: 10,
  hair: ['dannyPhantom', 'dougFunny', 'fonze', 'full', 'mrClean', 'mrT', 'pixie', 'turban'],
  hairColor: [
    '6c4545',
    '362c47',
    'dee1f5',
    'e15c66',
    'e16381',
    'f27d65',
    'f29c65',
    '6bd9e9',
    '9287ff',
    '77311d',
    'ac6651',
    'd2eff3',
    'e0ddff',
    'f4d150',
    'f9c9b6',
    'fc909f',
    'ffeba4',
    'ffedef',
    'ffffff',
  ],
  mouth: ['frown', 'laughing', 'nervous', 'pucker', 'sad', 'smile', 'smirk', 'surprised'],
  mouthColor: [
    '6c4545',
    '362c47',
    'dee1f5',
    'e15c66',
    'e16381',
    'f27d65',
    'f29c65',
    '6bd9e9',
    '9287ff',
    '77311d',
    'ac6651',
    'd2eff3',
    'e0ddff',
    'f4d150',
    'f9c9b6',
    'fc909f',
    'ffeba4',
    'ffedef',
    'ffffff',
  ],
  nose: ['curve', 'pointed', 'tound'],
  shirtColor: [
    '6c4545',
    '362c47',
    'dee1f5',
    'e15c66',
    'e16381',
    'f27d65',
    'f29c65',
    '6bd9e9',
    '9287ff',
    '77311d',
    'ac6651',
    'd2eff3',
    'e0ddff',
    'f4d150',
    'f9c9b6',
    'fc909f',
    'ffeba4',
    'ffedef',
    'ffffff',
  ],
  shirt: ['collared', 'crew', 'open'],
  skinColor: ['623d36', '92594b', 'b16a5b', 'd78774', 'e5a07e', 'e7a391', 'eeb4a4'],
};
export const optionsAdventureTypes = {
  randomizeIds: true,
  seed: ['Felix', 'Aneka'],
  size: 1000,
  // background color
  backgroundType: ['gradientLinear', 'solid'],
  // transparent, gradientLinear
  backgroundColor: [getrandomColor()],
  // include or exclude passed options.
  mode: 'include',
  // 'longHair', 'shortHair', 'eyepatch', 'hat', 'hijab', 'turban'
  top: ['longHair'],
  // in percent
  // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
  hatColor: ['auburn', 'black', 'blonde', 'brown', 'pastel', 'platinum', 'red', 'gray'],
  // 'auburn', 'black', 'blonde', 'brown', 'pastel', 'platinum', 'red', 'gray'
  hairColor: [
    '0e0e0e',
    '3eac2c',
    '6a4e35',
    '85c2c6',
    '796a45',
    '562306',
    '592454',
    'ab2a18',
    'ac6511',
    'afafaf',
    'b9a05f',
    'cb6820',
    'dba3be',
    'e5d7a3',
  ],
  // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
  accessories: ['eyepatch', 'kurt', 'prescription01'],
  accessoriesProbability: 0,
  // in percent
  accessoriesChance: 10,
  // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
  accessoriesColor: ['3c4f5c', '65c9ff', '262e33'],
  // 'medium', 'light', 'majestic', 'fancy', 'magnum'
  // in percent
  facialHairChance: 10,
  // 'auburn', 'black', 'blonde', 'brown', 'pastel', 'platinum', 'red', 'gray'
  // 'blazer', 'sweater', 'shirt', 'hoodie', 'overall'
  clothing: ['blazerAndShirt', 'blazerAndSweater', 'collarAndSweater'],
  // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
  clothesColor: ['black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'],
  clothingColor: ['black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'],
  clothingGraphic: ['bat', 'bear', 'cumbia', 'cat', 'name', 'dog', 'tiger'],
  // Possible values: close, cry, default, dizzy, roll, happy, hearts, side, squint, surprised, wink, winkWacky
  eyes: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
    'variant16',
    'variant17',
    'variant18',
    'variant19',
    'variant20',
    'variant21',
    'variant22',
    'variant23',
    'variant24',
    'variant25',
    'variant26',
    'variant27',
  ],
  facialHairProbability: Math.floor(Math.random() * 100),
  featuresProbability: Math.floor(Math.random() * 100),
  features: ['birthmark', 'blush', 'freckles', 'mustache'],
  glasses: ['variant01', 'variant02', 'variant03', 'variant04', 'variant05'],
  glassesProbability: 10,
  // 'angry', 'default', 'flat', 'raised', 'sad', 'unibrow', 'up', 'frown'
  eyebrows: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
  ],
  facialHair: [
    'beardLight',
    'beardMajestic',
    'beardMedium',
    'walrus',
    'soulPatch',
    'shadow',
    'pyramid',
    'goatee',
    'beardMustache',
  ],
  facialHairColor: [getrandomColor()],
  // Possible values: concerned, default, disbelief, eating, grimace, sad, scream, serious, smile, tongue, twinkle, vomit
  mouth: [
    'variant01',
    'variant02',
    'variant03',
    'variant04',
    'variant05',
    'variant06',
    'variant07',
    'variant08',
    'variant09',
    'variant10',
    'variant11',
    'variant12',
    'variant13',
    'variant14',
    'variant15',
    'variant16',
    'variant17',
    'variant18',
    'variant19',
    'variant20',
    'variant21',
    'variant22',
    'variant23',
    'variant24',
    'variant25',
    'variant26',
    'variant27',
    'variant28',
    'variant29',
    'variant30',
  ],

  // tanned', 'yellow', 'pale', 'light', 'brown', 'darkBrown', 'black'
  skin: [],
  hair: [
    'long01',
    'long02',
    'long03',
    'long04',
    'long05',
    'long06',
    'long07',
    'long08',
    'long09',
    'long10',
    'long11',
    'long12',
    'long13',
    'long14',
    'long15',
    'long16',
    'long17',
    'long18',
    'long19',
    'long20',
    'long21',
    'long22',
    'long23',
    'long24',
    'short01',
    'short02',
    'short03',
    'short04',
    'short05',
    'short06',
    'short07',
    'short08',
    'short09',
    'short10',
    'short11',
    'short12',
    'short13',
    'short14',
    'short15',
    'short16',
    'short17',
    'short18',
    'short19',
  ],
  nose: ['mediumRound', 'smallRound', 'wrinkles'],
  earrings: ['variant01', 'variant02', 'variant03', 'variant04', 'variant05', 'variant06'],
  earringsProbability: Math.floor(Math.random() * 100),
  skinColor: ['623d36', '92594b', 'b16a5b', 'eeb4a4', 'e7a391', 'e5a07e', 'd78774'],
};
