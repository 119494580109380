import React from 'react';
import {
  getrandomColor,
  getrandomitem,
  optionsAdventureTypes,
  optionsAvataarsTypes,
  optionsBottsTypes,
  optionsCroodlesTypes,
  optionsLoreleiTypes,
  optionsMicahTypes,
  optionsNotionistsTypes,
  optionsOpenPeepsTypes,
  optionsPersonaTypes,
  optionsminiavsTypes,
} from '../AvatarsOptions';
import { getColorsByName } from '../../colorSelector/NameColors';
import { maleNames } from '../maleNames';
import { femaleNames } from '../femaleNames';

const nameList = ['Felix', 'Aneka'];
export const templateOptionsAvatars = {
  randomizeIds: true,
  flip: true,
  size: 600,
  // background color
  backgroundType: ['transparent'],
  // transparent, gradientLinear
  backgroundColor: ['transparent'],
  baseColor: [],
  clothingColor: [],
  eyes: [],
  shirt: [],
  shirtColor: [],
  glasses: [],
  glassesColor: [],
  glassesProbability: 10,
  eyesColor: [],
  facialHair: [],
  facialHairColor: [],
  facialHairProbability: 10,
  earringsProbability: 10,
  hairProbability: 30,
  hair: [],
  hairColor: [],
  mouth: [],
  nose: [],
  eyeShadowColor: [],
  ears: [],
  earringColor: [],
  skinColor: [],
  earrings: [],
  eyebrows: [],
  eyebrowsColor: [],
};
export const listOfColors = (customname) => {
  const final = getrandomitem(getLettersColorSelected(customname));
  return [final];
};

export const getLettersColor = async (name) => {
  const color = await getColorsByName(name);
  const colorChoice = getrandomitem(color);
  return colorChoice.replaceAll('#', '');
};

export function getLettersColorSelected(customname) {
  const array = getColorsByName(customname);
  const final = Object.keys(array).map((x) => array[x].replaceAll('#', ''));
  return final;
}

export const getNameByGender = (gender) => {
  let name = '';
  switch (gender) {
    case 'male':
      name = getrandomitem(maleNames);
      break;
    case 'female':
      name = getrandomitem(femaleNames);
      break;
    default:
      // random
      break;
  }
  return name;
};

export function getType(avaType, gender, customname) {
  var options = [];

  //   var optionsPersona = {
  //     randomizeIds: true,
  //     seed: gender,
  //     flip: true,
  //     size: 600,
  //     // background color
  //     backgroundType: ['transparent'],
  //     // transparent, gradientLinear
  //     backgroundColor: ['transparent'],
  //     body: [getrandomitem(optionsPersonaTypes.body)],
  //     clothingColor: listOfColors(customname),
  //     eyes: [getrandomitem(optionsPersonaTypes.eyes)],
  //     facialHair: [getrandomitem(optionsPersonaTypes.facialHair)],
  //     facialHairProbability: 10,
  //     hair: [getrandomitem(optionsPersonaTypes.hair)],
  //     hairColor: listOfColors(customname),
  //     mouth: [getrandomitem(optionsPersonaTypes.mouth)],
  //     nose: [getrandomitem(optionsPersonaTypes.nose)],
  //     skinColor: listOfColors(customname),
  //   };
  //   var optionsAdventure = {
  //     randomizeIds: true,
  //     seed: gender,
  //     size: 600,
  //     // background color
  //     backgroundType: ['transparent'],
  //     // transparent, gradientLinear
  //     backgroundColor: ['transparent'],
  //     // include or exclude passed options.
  //     mode: 'include',
  //     // 'longHair', 'shortHair', 'eyepatch', 'hat', 'hijab', 'turban'
  //     top: ['longHair'],
  //     // in percent
  //     // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
  //     hatColor: listOfColors(customname),
  //     // 'auburn', 'black', 'blonde', 'brown', 'pastel', 'platinum', 'red', 'gray'
  //     hairColor: listOfColors(customname),
  //     // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
  //     accessories: ['eyepatch', 'kurt', 'prescription01'],
  //     accessoriesProbability: 0,
  //     // in percent
  //     accessoriesChance: 10,
  //     // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
  //     accessoriesColor: listOfColors(customname),
  //     // 'medium', 'light', 'majestic', 'fancy', 'magnum'
  //     // in percent
  //     facialHairChance: 10,
  //     // 'auburn', 'black', 'blonde', 'brown', 'pastel', 'platinum', 'red', 'gray'
  //     // 'blazer', 'sweater', 'shirt', 'hoodie', 'overall'
  //     clothing: ['blazerAndShirt', 'blazerAndSweater', 'collarAndSweater'],
  //     // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
  //     clothesColor: listOfColors(customname),
  //     //  ['black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'],
  //     clothingColor: ['black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'],
  //     clothingGraphic: ['bat', 'bear', 'cumbia', 'cat', 'name', 'dog', 'tiger'],
  //     // Possible values: close, cry, default, dizzy, roll, happy, hearts, side, squint, surprised, wink, winkWacky
  //     eyes: [
  //       'variant01',
  //       'variant02',
  //       'variant03',
  //       'variant04',
  //       'variant05',
  //       'variant06',
  //       'variant07',
  //       'variant08',
  //       'variant09',
  //       'variant10',
  //       'variant11',
  //       'variant12',
  //       'variant13',
  //       'variant14',
  //       'variant15',
  //       'variant16',
  //       'variant17',
  //       'variant18',
  //       'variant19',
  //       'variant20',
  //       'variant21',
  //       'variant22',
  //       'variant23',
  //       'variant24',
  //       'variant25',
  //       'variant26',
  //       'variant27',
  //     ],
  //     facialHairProbability: Math.floor(Math.random() * 100),
  //     featuresProbability: Math.floor(Math.random() * 100),
  //     features: ['birthmark', 'blush', 'freckles', 'mustache'],
  //     glasses: ['variant01', 'variant02', 'variant03', 'variant04', 'variant05'],
  //     glassesProbability: 10,
  //     // 'angry', 'default', 'flat', 'raised', 'sad', 'unibrow', 'up', 'frown'
  //     eyebrows: [
  //       'variant01',
  //       'variant02',
  //       'variant03',
  //       'variant04',
  //       'variant05',
  //       'variant06',
  //       'variant07',
  //       'variant08',
  //       'variant09',
  //       'variant10',
  //       'variant11',
  //       'variant12',
  //       'variant13',
  //       'variant14',
  //       'variant15',
  //     ],
  //     facialHair: [
  //       'beardLight',
  //       'beardMajestic',
  //       'beardMedium',
  //       'walrus',
  //       'soulPatch',
  //       'shadow',
  //       'pyramid',
  //       'goatee',
  //       'beardMustache',
  //     ],
  //     facialHairColor: listOfColors(customname),
  //     // Possible values: concerned, default, disbelief, eating, grimace, sad, scream, serious, smile, tongue, twinkle, vomit
  //     mouth: [
  //       'variant01',
  //       'variant02',
  //       'variant03',
  //       'variant04',
  //       'variant05',
  //       'variant06',
  //       'variant07',
  //       'variant08',
  //       'variant09',
  //       'variant10',
  //       'variant11',
  //       'variant12',
  //       'variant13',
  //       'variant14',
  //       'variant15',
  //       'variant16',
  //       'variant17',
  //       'variant18',
  //       'variant19',
  //       'variant20',
  //       'variant21',
  //       'variant22',
  //       'variant23',
  //       'variant24',
  //       'variant25',
  //       'variant26',
  //       'variant27',
  //       'variant28',
  //       'variant29',
  //       'variant30',
  //     ],

  //     // tanned', 'yellow', 'pale', 'light', 'brown', 'darkBrown', 'black'
  //     skin: listOfColors(customname),
  //     hair: [
  //       'long01',
  //       'long02',
  //       'long03',
  //       'long04',
  //       'long05',
  //       'long06',
  //       'long07',
  //       'long08',
  //       'long09',
  //       'long10',
  //       'long11',
  //       'long12',
  //       'long13',
  //       'long14',
  //       'long15',
  //       'long16',
  //       'long17',
  //       'long18',
  //       'long19',
  //       'long20',
  //       'long21',
  //       'long22',
  //       'long23',
  //       'long24',
  //       'short01',
  //       'short02',
  //       'short03',
  //       'short04',
  //       'short05',
  //       'short06',
  //       'short07',
  //       'short08',
  //       'short09',
  //       'short10',
  //       'short11',
  //       'short12',
  //       'short13',
  //       'short14',
  //       'short15',
  //       'short16',
  //       'short17',
  //       'short18',
  //       'short19',
  //     ],
  //     nose: ['mediumRound', 'smallRound', 'wrinkles'],
  //     earrings: ['variant01', 'variant02', 'variant03', 'variant04', 'variant05', 'variant06'],
  //     earringsProbability: Math.floor(Math.random() * 100),
  //     skinColor: listOfColors(customname),
  //     // ['623d36', '92594b', 'b16a5b', 'eeb4a4', 'e7a391', 'e5a07e', 'd78774'],
  //   };
  //   var optionsMicah = {
  //     randomizeIds: true,
  //     seed: gender,
  //     flip: true,
  //     size: 600,
  //     // background color
  //     backgroundType: ['transparent'],
  //     // transparent, gradientLinear
  //     backgroundColor: ['transparent'],
  //     baseColor: listOfColors(customname),
  //     clothingColor: listOfColors(customname),
  //     eyes: [getrandomitem(optionsMicahTypes.eyes)],
  //     shirt: [getrandomitem(optionsMicahTypes.shirt)],
  //     shirtColor: listOfColors(customname),
  //     glasses: [getrandomitem(optionsMicahTypes.glasses)],
  //     glassesColor: listOfColors(customname),
  //     glassesProbability: 10,
  //     eyesColor: listOfColors(customname),
  //     facialHair: [getrandomitem(optionsMicahTypes.facialHair)],
  //     facialHairColor: listOfColors(customname),
  //     facialHairProbability: 10,
  //     earringsProbability: 10,
  //     hairProbability: 30,
  //     hair: [getrandomitem(optionsMicahTypes.hair)],
  //     hairColor: listOfColors(customname),
  //     mouth: [getrandomitem(optionsMicahTypes.mouth)],
  //     nose: [getrandomitem(optionsMicahTypes.nose)],
  //     eyeShadowColor: ['000000'],
  //     ears: [getrandomitem(optionsMicahTypes.ears)],
  //     earringColor: listOfColors(customname),
  //     skinColor: listOfColors(customname),
  //     earrings: [getrandomitem(optionsMicahTypes.earrings)],
  //     eyebrows: [getrandomitem(optionsMicahTypes.eyebrows)],
  //     eyebrowsColor: listOfColors(customname),
  //   };
  const modifyTYpe = (list) => {
    let c = list;
    Object.keys(c)?.map((x) => {
      if (x?.includes('Color') && customname !== '' && x !== 'backgroundColor') {
        let newList = listOfColors(customname);
        return (options[x] = newList);
      }
      if (typeof c[x] === 'object' && x !== 'backgroundColor') {
        let newList = [getrandomitem(c[x])];
        return (options[x] = newList);
      } else if (typeof c[x] === 'boolean' && x !== 'randomizeIds') {
        let newList = getrandomitem(trueOrFalseArray);
        return (options[x] = newList);
      }
      // make sure Background Color is transparent
      if (x === 'backgroundColor') {
        let newList = ['transparent'];
        return (options[x] = newList);
      }
      return (options[x] = c[x]);
    });
  };
  const trueOrFalseArray = [true, false];

  switch (avaType) {
    case 'adventurer':
      modifyTYpe(optionsAdventureTypes);
      break;
    case 'personas':
      modifyTYpe(optionsPersonaTypes);
      break;
    case 'openpeeps':
      modifyTYpe(optionsOpenPeepsTypes);
      break;
    case 'notionists':
      modifyTYpe(optionsNotionistsTypes);
      break;
    case 'lorelei':
      modifyTYpe(optionsLoreleiTypes);
      break;
    case 'botts':
      modifyTYpe(optionsBottsTypes);
      break;
    case 'croodles':
      modifyTYpe(optionsCroodlesTypes);
      break;
    case 'avataars':
      modifyTYpe(optionsAvataarsTypes);
      break;
    case 'micah':
      modifyTYpe(optionsMicahTypes);
      break;
    case 'miniavs':
      modifyTYpe(optionsminiavsTypes);
      break;
    default:
      modifyTYpe(optionsLoreleiTypes);
      break;
  }

  return options;
}
