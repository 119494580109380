/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { userStore } from '../core/userStore';
import Loading from '../core/Loading';
import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
export default function HomepageSection2(props) {
  const syncedImages = userStore((s) => s.listedNFTs);
  const [images, setImages] = useState([]);
  useEffect(() => {
    let newImages = [];
    syncedImages.length > 0 &&
      syncedImages.forEach((x) => {
        newImages.push({
          original: x?.image_url,
          thumbnail: '',
          originalHeight: '400px',
          originalWidth: '400px',
        });
      });
    setImages(newImages);
  }, [syncedImages]);
  return (
    <>
      <section className="area container mt-5 mb-5 p-1 col-md-12">
        <div className="title" style={{ fontFamily: 'monospace' }}>
          <h2 className="center">Mint Now</h2>
          {/* Minting */}
          <section className="row m-3 p-5">
            <div className="col">
              <img src="https://i.seadn.io/s/primary-drops/0x91daea15d0e0cf310be0251eaa5357782310fed3/29577816:hero:partner_logo_url:76942831-ddd3-4bf0-b3eb-5cab426b4701.png?auto=format&dpr=1&w=1920" />
              <button
                className="btn btn-primary"
                onClick={() =>
                  (window.location.href = 'https://opensea.io/collection/land-of-names/drop')
                }
              >
                MINT NOW
              </button>
            </div>
            <div className="col">
              <img src="https://i.seadn.io/s/primary-drops/0x6dcb04e647e1b676ce1a819ca3ab7f62d58f58c6/29537135:hero:partner_logo_url:c2d1f970-524c-4034-81c7-d085c0e0bcd4.png?auto=format&dpr=1&w=1920" />
              <button
                className="btn btn-primary"
                onClick={() =>
                  (window.location.href = 'https://opensea.io/collection/animals-club-now/drop')
                }
              >
                MINT NOW
              </button>
            </div>
            <div className="col">
              <img src="https://i.seadn.io/s/primary-drops/0x0f0414d049482638a21b839ba89f3f8f4bda5a61/29516916:hero:partner_logo_url:9d8ece29-bc3f-4ee6-8753-83aa7f64c694.png?auto=format&dpr=1&w=750" />
              <button
                className="btn btn-primary"
                onClick={() =>
                  (window.location.href = 'https://opensea.io/collection/ghost-city-now/drop')
                }
              >
                MINT NOW
              </button>
            </div>
          </section>

          <AwesomeSlider animation="foldOutAnimation" cssModule={[CoreStyles, AnimationStyles]}>
            {syncedImages.length > 0
              ? syncedImages.map((x) => (
                  <div
                    style={{
                      background: 'transparent',
                      backgroundSize: 'cover',
                      height: '100% !important',
                      width: '100% !important',
                    }}
                    key={x?.image_url}
                    data-src={x?.image_url}
                  ></div>
                ))
              : []}
          </AwesomeSlider>
        </div>
      </section>
    </>
  );
}
