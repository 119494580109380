/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React from 'react';

export default function HomepageSection(props) {
  return (
    <>
      <section className="hero-area p-5 inner-container">
        <div className="title " style={{ fontFamily: 'monospace' }}>
          {/* <section className="col-md-6"> */}
          <h1
            style={{
              backgroundBlendMode: 'red',
              bottom: '0',
              fontSize: '60px',
              color: 'white',
              fontFamily: 'monospace',
              fontWeight: '400',
              animation: 'glow 2s ease-in-out infinite alternate',
            }}
          >
            Win hundreds of free NFTs only with RYMILAN!
          </h1>
          <p
            style={{
              backgroundBlendMode: 'red',
              bottom: '0',
              fontSize: '30px',
              color: 'white',
              fontFamily: 'monospace',
              fontWeight: '400',
              animation: 'glow 2s ease-in-out infinite alternate',
            }}
          >
            Buy one of our NFTS, and hold it for at least one month, to be one of the winners to win
          </p>
          <p
            style={{
              backgroundBlendMode: 'red',
              bottom: '0',
              fontSize: '30px',
              color: 'white',
              fontFamily: 'monospace',
              fontWeight: '400',
              animation: 'glow 2s ease-in-out infinite alternate',
            }}
          >
            a random unique NFT minted for you on us.
          </p>
          <p>
            {' '}
            <a href="https://opensea.io/RYMILAN" className="btn btn-primary">
              <img
                width={30}
                height={30}
                src="https://opensea.io/static/images/logos/opensea-logo.svg"
              />{' '}
              OpenSea
            </a>
          </p>
          {/* </section> */}
          {/* <section className="col-md-6"> */}
          {/* <ImageGallery
              lazyLoad={true}
              additionalClass={'title inner-container slider'}
              autoPlay={true}
              items={images}
              showThumbnails={false}
              showFullscreenButton={true}
            /> */}
          {/* </section> */}
        </div>
      </section>
    </>
  );
}
