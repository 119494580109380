import React, { useEffect, useState } from 'react';
import { createAvatar } from '@dicebear/core';
import { lorelei, avataaars, micah, personas, miniavs, adventurer } from '@dicebear/collection';

const nameList = [
  'Time',
  'Past',
  'Future',
  'Dev',
  'Fly',
  'Flying',
  'Soar',
  'Soaring',
  'Power',
  'Falling',
  'Fall',
  'Jump',
  'Cliff',
  'Mountain',
  'Rend',
  'Red',
  'Blue',
  'Green',
  'Yellow',
  'Gold',
  'Demon',
  'Demonic',
  'Panda',
  'Cat',
  'Kitty',
  'Kitten',
  'Zero',
  'Memory',
  'Trooper',
  'XX',
  'Bandit',
  'Fear',
  'Light',
  'Glow',
  'Tread',
  'Deep',
  'Deeper',
  'Deepest',
  'Mine',
  'Your',
  'Worst',
  'Enemy',
  'Hostile',
  'Force',
  'Video',
  'Game',
  'Donkey',
  'Mule',
  'Colt',
  'Cult',
  'Cultist',
  'Magnum',
  'Gun',
  'Assault',
  'Recon',
  'Trap',
  'Trapper',
  'Redeem',
  'Code',
  'Script',
  'Writer',
  'Near',
  'Close',
  'Open',
  'Cube',
  'Circle',
  'Geo',
  'Genome',
  'Germ',
  'Spaz',
  'Shot',
  'Echo',
  'Beta',
  'Alpha',
  'Gamma',
  'Omega',
  'Seal',
  'Squid',
  'Money',
  'Cash',
  'Lord',
  'King',
  'Duke',
  'Rest',
  'Fire',
  'Flame',
  'Morrow',
  'Break',
  'Breaker',
  'Numb',
  'Ice',
  'Cold',
  'Rotten',
  'Sick',
  'Sickly',
  'Janitor',
  'Camel',
  'Rooster',
  'Sand',
  'Desert',
  'Dessert',
  'Hurdle',
  'Racer',
  'Eraser',
  'Erase',
  'Big',
  'Small',
  'Short',
  'Tall',
  'Sith',
  'Bounty',
  'Hunter',
  'Cracked',
  'Broken',
  'Sad',
  'Happy',
  'Joy',
  'Joyful',
  'Crimson',
  'Destiny',
  'Deceit',
  'Lies',
  'Lie',
  'Honest',
  'Destined',
  'Bloxxer',
  'Hawk',
  'Eagle',
  'Hawker',
  'Walker',
  'Zombie',
  'Sarge',
  'Capt',
  'Captain',
  'Punch',
  'One',
  'Two',
  'Uno',
  'Slice',
  'Slash',
  'Melt',
  'Melted',
  'Melting',
  'Fell',
  'Wolf',
  'Hound',
  'Legacy',
  'Sharp',
  'Dead',
  'Mew',
  'Chuckle',
  'Bubba',
  'Bubble',
  'Sandwich',
  'Smasher',
  'Extreme',
  'Multi',
  'Universe',
  'Ultimate',
  'Death',
  'Ready',
  'Monkey',
  'Felix',
  'Anika',
  'Elevator',
  'Wrench',
  'Grease',
  'Head',
  'Theme',
  'Grand',
  'Cool',
  'Kid',
  'Boy',
  'Girl',
  'Vortex',
  'Paradox',
];
const avaRand = [lorelei, avataaars, micah, personas, miniavs, adventurer];
export default function CustomAvatars(props) {
  const [ava, setAva] = useState([]);
  const createAvatarNew = async (options) => {
    return new Promise((resolve, reject) => {
      try {
        const avatar = createAvatar(avaRand[Math.floor(Math.random() * avaRand.length)], options);
        const svg = avatar.png().toDataUri();
        resolve(svg);
      } catch (error) {
        reject(error);
      }
    });
  };
  useEffect(() => {
    regenerateAva();
  }, []);

  const regenerateAva = async () => {
    let avatarsNow = [];
    for (let index = 0; index < 10; index++) {
      let options = {
        seed: nameList[Math.floor(Math.random() * nameList.length)],
        size: 1000,
        // background color
        backgroundType: ['transparent'],
        // transparent, gradientLinear
        backgroundColor: ['transparent'],
        // include or exclude passed options.
        mode: 'include',
        // 'longHair', 'shortHair', 'eyepatch', 'hat', 'hijab', 'turban'
        top: ['longHair'],
        // in percent
        randomizeIds: true,
        // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
        hatColor: ['auburn', 'black', 'blonde', 'brown', 'pastel', 'platinum', 'red', 'gray'],
        // 'auburn', 'black', 'blonde', 'brown', 'pastel', 'platinum', 'red', 'gray'
        hairColor: ['auburn', 'black', 'blonde', 'brown', 'pastel', 'platinum', 'red', 'gray'],
        // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
        accessories: ['eyepatch', 'kurt', 'prescription01'],
        accessoriesProbability: 0,
        // in percent
        accessoriesChance: 10,
        // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
        accessoriesColor: ['3c4f5c', '65c9ff', '262e33'],
        // 'medium', 'light', 'majestic', 'fancy', 'magnum'
        // in percent
        facialHairChance: 10,
        // 'auburn', 'black', 'blonde', 'brown', 'pastel', 'platinum', 'red', 'gray'
        // 'blazer', 'sweater', 'shirt', 'hoodie', 'overall'
        clothing: ['blazerAndShirt', 'blazerAndSweater', 'collarAndSweater'],
        // 'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'
        clothesColor: ['black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'],
        clothingGraphic: ['bat', 'bear', 'cumbia', 'cat', 'name', 'dog', 'tiger'],
        // Possible values: close, cry, default, dizzy, roll, happy, hearts, side, squint, surprised, wink, winkWacky
        eyes: [
          'close',
          'cry',
          'default',
          'dizzy',
          'roll',
          'happy',
          'hearts',
          'side',
          'squint',
          'surprised',
          'wink',
          'winkWacky',
        ],
        // 'angry', 'default', 'flat', 'raised', 'sad', 'unibrow', 'up', 'frown'
        eyebrows: ['angry', 'angryNatural', 'default'],
        facialHair: ['beardLight', 'beardMajestic', 'beardMedium'],
        facialHairColor: ['2c1b18', '4a312c', '724133'],
        // Possible values: concerned, default, disbelief, eating, grimace, sad, scream, serious, smile, tongue, twinkle, vomit
        mouth: [
          'concerned',
          'default',
          'disbelief',
          'eating',
          'grimace',
          'sad',
          'scream',
          'serious',
          'smile',
          'tongue',
          'twinkle',
          'vomit',
        ],
        // tanned', 'yellow', 'pale', 'light', 'brown', 'darkBrown', 'black'
        skin: [],
      };
      await createAvatarNew(options).then((x) => {
        avatarsNow.push(x);
      });
    }
    setAva(avatarsNow);
  };
  return (
    <section className="">
      <button
        className="btn btn-primary col-12"
        onClick={() => {
          setAva([]);
          regenerateAva();
        }}
      >
        More
      </button>
      {ava.length > 0
        ? ava.map((svg, xds) => (
            <img
              height={'100'}
              width={'100'}
              key={xds * Math.random()}
              className="maxw-100"
              src={svg}
              onClick={() => props?.handleAvaCallback(svg)}
            />
          ))
        : []}
    </section>
  );
}
