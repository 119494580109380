/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { userStore } from '../core/userStore';
import HeroSection from './HeroSection';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCollectionNFTs } from '../core/APIs';
import { Link, useParams } from 'react-router-dom';
import Loading from '../core/Loading';
import { Items } from '../EditingPage/Items';
import metaTags from '../core/MetaTags';

export default function Invest(props) {
  const [openAbout, setAboutOpen] = React.useState(false);
  const [openContact, setContactOpen] = React.useState(false);
  const [openInstructions, setInstructionsOpen] = React.useState(false);
  const [randomImages, setRandomImages] = useState([]);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(true);
  const [next, setNext] = useState('nothing');
  const { collection, chain } = useParams();

  const fetchNewData = async () => {
    await getCollectionNFTs(collection, 50, next, chain).then(async (data) => {
      data?.nfts?.length > 0 &&
        (await data?.nfts.map(async (x) => {
          await setRandomImages([...randomImages, x]);
        }));
      setNext(data?.next || 'nothing');
      console.log(next);
      console.log(data?.nfts?.length);
    });
  };

  useEffect(() => {
    // metatags
    metaTags({
      sitename: 'RYMILAN',
      title: `Invest with RYMILAN`,
      author: `RYMILAN`,
      description: `RYMILAN | Invest with RYMILAN and OpenSea, buy NFT and get the chance to win, buy valuable NFTs from the biggest source of NFTs around`,
      keywords: `rymilan, ethereum, matic, opensea, polygon, ryan, milan, nft, nfts, free nft, win nft, build free nft, mint nft, mint, opensea, win, winner, free`,
      image: 'https://rymilan.com/logo.png',
    });
    setTimeout(() => {
      setMounted(true);
    }, 100);
    setMounted(true);
    setLoading(true);
    setRandomImages([]);
    setItems([]);
    setNext('nothing');
    getCollectionNFTs(collection, 50, 'nothing', chain).then(async (data) => {
      await setRandomImages(randomImages.concat(data.nfts));
      setNext(data?.next || 'nothing');
      console.log(data?.nfts?.length);
    });
  }, [props, collection]);
  useEffect(() => {
    if (randomImages?.length > 0) {
      setItems(paginateList(page));
      setCount(page * limit);
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [randomImages]);
  function paginateList(page_number) {
    return randomImages?.slice(page_number * limit, page_number * limit + limit);
  }
  const fetchData = async () => {
    const pagenow = page + 1;
    setPage(pagenow);
    if (items.length >= randomImages.length) {
      if (next !== 'nothing') {
        await fetchNewData();
      } else {
        setHasMore(false);
        return;
      }
    } else {
      const oldList = randomImages;
      await paginateList(pagenow).map((x) => {
        oldList.push(x);
      });
      await setItems(oldList);
      return;
    }
  };
  if (!mounted) {
    return <></>;
  }
  return loading ? (
    <Loading />
  ) : (
    <section className="container-fluid center justify-items-center">
      <InfiniteScroll
        dataLength={items.length}
        next={fetchData}
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
        loader={<h4>Loading...</h4>}
        className="row"
      >
        <div
          id="scrollableDiv"
          style={{
            overflowY: 'auto',
            display: 'block',
            height: '100vh',
          }}
          className="shadow-sm"
        >
          {items?.map((x, xds) => (
            <Link
              target="blank"
              to={`/invest/${chain}/${collection}/${x?.identifier}`}
              key={xds + x?.identifier + xds}
              className="col-md-3"
            >
              <img src={x?.image_url} alt={x?.name} />
            </Link>
          ))}
        </div>
      </InfiniteScroll>
    </section>
  );
}
