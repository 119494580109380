/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import 'react-toastify/dist/ReactToastify.css';
import { create_slug } from '../../../core/Helpers';
import Loading from '../../../core/Loading';
import { userStore } from '../../../core/userStore';
import { avaRand, generator, getLettersColor } from './generator';
import { Buffer } from 'buffer';
import { getColorsByName } from '../colorSelector/NameColors';
import { hexToName } from './components/ColorsConvertor';
import { getOwnNfts } from '../../../core/APIs';
import { templateOptionsAvatars } from './components/AvatarsTypes';
import {
  optionsAdventureTypes,
  optionsAvataarsTypes,
  optionsBottsTypes,
  optionsCroodlesTypes,
  optionsLoreleiTypes,
  optionsMicahTypes,
  optionsNotionistsTypes,
  optionsOpenPeepsTypes,
  optionsPersonaTypes,
  optionsminiavsTypes,
} from './AvatarsOptions';
import { createAvatar } from '@dicebear/core';
import NewFormDyna from './components/NewFormDyna';

export default function AvatarsByName(props) {
  const [loaded, setLoaded] = useState(0);
  const [loading, setloading] = useState(false);
  const [customChar, setcustomChar] = useState(false);
  const [reloadNFTs, setreloadNFTs] = useState(false);
  const [avaType, setavaType] = useState('personas');
  const [counter, setCounter] = useState(10);
  const sessionID = userStore((s) => s.sessionID);
  const [customName, setCustomName] = useState('');
  const [gender, setGender] = useState('male');
  const [preview, setPreview] = useState('');
  const [colorsCustom, setColors] = useState([]);
  const [optionsSet, setoptionsSet] = useState([]);
  const [customOptionSet, setcustomOptionSet] = useState([]);
  const uuid = userStore((s) => s.sessionID);
  const [myOwnNfts, setMyOwnNfts] = useState([]);
  const getMyOwnNfts = () => {
    setreloadNFTs(true);
    getOwnNfts(JSON.parse(uuid))
      .then((data) => {
        setMyOwnNfts(data);
        setTimeout(() => {
          setreloadNFTs(false);
        }, 500);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (uuid.length > 0) {
      getMyOwnNfts();
    }
  }, []);
  const onDrop = async () => {
    setloading(true);
    const folderPath = [];
    const uuid = JSON.parse(sessionID);
    const customNameCheck = customName.replaceAll(' ', '') !== '' ? customName : 'false';
    const counterNew = customNameCheck !== 'false' ? 1 : counter;
    const listfresh = await generator(
      avaType,
      gender,
      counterNew,
      uuid,
      customNameCheck,
      customOptionSet,
      customChar
    );
    //  list.map((image, xds) => {

    //   const fileAdd = {
    //     path: `${xds}-rymilan.png`,
    //     name: file?.name || file?.originalname || path || Math.ceil(Math.random() * 50000),
    //     uuid: uuid,
    //     mimetype: 'image/png',
    //     source: 'RYMILAN',
    //     from: 'https://ddkits.com',
    //     removeBG: props?.removeBG || false,
    //   };
    //   folderPath.push(fileAdd);
    // });

    await axios
      .post(
        `${process.env.REACT_APP_API_CORE}/myown/uploadFiles?uuid=${uuid}`,
        {
          count: counterNew,
          images: JSON.stringify(listfresh.images),
          metadata: JSON.stringify(listfresh.metadata),
          uuid: uuid,
        },
        {
          onUploadProgress: (ProgressEvent) => {
            setLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100);
          },
        }
      )
      .then(async () => {
        toast.success(`Generating files success`);
        props?.fileHandleBack(`Generating files success, you should see your avatar now.`);
        await getMyOwnNfts();
        setloading(false);
        props?.loadingHandleBack(false);
      })
      .catch(function (error) {
        toast.info(error);
        toast.error('Generating Failed, try again');
        setloading(false);
        props?.loadingHandleBack(false);
      });
  };

  const setOptionsValues = (x, va, array = false) => {
    setcustomChar(true);
    let optionsNow = customOptionSet;
    if (array) {
      optionsNow[x] = [va];
    } else {
      optionsNow[x] = va;
    }
    setcustomOptionSet(optionsNow);
    updatePreviewAva(optionsNow);
  };
  const updatePreviewAva = async (op) => {
    const avatar = await createAvatar(avaRand[avaType], op);
    const svg = await avatar.png().toDataUri();
    setPreview(svg);
  };
  useEffect(() => {
    switch (avaType) {
      case 'adventurer':
        setoptionsSet(optionsAdventureTypes);
        setcustomOptionSet(optionsAdventureTypes);
        updatePreviewAva(optionsAdventureTypes);
        break;
      case 'personas':
        setoptionsSet(optionsPersonaTypes);
        setcustomOptionSet(optionsPersonaTypes);
        updatePreviewAva(optionsPersonaTypes);
        break;
      case 'openpeeps':
        setoptionsSet(optionsOpenPeepsTypes);
        setcustomOptionSet(optionsOpenPeepsTypes);
        updatePreviewAva(optionsOpenPeepsTypes);
        break;
      case 'notionists':
        setoptionsSet(optionsNotionistsTypes);
        setcustomOptionSet(optionsNotionistsTypes);
        updatePreviewAva(optionsNotionistsTypes);
        break;
      case 'lorelei':
        setoptionsSet(optionsLoreleiTypes);
        setcustomOptionSet(optionsLoreleiTypes);
        updatePreviewAva(optionsLoreleiTypes);
        break;
      case 'botts':
        setoptionsSet(optionsBottsTypes);
        setcustomOptionSet(optionsBottsTypes);
        updatePreviewAva(optionsBottsTypes);
        break;
      case 'croodles':
        setoptionsSet(optionsCroodlesTypes);
        setcustomOptionSet(optionsCroodlesTypes);
        updatePreviewAva(optionsCroodlesTypes);
        break;
      case 'avataars':
        setoptionsSet(optionsAvataarsTypes);
        setcustomOptionSet(optionsAvataarsTypes);
        updatePreviewAva(optionsAvataarsTypes);
        break;
      case 'micah':
        setoptionsSet(optionsMicahTypes);
        setcustomOptionSet(optionsMicahTypes);
        updatePreviewAva(optionsMicahTypes);
        break;
      case 'miniavs':
        setoptionsSet(optionsminiavsTypes);
        setcustomOptionSet(optionsminiavsTypes);
        updatePreviewAva(optionsminiavsTypes);
        break;
      default:
        setoptionsSet(optionsLoreleiTypes);
        setcustomOptionSet(optionsLoreleiTypes);
        updatePreviewAva(optionsLoreleiTypes);
        break;
    }
  }, [avaType]);
  const avaRandList = [
    'lorelei',
    // 'shapes',
    'avataars',
    'micah',
    'personas',
    'openpeeps',
    'notionists',
    'botts',
    'croodles',
    'miniavs',
    'adventurer',
  ];
  const [constTypeForm, setConstTypeForm] = useState([]);
  function avaTypeOptions() {
    switch (avaType) {
      case 'adventurer':
        return (
          <section className="row " key={avaType}>
            {Object.keys(optionsAdventureTypes).map((x) => {
              let typeNow = typeof optionsAdventureTypes[x];
              return (
                <NewFormDyna
                  key={Math.random() * Math.random()}
                  typeNow={typeNow}
                  avaType={avaType}
                  x={x}
                  valueNow={optionsAdventureTypes[x]}
                  setOptionsValues={setOptionsValues}
                />
              );
            })}
          </section>
        );
      case 'personas':
        return (
          <section className="row " key={avaType}>
            {Object.keys(optionsPersonaTypes).map((x) => {
              let typeNow = typeof optionsPersonaTypes[x];
              return (
                <NewFormDyna
                  key={Math.random() * Math.random()}
                  typeNow={typeNow}
                  avaType={avaType}
                  x={x}
                  valueNow={optionsPersonaTypes[x]}
                  setOptionsValues={setOptionsValues}
                />
              );
            })}
          </section>
        );
      case 'notionists':
        return (
          <section className="row " key={avaType}>
            {Object.keys(optionsNotionistsTypes).map((x) => {
              let typeNow = typeof optionsNotionistsTypes[x];
              return (
                <NewFormDyna
                  key={Math.random() * Math.random()}
                  typeNow={typeNow}
                  avaType={avaType}
                  x={x}
                  valueNow={optionsNotionistsTypes[x]}
                  setOptionsValues={setOptionsValues}
                />
              );
            })}
          </section>
        );
      case 'avataars':
        return (
          <section className="row " key={avaType}>
            {Object.keys(optionsAvataarsTypes)
              .sort()
              .map((x) => {
                let typeNow = typeof optionsAvataarsTypes[x];
                return (
                  <NewFormDyna
                    key={Math.random() * Math.random()}
                    typeNow={typeNow}
                    avaType={avaType}
                    x={x}
                    valueNow={optionsAvataarsTypes[x]}
                    setOptionsValues={setOptionsValues}
                  />
                );
              })}
          </section>
        );
      case 'miniavs':
        return (
          <section className="row " key={avaType}>
            {Object.keys(optionsminiavsTypes)
              .sort()
              .map((x) => {
                let typeNow = typeof optionsminiavsTypes[x];
                return (
                  <NewFormDyna
                    key={Math.random() * Math.random()}
                    typeNow={typeNow}
                    avaType={avaType}
                    x={x}
                    valueNow={optionsminiavsTypes[x]}
                    setOptionsValues={setOptionsValues}
                  />
                );
              })}
          </section>
        );
      case 'croodles':
        return (
          <section className="row " key={avaType}>
            {Object.keys(optionsCroodlesTypes)
              .sort()
              .map((x) => {
                let typeNow = typeof optionsCroodlesTypes[x];
                return (
                  <NewFormDyna
                    key={Math.random() * Math.random()}
                    typeNow={typeNow}
                    avaType={avaType}
                    x={x}
                    valueNow={optionsCroodlesTypes[x]}
                    setOptionsValues={setOptionsValues}
                  />
                );
              })}
          </section>
        );
      case 'botts':
        return (
          <section className="row " key={avaType}>
            {Object.keys(optionsBottsTypes)
              .sort()
              .map((x) => {
                let typeNow = typeof optionsBottsTypes[x];
                return (
                  <NewFormDyna
                    key={Math.random() * Math.random()}
                    typeNow={typeNow}
                    avaType={avaType}
                    x={x}
                    valueNow={optionsBottsTypes[x]}
                    setOptionsValues={setOptionsValues}
                  />
                );
              })}
          </section>
        );
      case 'openpeeps':
        return (
          <section className="row " key={avaType}>
            {Object.keys(optionsOpenPeepsTypes)
              .sort()
              .map((x) => {
                let typeNow = typeof optionsOpenPeepsTypes[x];
                return (
                  <NewFormDyna
                    key={Math.random() * Math.random()}
                    typeNow={typeNow}
                    avaType={avaType}
                    x={x}
                    valueNow={optionsOpenPeepsTypes[x]}
                    setOptionsValues={setOptionsValues}
                  />
                );
              })}
          </section>
        );
      case 'lorelei':
        return (
          <section className="row " key={avaType}>
            {Object.keys(optionsLoreleiTypes)
              .sort()
              .map((x) => {
                let typeNow = typeof optionsLoreleiTypes[x];
                return (
                  <NewFormDyna
                    key={Math.random() * Math.random()}
                    typeNow={typeNow}
                    avaType={avaType}
                    x={x}
                    valueNow={optionsLoreleiTypes[x]}
                    setOptionsValues={setOptionsValues}
                  />
                );
              })}
          </section>
        );
      case 'micah':
        return (
          <section className="row " key={avaType}>
            {Object.keys(optionsMicahTypes)
              .sort()
              .map((x) => {
                let typeNow = typeof optionsMicahTypes[x];
                return (
                  <NewFormDyna
                    key={Math.random() * Math.random()}
                    typeNow={typeNow}
                    avaType={avaType}
                    x={x}
                    valueNow={optionsMicahTypes[x]}
                    setOptionsValues={setOptionsValues}
                  />
                );
              })}
          </section>
        );
      default:
        return (
          <section className="row " key={avaType}>
            {Object.keys(optionsLoreleiTypes)
              .sort()
              .map((x) => {
                let typeNow = typeof optionsLoreleiTypes[x];
                return (
                  <NewFormDyna
                    key={Math.random() * Math.random()}
                    typeNow={typeNow}
                    avaType={avaType}
                    x={x}
                    valueNow={optionsLoreleiTypes[x]}
                    setOptionsValues={setOptionsValues}
                  />
                );
              })}
          </section>
        );
    }
    // return (
    //   <section className="row " key={avaType}>
    //     {Object.keys(optionsSet).map((x) => {
    //       let typeNow = typeof optionsSet[x];
    //       return (
    //         <NewFormDyna
    //           key={Math.random() * Math.random()}
    //           typeNow={typeNow}
    //           avaType={avaType}
    //           x={x}
    //           valueNow={optionsSet[x]}
    //           setOptionsValues={setOptionsValues}
    //         />
    //       );
    //     })}
    //   </section>
    // );
  }
  const setColorsFromLetters = async () => {
    const colors = await getColorsByName(customName);
    setColors(colors);
  };
  useEffect(() => {
    setcustomChar(false);
    if (customName.replaceAll(' ', '') !== '') {
      setColorsFromLetters();
    }
  }, [customName]);
  useEffect(() => {
    setConstTypeForm(avaTypeOptions());
  }, [avaType]);
  useEffect(() => {
    setConstTypeForm(avaTypeOptions());
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <div className="form-group col-md-12">
      <div className="form-control col-md-12 mb-3 p-2">
        <label htmlFor="customname" className="form-control col-md-12">
          Name
        </label>
        <input
          name="customname"
          id="customname"
          type="text"
          value={customName}
          onChange={(e) => setCustomName(e.target.value)}
        />
        <div className="row">
          {colorsCustom?.map((x, xds) => {
            return (
              <span className="col" key={x + Math.random()} style={{ backgroundColor: x }}>
                {x}
              </span>
            );
          })}
        </div>
        <hr />
        <div className="row mt-2">
          {colorsCustom?.map((x, xds) => {
            if (xds === colorsCustom.length - 1) {
              return (
                <>
                  <span className="col-6 bold">Final Color:</span>
                  <span className="col-6" key={x + Math.random()} style={{ backgroundColor: x }}>
                    {x}
                  </span>
                </>
              );
            }
          })}
        </div>
        <div
          className="form-control col-md-12 mb-1 p-2"
          onChange={(e) => setGender(e.target.value)}
        >
          <label htmlFor="type" className="form-control col-md-6">
            Avatar Style
          </label>
          <select
            name="type"
            id="type"
            value={avaType}
            onChange={(e) => setavaType(e.target.value)}
          >
            {avaRandList.map((x) => (
              <option value={x} key={x + Math.random()}>
                {x}
              </option>
            ))}
          </select>
          {/* <label htmlFor="gender" className="form-control col-md-6">
                Gender
              </label>
              <input name="gender" id="gender" type="radio" value="male" />
              Male
              <input name="gender" id="gender" type="radio" value="female" />
              Female */}
        </div>
        <div className="form-control col-md-12 mb-3 p-2">
          <label htmlFor="counter" className="form-control col-md-12">
            Count {customName.replaceAll(' ', '') === '' ? '' : 'With Name only one avatar allowed'}
          </label>
          <input
            id="counter"
            name="counter"
            type="number"
            value={customName.replaceAll(' ', '') === '' ? counter : 1}
            onChange={(e) => setCounter(e.target.value)}
          />
        </div>
      </div>
      {customName.replaceAll(' ', '') === '' ? (
        <>
          <div className="col-md-12 mb-3 p-2 row">
            <section className="col-md-6">{constTypeForm}</section>
            <figure className="col-md-6">
              <img src={preview} width={'100%'} height={'100%'} alt="RyMilan Generated Avatar" />
            </figure>
          </div>
        </>
      ) : (
        []
      )}
      <div className="bg-white p-1 m-1 border">
        <input
          type="Checkbox"
          {...(customChar ? { checked: true } : [])}
          onClick={() => setcustomChar(!customChar)}
        />
        Custom Choice
      </div>
      <button type="submit" onClick={() => onDrop()}>
        {' '}
        Submit
      </button>
      {customName.replaceAll(' ', '') === '' ? (
        <button type="submit" onClick={() => updatePreviewAva(customOptionSet)}>
          {' '}
          Preview
        </button>
      ) : (
        []
      )}
      <div className="form-group">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <div
        className="form-control"
        style={{
          marginTop: '270px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CircularProgress variant="determinate" value={loaded} />
        <section
          className="form-control col-md-12"
          style={{
            background: 'rgb(0,0,0, 0.7)',
            fontFamily: 'monospace',
            fontSize: '10px',
            display: 'block',
            justifyContent: 'left',
            color: '#FFF',
            overflow: 'auto',
            height: '30%',
          }}
        >
          <ul className="col-md-12 center m-1 p-1 text-align-center row col-md-12">
            <h2>
              Generated NFTs{' '}
              <button className="btn btn-primary" onClick={() => getMyOwnNfts()}>
                R
              </button>
            </h2>
            {!reloadNFTs && myOwnNfts.length > 0 ? (
              myOwnNfts
                .filter((x) => x)
                .map((x) => {
                  return (
                    <a
                      className="col-md-2 border p-1 m-1"
                      key={x?.fileName + Math.random()}
                      href={x?.fileName}
                      target="blank"
                    >
                      <img src={x?.fileName} target="blank" id={x?.fileName + Math.random()} />
                      <span
                        className="bg-white text-black p-1 m-1 col-md-12"
                        style={{ fontSize: '16px' }}
                      >
                        {x?.name}
                      </span>
                    </a>
                  );
                })
            ) : (
              <Loading />
            )}
          </ul>
        </section>
      </div>
    </div>
  );
}
