/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';
import 'react-image-picker-editor/dist/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './styles.css';
import Loading from '../core/Loading';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { ObjectSelection, ObjectContext } from '../EditingPage/EditingPage';
import { updateImage } from '../EditingPage/Functions';

export default function NewEditor(props) {
  const [imageSrc, setImageSrc] = useState([]);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState(1);
  const [path, setPath] = useState('');
  const [name, setName] = useState('');
  const [preview, setPreview] = useState('');
  const { objects, dispatch1 } = React.useContext(ObjectContext);
  const [loading, setLoading] = useState(true);

  let elements = props.hashedFolder;
  if (objects && objects.length) {
    elements = objects;
  }
  const [mounted, setMounted] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(true);
  const { selection, dispatch2 } = useContext(ObjectSelection);

  useEffect(() => {
    setImages(parseInt(props?.counter || 1));
    setFiles(props?.files || []);
  }, []);
  const uuid = JSON.parse(localStorage.uuid);

  function imageUpdate(index, newDataUri) {
    setLoadingPreview(true);
    let images = imageSrc;
    const findIndexNow = images.findIndex((x) => x.id === parseInt(index));
    return new Promise((resolve, reject) => {
      if (index && newDataUri && findIndexNow > -1) {
        images[findIndexNow].imageSrc = newDataUri;
        setImageSrc(images);
      }
      setLoadingPreview(false);
      resolve([]);
      reject([]);
    });
  }
  const imagesList = () => {
    let array = [];
    elements?.map((image, index) => {
      array[image.name] = {
        imageSrc: image.path,
        id: index,
        name: image.name,
        initialImage: '',
        imageSrcProp: image.path,
        data: (
          <>
            <ReactImagePickerEditor
              id={`${index}`}
              config={{
                borderRadius: '8px',
                language: 'en',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                compressInitial: null,
                hideAddBtn: true,
                hideDeleteBtn: true,
              }}
              imageSrcProp={image.path}
              imageChanged={(newDataUri) => {
                if (newDataUri && newDataUri !== null && newDataUri !== '') {
                  updateImage(newDataUri, image, uuid);
                }
              }}
            />
            {/* <section className="col-md-12">
              <ColorSelector
                className="col-md-12"
                imgsrc={image.path}
                imageChanged={(newDataUri) => {
                  if (newDataUri && newDataUri !== null && newDataUri !== '') {
                    updateImage(newDataUri, image, uuid);
                  }
                }}
              />
              <canvas
                id="neweditcanvas"
                style={{
                  borderRadius: '8px',
                  height: '70vh',
                  width: '100%',
                }}
              ></canvas>
            </section> */}
          </>
        ),
      };
    });

    setImageSrc(array);
  };

  useEffect(() => {
    setMounted(true);
    imagesList();
    // setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    setLoadingPreview(true);
    setPath(imageSrc[selection.name]?.path || imageSrc[0]?.path);
    setName(selection?.name || imageSrc[0]?.name);
    setPreview(selection?.name || imageSrc[0]?.name);
    setTimeout(() => {
      setLoading(false);
      setLoadingPreview(false);
    }, 1000);
  }, [selection]);

  if (!mounted) {
    return <></>;
  }
  return (
    <section id={path} key={path}>
      {loading && imageSrc[selection.name] ? (
        <Loading />
      ) : (
        <>{imageSrc[selection.name]?.data ? imageSrc[selection.name]?.data : imageSrc[0]?.data}</>
      )}{' '}
    </section>
  );
}
