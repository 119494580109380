/**
* Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
* https://github.com/ddkits
* https://rymilan.com
* Important: To use this code please leave the copyright in place
* Free NFT website for RYMILAN, https://rymilan.com
*/
/* eslint-disable */

/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { NumberOfCopies, ObjectContext, ObjectSelection } from './EditingPage';
import { useTheme } from '@material-ui/core/styles';
import { TreeView } from '@material-ui/lab';
import TreeItem from '@material-ui/lab/TreeItem';
import { Typography } from '@material-ui/core';
import './buildFolder.css';

export const Folders = (props) => {
  const { objects, dispatch1 } = React.useContext(ObjectContext);
  const { selection, dispatch2 } = React.useContext(ObjectSelection);
  let folderStructure = [];
  const handleClick = (folder) => {
    dispatch1({
      type: 'update',
      nameToFind: folder.name,
      valueToChange: 'path',
      currentSlide: folder.path,
    });
    dispatch2({
      type: 'update',
      name: folder.name,
    });
  };

  return (
    <div className="col-md-12">
      {' '}
      <ListItem button component="a" href="#">
        <Typography
          className="element"
          style={{
            fontWeight: 'bold',
            fontFamily: 'monospace',
            backgroundColor: '#102841',
          }}
        >
          Uploaded Files
        </Typography>
      </ListItem>
      {props?.children &&
        props?.children?.map((folder, index1) => {
          if (
            folder.name?.includes('.webp') ||
            folder.name?.includes('.png') ||
            folder.name?.includes('.jpg') ||
            folder.name?.includes('.jpeg')
          ) {
            return (
              <div key={index1 * Math.random()}>
                {/* {folder?.map((subfolder, index2) => ( */}
                <button
                  key={index1}
                  className={
                    selection.name === folder.name
                      ? 'small btn-xs wrap-break maxw-100 bg-light text-dark p-0 m-1'
                      : 'small btn-xs wrap-break maxw-100 bg-dark text-light p-0 m-1'
                  }
                  onClick={() => handleClick(folder)}
                >
                  <ListItem button component="a" href="#">
                    <Typography
                      className="elementSubfolder"
                      style={{
                        fontFamily: 'monospace',
                      }}
                    >
                      {folder.name.split('___')[1]
                        ? folder.name.split('___')[folder.name.split('___').length - 1]
                        : folder.name.split('__')[1]
                        ? folder.name.split('__')[folder.name.split('__').length - 1]
                        : folder.name.split('_')[1]
                        ? folder.name.split('_')[folder.name.split('_').length - 1]
                        : folder.name}
                    </Typography>
                  </ListItem>
                </button>
                {/* ))} */}
                <TreeItem
                  nodeId="1"
                  label={
                    <ListItem root="true" component="a" href="#">
                      <Typography styles={{ backgroundColor: '#034b92' }}> </Typography>
                    </ListItem>
                  }
                />
              </div>
            );
          }
        })}
    </div>
  );
};
