/**
* Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
* https://github.com/ddkits
* https://rymilan.com
* Important: To use this code please leave the copyright in place
* Free NFT website for RYMILAN, https://rymilan.com
*/
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';
import { ObjectSelection, ObjectContext } from './EditingPage';
import './Items.css';
import NewEditor from '../neweditor';
import Loading from '../core/Loading';
const myTheme = {};
import { Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
const editorState = EditorState.createEmpty();

export const Items = (props) => {
  const { selection, dispatch2 } = React.useContext(ObjectSelection);
  const { objects, dispatch1 } = React.useContext(ObjectContext);
  const [loading, setLoading] = useState(true);
  const [editorStateValue, seteditorStateValue] = useState({ editorState });

  let elements = objects;
  if (objects && objects.length) {
    elements = objects;
  }
  useEffect(() => {
    if (elements?.length > 0) {
      setLoading(false);
    }
  }, [elements]);

  return loading ? (
    <Loading />
  ) : (
    <div>
      <div
        style={{
          height: `90%`,
          width: `100%`,
          position: 'relative',
        }}
        className="container row"
        ref={props.parent}
      >
        {elements &&
          // elements.map((file, index) => (
          //   <div
          //     key={index + file.name}
          //     className="col"
          //     onClick={() => props.onClick(`${file.name}`)}
          //   >
          //     {loading ? (
          //       []
          //     ) : (
          //       <>

          //       </>
          //     )}

          //     {/* <Rnd
          //       style={{
          //         zIndex: file.depth,
          //       }}
          //       onDragStop={(event) => {
          //         props.setCoord(event, file);
          //       }}
          //     >
          //       <img
          //         src={`${file.path}`}
          //         alt="x"
          //         style={{
          //           width: file.width,
          //           height: file.height,
          //         }}
          //         className="items"
          //       />
          //     </Rnd> */}
          //   </div>
          // ))
          (props?.newEditor === true ? (
            <Editor editorState={editorState} onChange={(e) => seteditorStateValue()} />
          ) : (
            <NewEditor
              onClick={(e) => props?.onClick(`${e}`)}
              counter={elements?.length}
              files={elements}
            />
          ))}
      </div>
    </div>
  );
};
