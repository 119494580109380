const wordToHex = {
  cornflowerblue: '6495ED',
  red: 'FF0000',
  whitesmoke: 'F5F5F5',
  bisque: 'FFE4C4',
  sienna: 'A0522D',
  slateblue: '6A5ACD',
  slategray: '708090',
  lawngreen: '7CFC00',
  tomato: 'FF6347',
  blanchedalmond: 'FFEBCD',
  darkviolet: '9400D3',
  plum: 'DDA0DD',
  salmon: 'FA8072',
  lightgoldenrodyellow: 'FAFAD2',
  cyan: '00FFFF',
  darkorange: 'FF8C00',
  lightgrey: 'D3D3D3',
  orangered: 'FF4500',
  indigo: '4B0082',
  orchid: 'DA70D6',
  chartreuse: '7FFF00',
  lavender: 'E6E6FA',
  darkkhaki: 'BDB76B',
  lightslategrey: '778899',
  firebrick: 'B22222',
  oldlace: 'FDF5E6',
  darksalmon: 'E9967A',
  white: 'FFFFFF',
  turquoise: '40E0D0',
  moccasin: 'FFE4B5',
  midnightblue: '191970',
  darkmagenta: '8B008B',
  navajowhite: 'FFDEAD',
  palegreen: '98FB98',
  mintcream: 'F5FFFA',
  lime: '00FF00',
  brown: 'A52A2A',
  darkolivegreen: '556B2F',
  lightseagreen: '20B2AA',
  teal: '008080',
  mediumvioletred: 'C71585',
  papayawhip: 'FFEFD5',
  mediumslateblue: '7B68EE',
  linen: 'FAF0E6',
  ghostwhite: 'F8F8FF',
  azure: 'F0FFFF',
  aquamarine: '7FFFD4',
  lightcoral: 'F08080',
  springgreen: '00FF7F',
  blue: '0000FF',
  lightslategray: '778899',
  cadetblue: '5F9EA0',
  beige: 'F5F5DC',
  deeppink: 'FF1493',
  gray: '808080',
  lightsalmon: 'FFA07A',
  violet: 'EE82EE',
  floralwhite: 'FFFAF0',
  skyblue: '87CEEB',
  navy: '000080',
  olive: '808000',
  mediumpurple: '9370DB',
  darkgrey: 'A9A9A9',
  chocolate: 'D2691E',
  darkturquoise: '00CED1',
  lightblue: 'ADD8E6',
  lightcyan: 'E0FFFF',
  orange: 'FFA500',
  darkgreen: '006400',
  maroon: '800000',
  mistyrose: 'FFE4E1',
  ivory: 'FFFFF0',
  steelblue: '4682B4',
  indianred: 'CD5C5C',
  darkseagreen: '8FBC8F',
  cornsilk: 'FFF8DC',
  saddlebrown: '8B4513',
  coral: 'FF7F50',
  lavenderblush: 'FFF0F5',
  black: '000000',
  dimgray: '696969',
  powderblue: 'B0E0E6',
  deepskyblue: '00BFFF',
  green: '008000',
  darkorchid: '9932CC',
  yellow: 'FFFF00',
  burlywood: 'DEB887',
  darkslategrey: '2F4F4F',
  darkcyan: '008B8B',
  fuchsia: 'FF00FF',
  palevioletred: 'DB7093',
  darkblue: '00008B',
  aqua: '00FFFF',
  peachpuff: 'FFDAB9',
  antiquewhite: 'FAEBD7',
  rebeccapurple: '663399',
  darkgray: 'A9A9A9',
  palegoldenrod: 'EEE8AA',
  limegreen: '32CD32',
  mediumblue: '0000CD',
  lemonchiffon: 'FFFACD',
  forestgreen: '228B22',
  mediumorchid: 'BA55D3',
  olivedrab: '6B8E23',
  tan: 'D2B48C',
  mediumturquoise: '48D1CC',
  mediumseagreen: '3CB371',
  paleturquoise: 'AFEEEE',
  crimson: 'DC143C',
  khaki: 'F0E68C',
  lightgreen: '90EE90',
  hotpink: 'FF69B4',
  lightpink: 'FFB6C1',
  lightgray: 'D3D3D3',
  slategrey: '708090',
  darkslategray: '2F4F4F',
  gainsboro: 'DCDCDC',
  dimgrey: '696969',
  sandybrown: 'F4A460',
  darkgoldenrod: 'B8860B',
  lightsteelblue: 'B0C4DE',
  darkslateblue: '483D8B',
  blueviolet: '8A2BE2',
  royalblue: '4169E1',
  yellowgreen: '9ACD32',
  gold: 'FFD700',
  aliceblue: 'F0F8FF',
  wheat: 'F5DEB3',
  dodgerblue: '1E90FF',
  purple: '800080',
  seagreen: '2E8B57',
  darkred: '8B0000',
  mediumaquamarine: '66CDAA',
  greenyellow: 'ADFF2F',
  goldenrod: 'DAA520',
  rosybrown: 'BC8F8F',
  seashell: 'FFF5EE',
  thistle: 'D8BFD8',
  honeydew: 'F0FFF0',
  magenta: 'FF00FF',
  peru: 'CD853F',
  silver: 'C0C0C0',
  mediumspringgreen: '00FA9A',
  lightskyblue: '87CEFA',
  snow: 'FFFAFA',
  pink: 'FFC0CB',
  lightyellow: 'FFFFE0',
  grey: '808080',
};

const hexToWord = Object.fromEntries(Object.entries(wordToHex).map(([k, v]) => [v, k]));
const letterToWord = (letter) => Object.values(wordToHex)[letter];

export const nametoHex = (color) => wordToHex[color.toLowerCase()];
export const letterToHex = (index, location) => {
  const one = index === 0 ? index + 1 : index;
  const two = location === 0 ? location + 1 : location;
  // function to generate the color by location and letter type
  const rule = Math.floor((one * 10) / (one * two));
  const color = letterToWord(rule);
  return color;
};
export const locationToColor = (index, location) => {
  const one = index === 0 ? index + 1 : index;
  const two = location === 0 ? location + 1 : location;
  // function to generate the color by location and letter type
  const rule = Math.floor((two * 20) / (one * two));
  const color = letterToWord(rule > 0 && rule < 148 ? rule : 1);
  return color;
};
export const letterToColor = (color) => wordToHex[color.toLowerCase()];

export const hexToName = (hex) => hexToWord[hex.toLowerCase()];
