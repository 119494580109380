import React from 'react';

/**
 * getStorageData
 * @param {string} name
 * @returns
 */
export const getStorageData = (name) => {
  const getOriginal = localStorage.getItem(name)
    ? JSON.parse(localStorage.getItem(name), true)
    : [];
  return getOriginal;
};

/**
 *storeStorageData
 * @param {string} name
 * @param {object} data
 * @returns
 */
export const storeStorageData = async (name, data) => {
  return new Promise((resolve, reject) => {
    localStorage.setItem(name, JSON.stringify(data));
    resolve({});
  });
};
/**
 *updateStorageData
 * @param {string} name
 * @param {object} data
 * @returns
 */
export const updateStorageData = (name, data) => {
  return storeStorageData(name, data);
};

/**
 *delStorageData
 * @param {string} name
 * @param {object} data
 * @returns
 */
export const delStorageData = (name) => {
  return new Promise((resolve) => {
    if (localStorage.getItem(name) && localStorage.getItem(name) !== '') {
      resolve(localStorage.removeItem(name));
    }
    resolve([]);
  });
};

/**
 *delStorageData
 * @param {string} name
 * @param {object} data
 * @returns
 */
export const bkupStorageData = async (name) => {
  const getOriginal = getStorageData(name);
  let getBkup = getStorageData('bkup');
  // return new Promise((resolve) => {

  //   getBkup.push({ name: name, data: getOriginal });
  //   resolve(storeStorageData(bkup-${uuid}, getBkup));
  // });
};
