/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { SliderComponent } from './Slider';
import { TextField } from '@material-ui/core';
import { NumberOfCopies, ObjectContext, ObjectSelection } from './EditingPage';
import { Button, Input } from '@material-ui/core';
import removeBackground from '@imgly/background-removal';
import { getBase64 } from '../core/Helpers';
import { toast } from 'react-toastify';
import { deleteAnImage } from './Functions';
export function calculateSecondsBetweenDates(startDate, endDate) {
  const milliseconds = endDate - startDate;
  const seconds = (milliseconds / 1000.0).toFixed(1);
  return seconds;
}

export const EditorInput = (props) => {
  // eslint-disable-next-line no-undef
  const { objects, dispatch1 } = React.useContext(ObjectContext);
  const { selection, dispatch2 } = React.useContext(ObjectSelection);
  const [currentValues, setCurrentValues] = useState([]);

  React.useEffect(() => {
    setCurrentValues(objects[objects?.findIndex((x) => x.name === selection?.name)]);
  }, [objects, selection.name]);

  const commonStyle = {
    margin: '10px',
    backgroundColor: '#c1c9d1',
    padding: '5px',
    borderRadius: '10px',
    boxShadow: '1px 3px 1px #afafaf',
  };
  const randomImage = objects[Math.floor(Math.random() * objects.length)];
  const [imageUrl, setImageUrl] = useState(randomImage);
  const [isRunning, setIsRunning] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [startDate, setStartDate] = useState(Date.now());
  const [caption, setCaption] = useState('Click me to remove background');

  useEffect(() => {
    let interval = null;
    if (isRunning) {
      interval = setInterval(() => {
        setSeconds(calculateSecondsBetweenDates(startDate, Date.now()));
      }, 100);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isRunning, startDate]);

  const resetTimer = () => {
    setIsRunning(true);
    setStartDate(Date.now());
    setSeconds(0);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const handleDeleteClick = () => {
    deleteAnImage(currentValues.path, JSON.parse(localStorage.getItem('uuid')));
    props?.newUpdateCallback();
  };
  const handleFinalClick = async () => {
    setIsRunning(true);
    resetTimer();
    await fetch(`${process.env.REACT_APP_API_CORE}/api/updateFileBg`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        file: currentValues.path,
        uuid: JSON.parse(localStorage.getItem('uuid')),
      }),
    })
      .then((res) => {
        toast('New Image created.');
        props?.newUpdateCallback();
      })
      .catch((err) => {
        toast(JSON.stringify(err));

        return err;
      });
    setIsRunning(false);
    stopTimer();
  };

  const handleReadTextClick = async () => {
    setIsRunning(true);
    resetTimer();
    await fetch(`${process.env.REACT_APP_API_CORE}/read-image-text`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        file: currentValues.path,
        uuid: JSON.parse(localStorage.getItem('uuid')),
      }),
    })
      .then((res) => {
        toast(res.message);
      })
      .catch((err) => {
        toast(JSON.stringify(err));

        return err;
      });
    setIsRunning(false);
    stopTimer();
  };
  const [height, setheight] = React.useState({
    name: 'height',
    value: objects[objects?.findIndex((x) => x.name === selection?.name)]?.height,
  });
  const [width, setwidth] = React.useState({
    name: 'width',
    value: objects[objects?.findIndex((x) => x.name === selection?.name)]?.width,
  });
  //const [input3, setInput3] = React.useState({ name: "depth", value: null });
  const [input4, setInput4] = React.useState({ value: null });

  return (
    <div
      style={{
        marginTop: '10px',
        backgroundColor: 'black',
        padding: '7px',
        borderRadius: '10px',
        boxShadow: '1px 3px 1px #acacaf',
      }}
    >
      <button disabled={isRunning} className="btn btn-danger" onClick={handleDeleteClick}>
        Delete
        <br />
        <small>
          <small>{selection.name}</small>
        </small>
      </button>
      <div style={commonStyle}>
        <div style={{ fontWeight: 'bold', fontFamily: 'monospace' }}>Total Copies:</div>

        <TextField
          size="small"
          defaultValue={100}
          inputProps={{ min: 0, style: { textAlign: 'center' } }}
          margin="dense"
          variant="outlined"
          onChange={(event) => {
            setInput4({ value: JSON.parse(event.target.value) });
          }}
          error={input4.value > 10000}
          helperText={input4 > 10000 ? 'Should be less than 10000' : ''}
        />
      </div>

      <div style={{ justifyContent: 'center', color: 'white' }}>
        <p>{caption}</p>
        <p>BG time: {seconds} s</p>
        <Button disabled={isRunning} variant="contained" color="primary" onClick={handleFinalClick}>
          Remove Background
        </Button>
      </div>
      <div style={{ justifyContent: 'center', color: 'white' }}>
        <Button
          disabled={isRunning}
          variant="contained"
          color="primary"
          onClick={handleReadTextClick}
        >
          Read Image Text
        </Button>
      </div>
    </div>
  );
};
