/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Routes } from 'react-router-dom';
import { EditingPage } from './EditingPage/EditingPage';
import { Fluidity } from './LoadingPage/Fluidity';
import { Pages } from './pages';
import './EditingPage/styles.css';
import { Selection } from './SelectionPage/Selection';
import MyOwn from './core/admin/MyOwn';
import Invest from './pages/Invest';
import { getAllNFTs } from './core/APIs';
import { AboutModalComponent } from './pages/AboutModal';
import { ContactModalComponent } from './pages/ContactModal';
import { InstructionsModalComponent } from './pages/InstructionsModal';
import { NavHomePage } from './pages/navigationBar';
import './style.css';
import Loading from './core/Loading';
import { v4 } from 'uuid';
import { userStore } from './core/userStore';
import axios from 'axios';
import { ethers } from 'ethers';
import InvestPage from './pages/InvestPage';
import { InvestWithUs } from './pages/InvestWithUs';
import metaTags from './core/MetaTags';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import ByNamezone from './SelectionPage/ByNamezone';

export default function App() {
  const [mounted, setMounted] = useState(false);
  const [openAbout, setAboutOpen] = useState(false);
  const [openContact, setContactOpen] = useState(false);
  const [openInstructions, setInstructionsOpen] = useState(false);
  const sessionID = userStore((s) => s.sessionID);
  const [investWithUs, setInvestOpen] = useState(false);

  let sessionNow = localStorage.getItem('uuid');
  useEffect(() => {
    if (sessionID !== '') {
      localStorage.setItem('uuid', JSON.stringify(sessionID));
    } else if (sessionNow !== '' && sessionNow !== null) {
      userStore.setState({
        sessionID: sessionNow,
      });
    } else {
      const ID = { uuid: v4() };
      userStore.setState({
        sessionID: JSON.stringify(ID.uuid),
      });
      localStorage.setItem('uuid', JSON.stringify(ID.uuid));
      // axios.post(process.env.REACT_APP_API_CORE + '/saveID', ID);
    }
  }, []);

  useEffect(() => {
    getAllNFTs().then((x) => {
      // metatags
      metaTags({
        sitename: 'RYMILAN',
        title: 'RYMILAN',
        author: `RYMILAN`,
        description: 'RYMILAN | A Web3 tool to take the pain of creating NFTs Away from you.',
        keywords:
          'rymilan, ryan, milan, nft, nfts, free nft, win nft, build free nft, mint nft, mint, opensea, win, winner, free',
        image: 'https:/rymilan.com/logo.png',
      });
      setTimeout(() => {
        setMounted(true);
      }, 100);
    });
  }, []);
  const handleCloseAbout = () => {
    setAboutOpen(false);
  };
  const handleCloseContact = () => {
    setContactOpen(false);
  };
  const handleClosInvest = () => {
    setInvestOpen(false);
  };
  const handleCloseInstructions = () => {
    setInstructionsOpen(false);
  };

  return !mounted ? (
    <Loading />
  ) : (
    <section id="app">
      <div id="general-background">
        <div id="general-inner-container ">
          <div>
            <NavHomePage
              setAboutOpen={setAboutOpen}
              setContactOpen={setContactOpen}
              setInstructionsOpen={setInstructionsOpen}
              setInvestOpen={setInvestOpen}
            />
          </div>
          <AboutModalComponent
            className=" shadow-lg m-1 p-3 rounded"
            isOpen={openAbout}
            handleClose={handleCloseAbout}
          />
          <InvestWithUs
            className=" shadow-lg m-1 p-3 rounded"
            isOpen={investWithUs}
            handleClose={handleClosInvest}
          />
          <InstructionsModalComponent
            isOpen={openInstructions}
            handleClose={handleCloseInstructions}
            className=" shadow-lg m-1 p-3 rounded"
          />
          <div className="inner-container  shadow-lg m-1 p-3 rounded">
            <Routes>
              <Route exact path="/" element={<Pages />} />
              <Route path="/selection" element={<Selection />} />
              <Route path="/avatar-by-name" element={<ByNamezone />} />
              <Route path="/editing" element={<EditingPage />} />
              <Route path="/loading" element={<Fluidity />} />
              <Route path="/invest/:chain/:collection" element={<Invest />} />
              <Route path="/invest/:chain/:collection/:item" element={<InvestPage />} />
              {/* <Route path="/editor" element={<NewEditor />} />      */}
              <Route path="/myown" element={<MyOwn />} />
            </Routes>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}
