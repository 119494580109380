/* eslint-disable no-undef */
import React, { createRef, useEffect, useRef, useState } from 'react';
import { createNewImage, getOwnNfts, submitDetails } from '../../core/APIs';
import { create_slug, userStore } from '../../core/userStore';
import { loadImage } from 'canvas';
import './styles.css';
import Loading from '../../core/Loading';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { deleteAnImage, updateImage } from '../../EditingPage/Functions';
import { toast } from 'react-toastify';
import * as FA from 'react-icons/fa6';
import { Button, MenuItem, Select } from '@material-ui/core';
import { calculateSecondsBetweenDates } from '../../EditingPage/EditorInput';
import { getStorageData, storeStorageData, updateStorageData } from '../../core/StorageCont';
import CustomAvatars from './avatars';
import { createAvatar } from '@dicebear/core';
import { lorelei, avataaars } from '@dicebear/collection';
import { getColorsByName } from './colorSelector/NameColors';
const nameList = [
  'Time',
  'Past',
  'Future',
  'Dev',
  'Fly',
  'Flying',
  'Soar',
  'Soaring',
  'Power',
  'Falling',
  'Fall',
  'Jump',
  'Cliff',
  'Mountain',
  'Rend',
  'Red',
  'Blue',
  'Green',
  'Yellow',
  'Gold',
  'Demon',
  'Demonic',
  'Panda',
  'Cat',
  'Kitty',
  'Kitten',
  'Zero',
  'Memory',
  'Trooper',
  'XX',
  'Bandit',
  'Fear',
  'Light',
  'Glow',
  'Tread',
  'Deep',
  'Deeper',
  'Deepest',
  'Mine',
  'Your',
  'Worst',
  'Enemy',
  'Hostile',
  'Force',
  'Video',
  'Game',
  'Donkey',
  'Mule',
  'Colt',
  'Cult',
  'Cultist',
  'Magnum',
  'Gun',
  'Assault',
  'Recon',
  'Trap',
  'Trapper',
  'Redeem',
  'Code',
  'Script',
  'Writer',
  'Near',
  'Close',
  'Open',
  'Cube',
  'Circle',
  'Geo',
  'Genome',
  'Germ',
  'Spaz',
  'Shot',
  'Echo',
  'Beta',
  'Alpha',
  'Gamma',
  'Omega',
  'Seal',
  'Squid',
  'Money',
  'Cash',
  'Lord',
  'King',
  'Duke',
  'Rest',
  'Fire',
  'Flame',
  'Morrow',
  'Break',
  'Breaker',
  'Numb',
  'Ice',
  'Cold',
  'Rotten',
  'Sick',
  'Sickly',
  'Janitor',
  'Camel',
  'Rooster',
  'Sand',
  'Desert',
  'Dessert',
  'Hurdle',
  'Racer',
  'Eraser',
  'Erase',
  'Big',
  'Small',
  'Short',
  'Tall',
  'Sith',
  'Bounty',
  'Hunter',
  'Cracked',
  'Broken',
  'Sad',
  'Happy',
  'Joy',
  'Joyful',
  'Crimson',
  'Destiny',
  'Deceit',
  'Lies',
  'Lie',
  'Honest',
  'Destined',
  'Bloxxer',
  'Hawk',
  'Eagle',
  'Hawker',
  'Walker',
  'Zombie',
  'Sarge',
  'Capt',
  'Captain',
  'Punch',
  'One',
  'Two',
  'Uno',
  'Slice',
  'Slash',
  'Melt',
  'Melted',
  'Melting',
  'Fell',
  'Wolf',
  'Hound',
  'Legacy',
  'Sharp',
  'Dead',
  'Mew',
  'Chuckle',
  'Bubba',
  'Bubble',
  'Sandwich',
  'Smasher',
  'Extreme',
  'Multi',
  'Universe',
  'Ultimate',
  'Death',
  'Ready',
  'Monkey',
  'Felix',
  'Anika',
  'Elevator',
  'Wrench',
  'Grease',
  'Head',
  'Theme',
  'Grand',
  'Cool',
  'Kid',
  'Boy',
  'Girl',
  'Vortex',
  'Paradox',
];
let options = {
  seed: nameList[Math.floor(Math.random() * nameList.length)],
  // background color
  backgroundType: ['transparent'],
  // transparent, gradientLinear
  backgroundColor: ['transparent'],
  // include or exclude passed options.
  mode: 'include',
  // 'longHair' | 'shortHair' | 'eyepatch' | 'hat' | 'hijab' | 'turban'
  top: ['longHair'],
  // in percent
  randomizeIds: true,
  // 'black' | 'blue' | 'gray' | 'heather' | 'pastel' | 'pink' | 'red' | 'white'
  hatColor: ['auburn'],
  // 'auburn' | 'black' | 'blonde' | 'brown' | 'pastel' | 'platinum' | 'red' | 'gray'
  hairColor: ['auburn', 'black', 'blonde', 'brown', 'pastel', 'platinum', 'red', 'gray'],
  // 'black' | 'blue' | 'gray' | 'heather' | 'pastel' | 'pink' | 'red' | 'white'
  accessories: ['eyepatch', 'kurt', 'prescription01'],
  accessoriesProbability: 0,
  // in percent
  accessoriesChance: 10,
  // 'black' | 'blue' | 'gray' | 'heather' | 'pastel' | 'pink' | 'red' | 'white'
  accessoriesColor: ['3c4f5c', '65c9ff', '262e33'],
  // 'medium' | 'light' | 'majestic' | 'fancy' | 'magnum'
  // in percent
  facialHairChance: 10,
  // 'auburn' | 'black' | 'blonde' | 'brown' | 'pastel' | 'platinum' | 'red' | 'gray'
  // 'blazer' | 'sweater' | 'shirt' | 'hoodie' | 'overall'
  clothing: ['blazerAndShirt', 'blazerAndSweater', 'collarAndSweater'],
  // 'black' | 'blue' | 'gray' | 'heather' | 'pastel' | 'pink' | 'red' | 'white'
  clothesColor: ['black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'],
  clothingGraphic: ['bat', 'bear', 'cumbia', 'cat', 'name', 'dog', 'tiger'],
  // Possible values: close, cry, default, dizzy, roll, happy, hearts, side, squint, surprised, wink, winkWacky
  eyes: [
    'close',
    'cry',
    'default',
    'dizzy',
    'roll',
    'happy',
    'hearts',
    'side',
    'squint',
    'surprised',
    'wink',
    'winkWacky',
  ],
  size: 1000,
  // 'angry' | 'default' | 'flat' | 'raised' | 'sad' | 'unibrow' | 'up' | 'frown'
  eyebrows: ['angry', 'angryNatural', 'default'],
  facialHair: ['beardLight', 'beardMajestic', 'beardMedium'],
  facialHairColor: ['2c1b18', '4a312c', '724133'],
  // Possible values: concerned, default, disbelief, eating, grimace, sad, scream, serious, smile, tongue, twinkle, vomit
  mouth: [
    'concerned',
    'default',
    'disbelief',
    'eating',
    'grimace',
    'sad',
    'scream',
    'serious',
    'smile',
    'tongue',
    'twinkle',
    'vomit',
  ],
  // tanned' | 'yellow' | 'pale' | 'light' | 'brown' | 'darkBrown' | 'black'
  skin: [],
};
export default function DrawNewImage(props) {
  const [image, setImage] = useState('');
  const [imageMetaData, setimageMetaData] = useState('');
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [images, setImages] = useState([]);
  const [imagesGrouped, setImagesGrouped] = useState([]);
  const [filename, setFilename] = useState(`new_${Math.ceil(Math.random() * 9292929)}`);
  const [layer, setLayer] = useState('layer1');
  const [imageDataNow, setimageData] = useState('');
  const [bgColor, setbgColor] = useState('#ffffff');
  const uuid = userStore((s) => s.sessionID);
  const [originalData, setOriginalData] = useState([]);
  const canvasRef = createRef(null);
  const [lineCap, setlineCap] = useState('round');
  const [lineWidth, setlineWidth] = useState(5);
  const [strokeStyle, setstrokeStyle] = useState('#000000');
  const [eraseMode, seteraseMode] = useState(false);
  const activeBefore = localStorage.getItem(`active-${JSON.parse(uuid)}`) ?? '';
  const [activeTabName, setactiveTabNameFinal] = useState('');
  const [onChange, setonChange] = useState(false);
  const [onCanvasChange, setonCanvasChange] = useState(false);
  const activeTab = createRef(null);
  const [isRunning, setIsRunning] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [startDate, setStartDate] = useState(Date.now());
  const [saveWithBackground, setsaveWithBackground] = useState(true);
  const [caption, setCaption] = useState('');
  const dataBefore = getStorageData(`data-${JSON.parse(uuid)}`) ?? [];
  const setdataBefore = (data) => storeStorageData(`data-${JSON.parse(uuid)}`, data);
  const configBefore = getStorageData(`config-${JSON.parse(uuid)}`) ?? [];
  const setconfigBefore = (data) => storeStorageData(`config-${JSON.parse(uuid)}`, data);
  const setactiveBefore = (data) => storeStorageData(`active-${JSON.parse(uuid)}`, data); // Assign which layer would be
  const [copies, setCopies] = useState(1);
  const [colorLetters, setcolorLetters] = useState('');
  const [finalColor, setFinalColor] = useState(['white']);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [showFilesList, setshowFilesList] = useState(false);
  const findwordColor = async (e) => {
    e.preventDefault();
    setcolorLetters(e.target.value);
    let value = e.target.value;
    // count the letters
    // split the letters into array
    // get the final color result
    const color = await getColorsByName(value);
    setFinalColor(color || ['white']);
    // show personal final color
  };
  const createAvatarNew = async (options) => {
    return new Promise((resolve) => {
      const avatar = createAvatar(avataaars, options);
      const svg = avatar.png();
      resolve(svg);
    });
  };
  //   Localstorage functionalitites

  const setactiveTabName = (name) => {
    setactiveTabNameFinal(name);
    setactiveBefore(name);
  };
  const convertBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let fileInfo = [];
      let baseURL = '';
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = (event) => {
        // Make a fileInfo Object
        fileInfo = event.target;
        console.log(fileInfo);
        baseURL = event.target.result;
        resolve(baseURL);
      };
      reader.onerror = (error) => {
        console.error('Error: ', error);
        reject(error);
      };
    });
  };
  // send api call to generate nft

  //   canvas configurations

  useEffect(() => {
    // temp testing
    // localStorage.removeItem(`data-${uuid}`);
    if (uuid.length > 0) {
      //   create the blank design for this user /create-new-image
      const oldData = getStorageData('images');
      if (oldData?.length > 0) {
        setImages(oldData);
        setactiveTabName(oldData[0]?.name);
        setimageMetaData(oldData[0]?.metadata);
        setimageData(oldData[0]?.data);
        setFilename(oldData[0]?.name);
        setLayer(oldData[0]?.layer);
        setLoadingPreview(false);
      } else {
        createNewImage(JSON.parse(uuid), layer, filename).then(async (result) => {
          const base64 = await convertBase64(result.path);
          const newImages = [
            ...images,
            {
              imageSrc: result.path,
              id: create_slug(result.path),
              metadata: result.metadata,
              layer: `${layer}`,
              name: filename,
              initialImage: base64,
              imageSrcProp: result.path,
              data: base64,
              strokeStyle: strokeStyle,
              canvasWidth: 1000,
              canvasHeight: 1000,
              lineCap: lineCap,
              lineWidth: lineWidth,
              bgColor: bgColor,
              bgImage: result.path,
            },
          ];
          await setimageData(base64);
          await saveImage(base64, filename);
          await setImages(newImages);
          await makeTheChanges(newImages);

          let imagesDone = false;
          let counter = 100;
          setTimeout(async () => {
            while (!imagesDone && counter >= 0) {
              if (newImages.Length > 0) {
                imagesDone = true;
              }
              imagesDone = false;
              counter--;
            }
            const index = newImages?.findIndex(
              (x) => create_slug(x?.name) === create_slug(filename)
            );
            await setactiveTabName(filename);
            await setimageMetaData(result.metadata);
            await setImage(result.path);
            await setLayer(layer);
            await setconfigBefore({
              filename: filename,
              metadata: result.metadata,
              image: result.path,
            });
            await setLoadingPreview(false);
          }, 1000);
        });
      }
    }
  }, []);
  //   group images
  function groupBy(collection, property) {
    var i = 0,
      val,
      index,
      values = [],
      result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  const changeLayerCallback = async (e, nfilename, nimage, index) => {
    let newImages = images;
    const layer1 = newImages[index]?.layer;
    console.log(newImages[index]);
    const layer2 = `layer${e.target.value}`;
    return new Promise((resolve, reject) => {
      Object.keys(newImages[index]).forEach((key) => {
        if (typeof newImages[index][key] === 'string') {
          newImages[index][key] = newImages[index][key]?.replaceAll(layer1, layer2);
        }
      });
      console.log(newImages[index]);
      setImages(newImages);
      handleFinalUpdateDetailsClick(nimage, layer1, layer2, JSON.parse(uuid))
        .then(async () => {
          setLayer(layer2);
          await makeTheChanges(newImages);

          setonChange(true);
          setonCanvasChange(true);
          resolve([]);
        })
        .catch((x) => reject(console.log(x)));
    });

    // update the image folder
    //   need to move the file from old layer folder to new one using file path
  };
  const changeFilenameCallback = async (e, filename, image, index) => {
    let newImages = images;
    newImages[index].name = e.target.value;

    await setImages(newImages);
    await makeTheChanges(newImages);

    setonChange(true);
    // update the image folder
    //   need to move the file from old layer folder to new one using file path
  };

  const stopTimer = () => {
    setIsRunning(false);
  };
  const resetTimer = () => {
    setIsRunning(true);
    setStartDate(Date.now());
    setSeconds(0);
  };
  const onTabChangeupdates = (index) => {
    let result = images[index];
    setactiveTabName(result.name);
  };

  useEffect(() => {
    let interval = null;
    if (isRunning) {
      interval = setInterval(() => {
        setSeconds(calculateSecondsBetweenDates(startDate, Date.now()));
      }, 100);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isRunning, startDate]);

  const handleFinalRemoveBGClick = async (nimage, nLayer, nuuid, index, undo = false) => {
    setIsRunning(true);
    resetTimer();
    if (undo) {
      let newImages = images;
      const prenewPath = newImages[index].imageSrc?.split('/');
      prenewPath[prenewPath.length - 1] = prenewPath[prenewPath.length - 1]
        .replace('NOBG-', '')
        .replace('.png.png', '.png');
      const newPath = prenewPath.join('/');
      newImages[index].imageSrc = newPath;
      newImages[index].imageSrcProp = newPath;
      newImages[index].bgImage = newPath;
      const base64 = await convertBase64(newPath);
      newImages[index].data = base64;
      await setImages(newImages);
      await makeTheChanges(newImages);
      //   setonChange(true);
      setonCanvasChange(true);
      setIsRunning(false);
      stopTimer();
      return;
    } else {
      return await fetch(`${process.env.REACT_APP_API_CORE}/api/updateFileBg`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          file: nimage,
          layer: nLayer,
          uuid: nuuid,
        }),
      })
        .then(async (res) => {
          toast('New Image created.');
          let newImages = images;
          const prenewPath = newImages[index].imageSrc?.split('/');
          prenewPath[prenewPath.length - 1] = `NOBG-${prenewPath[prenewPath.length - 1]}.png`;
          const newPath = prenewPath.join('/');
          newImages[index].imageSrc = newPath;
          newImages[index].imageSrcProp = newPath;
          newImages[index].bgImage = newPath;
          newImages[index].bgColor = '#ffffff';
          const base64 = await convertBase64(newPath);
          newImages[index].data = base64;
          setimageData(base64);
          await setImages(newImages);
          await makeTheChanges(newImages);
          setonChange(true);
          setonCanvasChange(true);
          setIsRunning(false);
          stopTimer();
          return;
        })
        .catch((err) => {
          toast(JSON.stringify(err));
          setIsRunning(false);
          stopTimer();
          return err;
        });
    }
  };
  const handleFinalUpdateDetailsClick = (nimage, layer1, layer2, nuuid) => {
    setIsRunning(true);
    resetTimer();
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_CORE}/api/updateFileDetails`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          file: nimage,
          layer1: layer1,
          layer2: layer2,
          uuid: nuuid,
        }),
      })
        .then((resp) => {
          const data = resp.json();
          setIsRunning(false);
          stopTimer();
          resolve(data);
        })
        .catch((err) => {
          setIsRunning(false);
          stopTimer();
          reject(err);
        });
    });
  };

  const changeStrokeStyleCallback = async (e, filename, image, index) => {
    e.preventDefault();
    // newImages[index].strokeStyle = e.target.value;
    // await setImages(newImages);
    // await updateStorageData('images', newImages);
    setstrokeStyle(e.target.value);
    // update the image folder
    //   need to move the file from old layer folder to new one using file path
  };
  const changeLineWidthCallback = async (e, filename, image, index) => {
    e.preventDefault();
    // newImages[index].lineWidth = e.target.value;
    // await setImages(newImages);
    // await updateStorageData('images', newImages);
    setlineWidth(e.target.value);
    // update the image folder
    //   need to move the file from old layer folder to new one using file path
  };
  const changelineCapCallback = async (e, filename, image, index) => {
    e.preventDefault();
    // newImages[index].lineCap = e.target.value;
    // await setImages(newImages);
    setlineCap(e.target.value);
    // update the image folder
    //   need to move the file from old layer folder to new one using file path
  };

  const changeBGCallback = async (e, filename, image, index) => {
    setLoadingPreview(true);
    setIsRunning(true);
    resetTimer();
    let newImages = images;
    newImages[index].bgColor = e.target.value;
    newImages[index].bgImage = '';
    newImages[index].data = '';
    setimageData('');
    await setImages(newImages);
    await makeTheChanges(newImages);

    setonChange(true);
    setonCanvasChange(true);
    setLoadingPreview(false);
    setIsRunning(false);
    stopTimer();
    // update the image folder
    //   need to move the file from old layer folder to new one using file path
  };
  const changebgImageCallback = async (e, filename, imagen, index) => {
    const file = e.target.files[0];
    const base64 = await getBase64(file);

    setIsRunning(true);
    resetTimer();

    return new Promise((resolve, reject) => {
      return saveImageToServer(base64, filename, imagen, index)
        .then(async () => {
          let newImages = images;
          newImages[index].bgImage = filename;
          newImages[index].data = base64;
          setimageData(base64);
          await setImages(newImages);
          await makeTheChanges(newImages);

          setLoadingPreview(true);
          setTimeout(() => {
            setonCanvasChange(true);
            setLoadingPreview(false);
            setIsRunning(false);
            stopTimer();
            resolve([]);
          }, 3000);
        })
        .catch((err) => {
          setonCanvasChange(true);
          setLoadingPreview(false);
          setIsRunning(false);
          stopTimer();
          reject([]);
        });
    });

    // update the image folder
    //   need to move the file from old layer folder to new one using file path
  };
  const changeResetSketchCallback = async (e, filename, image, index) => {
    setIsRunning(true);
    resetTimer();
    localStorage.clear();
    window.location.reload();
    // update the image folder
    //   need to move the file from old layer folder to new one using file path
  };
  const changeDeleteSketchCallback = async (e, filename, image, index) => {
    setIsRunning(true);
    resetTimer();
    let newImages = images;
    newImages.splice(index, 1);
    await deleteAnImage(image, JSON.parse(uuid));
    await setImages(newImages);
    await makeTheChanges(newImages);

    setactiveTabName(newImages[index - 1].name);
    setonChange(true);
    setonCanvasChange(true);
    setIsRunning(false);
    stopTimer();
    // update the image folder
    //   need to move the file from old layer folder to new one using file path
  };
  const generateNFTCallback = async () => {
    setIsRunning(true);
    resetTimer();
    const sendObjects = images.map((x, xds) => {
      return {
        name: x?.name,
        path: x?.imageSrc,
        layer: x?.layer,
        key: xds * Math.random(),
        height: 1000,
        width: 1000,
        depth: 0,
        x: 0,
        y: 0,
      };
    });
    const data = {
      objects: sendObjects,
      total: { value: copies },
      uuid: JSON.parse(uuid),
      canvasHeight: 1000,
      canvasWidth: 1000,
      folderTree: { name: JSON.parse(uuid), children: images },
      name: name,
      description: description,
      URL: 'https://rymilan.com',
    };
    submitDetails(data).then((result) => {
      let newImages = images;
      newImages.push(result);
      setImages(newImages);
      setdataBefore(newImages);
      setonChange(true);
      setonCanvasChange(true);
    });
  };
  function imageUpdate(index, newDataUri) {
    setIsRunning(true);
    resetTimer();
    setLoadingPreview(true);
    let images = image.path;
    const findIndexNow = images.findIndex((x) => x?.id === parseInt(index));
    return new Promise((resolve, reject) => {
      if (index && newDataUri && findIndexNow > -1) {
        images[findIndexNow].imageSrc = newDataUri;
        setImage(images);
        setLoadingPreview(false);
        resolve([]);
      }
      reject([]);
    });
  }

  const saveImage = async (data, name) => {
    setimageData(data);
    await storeStorageData(name, data).then(() => {
      setonCanvasChange(true);
      setLoadingPreview(true);
    });
  };
  const handleAvaCallback = (data, imageSrc) => [
    updateImage(data, { path: `${imageSrc}` }, JSON.parse(uuid))
      .then(async () => {
        setonCanvasChange(true);
        setLoadingPreview(true);
        return;
      })
      .catch((err) => console.log(err)),
  ];
  const saveImageToServer = async (data, imageSrc, name, index) => {
    await saveImage(data, name);
    return new Promise((resolve, reject) => {
      // Store tem in local storage as data
      updateImage(data, { path: `${imageSrc}` }, JSON.parse(uuid))
        .then(async () => {
          setonCanvasChange(true);
          setLoadingPreview(true);
          resolve([]);
        })
        .catch((err) => resolve([]));
    });
  };

  const [myOwnNfts, setMyOwnNfts] = useState([]);
  const getMyOwnNfts = () => {
    getOwnNfts(JSON.parse(uuid)).then((data) => {
      setMyOwnNfts(data);
    });
  };

  function drawImageScaled(obj, id) {
    // var width = canvas.width + intensity * (canvas.width / canvas.height);
    // var height = canvas.height + intensity;
    // var x = 0 - (posX / canvas.width) * (width - canvas.width);
    // var y = 0 - (posY / canvas.height) * (height - canvas.height);
    // ctx?.drawImage(img, x, y, width, height);
    // Once the image is loaded, we will get the width & height of the image
    if (canvasRef?.current) {
      canvasRef.current.backgroundImage = obj.image;
    }
  }

  useEffect(() => {
    const newImagesG = groupBy(images, 'layer');
    setImagesGrouped(newImagesG);
    setTimeout(() => {
      setdataBefore(newImagesG);
    }, 500);
    if (images?.length > 0 && activeTabName !== '') {
      const index = images?.findIndex((x) => create_slug(x?.name) === create_slug(filename));
      setimageMetaData(images[index].metadata);
      setImage(images[index].imageSrc);
      setLayer(images[index].layer);
      setbgColor(images[index].bgColor);
    }
  }, [activeTabName]);

  function openNav() {
    document.getElementById('filelistnav').style.width = '250px';
    document.getElementById('drawing-board').style.marginLeft = '250px';
    document.body.style.backgroundColor = 'rgba(0,0,0,0.4)';
  }

  /* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
  function closeNav() {
    document.getElementById('filelistnav').style.width = '0';
    document.getElementById('drawing-board').style.marginLeft = '0';
    document.body.style.backgroundColor = 'white';
  }
  var imageBlob = new Image();
  imageBlob.onload = function () {};
  useEffect(() => {
    setTimeout(() => {
      setonChange(false);
      setIsRunning(false);
      stopTimer();
    }, 50);
  }, [onChange]);

  const [loading, setLoading] = useState(false);
  const makeTheChanges = async (images) => {
    setLoading(true);
    await updateStorageData('images', images);
    await setdataBefore(images);
    await updateStorageData('imagesGrouped', groupBy(images, 'layer'));
    await setImagesGrouped(images.length > 0 ? groupBy(images, 'layer') : []);
    setonChange(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  useEffect(() => {
    makeTheChanges(images);
  }, [images]);

  useEffect(() => {
    setTimeout(() => {
      setonCanvasChange(false);
      getMyOwnNfts();
      setIsRunning(false);
      stopTimer();
    }, 500);
  }, [onCanvasChange]);

  return (
    <>
      <div id="loading" {...(!onChange || !onCanvasChange || !loading ? { hidden: true } : [])}>
        LOADING...
      </div>
      <div id="filelistnav" className={showFilesList ? 'filelistnav active' : 'filelistnav'}>
        <button className="closebtn" onClick={() => closeNav()}>
          &times;
        </button>
        <div className="  layers-sidebar">
          {imagesGrouped.length > 0
            ? imagesGrouped.map((key) => {
                const layerName = key[0].layer;
                const element = key;
                return (
                  <ul
                    className="col-md-12 center m-1 p-1 text-align-center"
                    key={Math.random() * 99999}
                  >
                    {layerName === 'layer1' ? 'Background(s)' : layerName}
                    {element.map((x, index) => {
                      return (
                        <button
                          key={x?.name + 'tab'}
                          className={`nav-link text-align-center ${
                            activeTabName === x?.name ? 'active glowNew' : ''
                          } `}
                          id={`nav-${x?.name}-tab`}
                          onClick={(e) => {
                            e.preventDefault();
                            saveImage(x?.data, x?.name);
                            setactiveTabName(x?.name);
                          }}
                        >
                          {x?.name}
                        </button>
                      );
                    })}{' '}
                  </ul>
                );
              })
            : []}

          <ul className="col-md-12 center m-1 p-1 text-align-center" key={Math.random() * 99999}>
            Generated NFTs
            {!onChange && myOwnNfts.length > 0
              ? myOwnNfts
                  .filter((x) => x)
                  .map((x) => {
                    return (
                      <a
                        role="link"
                        href={x?.fileName}
                        key={x?.name + 'tab'}
                        target="blank"
                        className="nav-link text-align-center"
                        id={`nav-${x?.name}-tab`}
                      >
                        {x?.name}
                      </a>
                    );
                  })
              : []}
          </ul>
        </div>
      </div>
      <main id="drawing-board" className="container-fluid inner-container p-1 drawing-board">
        {images.length > 0 ? (
          <>
            <div className="col-md-12 row">
              <h3 className="col pull-end">
                {' '}
                {isRunning ? <small>Processing: {seconds} </small> : '0s'}
              </h3>
            </div>
            <div className="tab-content " id="nav-tabContent">
              {images.length > 0
                ? images.map((x, index) => {
                    return (
                      <div
                        key={x?.name + '-canvas'}
                        className={`col-md-12 tab-pane  ${
                          activeTabName === x?.name ? 'show active d-flex' : ''
                        } `}
                        id={`nav-${x?.name}`}
                        role="tabpanel"
                        aria-labelledby={`nav-${x?.name}-tab`}
                      >
                        <section id="toolbar" className="col-2">
                          <>
                            <section key={activeTabName}>
                              <div className="col-12 dropdown">
                                {!showFilesList ? (
                                  <span
                                    className="col pull-start fileslist btn btn-secondary p-1 mb-1 col-md-12"
                                    onClick={() => openNav()}
                                  >
                                    <>
                                      {`Files list`} <FA.FaArrowRight size={20} />{' '}
                                    </>
                                  </span>
                                ) : (
                                  <FA.FaArrowLeft onClick={() => openClose()} size={20} />
                                )}
                              </div>
                              <div className="col-12 dropdown">
                                <a
                                  className="btn btn-secondary dropdown-toggle p-1 mb-1 col-md-12"
                                  href="#"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  File
                                </a>

                                <ul className="dropdown-menu">
                                  <li>
                                    <label htmlFor="filename" className="col-md-12">
                                      File name
                                    </label>
                                    <input
                                      id="filename"
                                      name="filename"
                                      type="text"
                                      value={x?.name}
                                      className="col-md-12"
                                      disabled
                                      onChange={(e) =>
                                        changeFilenameCallback(e, x?.name, x?.imageSrc, index)
                                      }
                                    />
                                    <input
                                      id="uuid"
                                      name="uuid"
                                      type="text"
                                      value={JSON.parse(uuid)}
                                      className="col-md-12"
                                      disabled
                                    />
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      const newfilename = `new_${Math.ceil(
                                        Math.random() * 9292929
                                      )}`;
                                      createNewImage(
                                        JSON.parse(uuid),
                                        x?.layer || 'layer1',
                                        newfilename
                                      ).then(async (result) => {
                                        const imageData = await loadImage(result.path);
                                        const base64 = await convertBase64(result.path);
                                        const newImages = [
                                          ...images,
                                          {
                                            imageSrc: result.path,
                                            id: create_slug(result.path),
                                            metadata: result.metadata,
                                            layer: x?.layer || 'layer1',
                                            name: newfilename,
                                            initialImage: base64,
                                            imageSrcProp: result.path,
                                            data: base64,
                                            strokeStyle: strokeStyle,
                                            canvasWidth: 1000,
                                            canvasHeight: 1000,
                                            lineCap: lineCap,
                                            lineWidth: lineWidth,
                                            bgImage: result.path,
                                            bgColor: '#ffffff',
                                          },
                                        ];
                                        setImages(newImages);

                                        setactiveTabName(newfilename);
                                        setimageMetaData(result.metadata);
                                        setImage(result.path);
                                        setstrokeStyle(strokeStyle);
                                        setbgColor(bgColor);
                                      });
                                    }}
                                  >
                                    Add New Image
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      createAvatarNew(options).then(async (x) => {
                                        saveImageToServer(x, x?.imageSrc, x?.name, index).then(
                                          () => {
                                            saveImage(x);
                                          }
                                        );
                                      });
                                    }}
                                  >
                                    Generate New Avatar
                                  </li>
                                  <li className="dropdown-item">
                                    <hr />
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      changeDeleteSketchCallback(e, x?.name, x?.imageSrc, index);
                                    }}
                                  >
                                    Delete {x?.name}
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      canvasRef?.current.clearCanvas();
                                    }}
                                  >
                                    Clear Sketch
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      canvasRef?.current.resetCanvas();
                                      canvasRef.current.backgroundImage = '';
                                      changeResetSketchCallback(e, x?.name, x?.imageSrc, index);
                                      setonCanvasChange(true);
                                    }}
                                  >
                                    Reset Sketch
                                  </li>
                                  <li className="dropdown-item">
                                    <hr />
                                  </li>
                                  <li className="dropdown-item">
                                    <a href={x?.imageSrc} target="blank">
                                      Download Image
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-12 dropdown">
                                <a
                                  className="btn btn-secondary dropdown-toggle p-1 mb-1 col-md-12"
                                  href="#"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Tools{' '}
                                </a>

                                <ul className="dropdown-menu">
                                  <li className="dropdown-item">
                                    <label htmlFor="letters">My Name Color</label>
                                    <input
                                      style={{ background: finalColor[finalColor.length - 1] }}
                                      id="letters"
                                      maxLength={19}
                                      name="letters"
                                      type="text"
                                      onChange={(e) => findwordColor(e)}
                                      value={colorLetters}
                                    />
                                    Colors by letter are:
                                    <div style={{ color: finalColor[finalColor.length - 1] }}>
                                      {finalColor?.map((x) => (
                                        <div
                                          className="col-md-12"
                                          key={x + Math.random()}
                                          style={{ background: x, color: 'black' }}
                                        >
                                          {x}
                                        </div>
                                      ))}
                                    </div>
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() =>
                                      handleFinalRemoveBGClick(
                                        x?.imageSrc,
                                        x?.layer,
                                        JSON.parse(uuid),
                                        index,
                                        x?.imageSrc?.includes('NOBG-') ? true : false
                                      )
                                    }
                                  >
                                    {x?.imageSrc?.includes('NOBG-')
                                      ? 'Undo Background'
                                      : 'Remove Background'}
                                  </li>
                                  <li className="dropdown-item">
                                    <hr />
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      canvasRef?.current
                                        .exportImage('png')
                                        .then((result) => {
                                          const imageData = result;
                                          const data = imageData.data;
                                          if (originalData?.length > 0) {
                                            saveImage(imageData, x?.name);
                                            setonCanvasChange(true);
                                          }
                                        })
                                        .catch((e) => {
                                          toast(e.message);
                                        });
                                    }}
                                  >
                                    Original
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      // Add sepia
                                      canvasRef?.current
                                        .exportImage('png')
                                        .then(async (data) => {
                                          const imageData = data;
                                          if (originalData?.length > 0) {
                                            // original saved before
                                          } else {
                                            await setOriginalData(imageData);
                                          }
                                          for (var i = 0; i < data.length; i += 4) {
                                            let red = data[i],
                                              green = data[i + 1],
                                              blue = data[i + 2];

                                            data[i] = Math.min(
                                              Math.round(
                                                0.393 * red + 0.769 * green + 0.189 * blue
                                              ),
                                              255
                                            );
                                            data[i + 1] = Math.min(
                                              Math.round(
                                                0.349 * red + 0.686 * green + 0.168 * blue
                                              ),
                                              255
                                            );
                                            data[i + 2] = Math.min(
                                              Math.round(
                                                0.272 * red + 0.534 * green + 0.131 * blue
                                              ),
                                              255
                                            );
                                          }
                                          await saveImage(data, x?.name);
                                          setonCanvasChange(true);
                                        })
                                        .catch((e) => {
                                          toast(JSON.stringify(e));
                                        });
                                    }}
                                  >
                                    Sepia
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      // Add sepia
                                      canvasRef?.current
                                        .exportImage('png')
                                        .then((data) => {
                                          const imageData = data;
                                          if (originalData?.length > 0) {
                                            // original saved before
                                          } else {
                                            setOriginalData(imageData);
                                          }
                                          for (var i = 0; i < data.length; i += 4) {
                                            data[i] = 255 - data[i]; // red
                                            data[i + 1] = 255 - data[i + 1]; // green
                                            data[i + 2] = 255 - data[i + 2]; // blue
                                          }
                                          saveImage(imageData, x?.name);
                                          setonCanvasChange(true);
                                        })
                                        .catch((e) => {
                                          toast(e.message);
                                        });
                                    }}
                                  >
                                    Invert
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      //   const imageData =
                                      //     canvasRef?.current
                                      //       ?.getContext('2d')
                                      //       ?.getImageData(0, 0, img?.width, img?.height) ??
                                      //     'nothing poped up';
                                      //   console.log(imageData);
                                      // Add GrayScale
                                      canvasRef?.current
                                        .exportImage('png')
                                        .then((data) => {
                                          const imageData = data;
                                          if (originalData?.length > 0) {
                                            // original saved before
                                          } else {
                                            setOriginalData(imageData);
                                          }
                                          for (var i = 0; i < data.length; i += 4) {
                                            var avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
                                            data[i] = avg; // red
                                            data[i + 1] = avg; // green
                                            data[i + 2] = avg; // blue
                                          }
                                          saveImage(imageData, x?.name);
                                          setonCanvasChange(true);
                                        })
                                        .catch((e) => {
                                          toast(e.message);
                                        });
                                    }}
                                  >
                                    GrayScale
                                  </li>

                                  {/*  Original */}
                                </ul>
                              </div>
                              <div className="col-12 dropdown">
                                <a
                                  className="btn btn-secondary dropdown-toggle p-1 mb-1 col-md-12"
                                  href="#"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Generate NFT
                                </a>
                                <div className="col-md-12  p-3 m-1 dropdown-menu justify-content-center justify-items-center text-align-center">
                                  <label htmlFor="name" className="col-md-12">
                                    Name
                                  </label>
                                  <input
                                    className="col-md-12"
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                  <label htmlFor="description" className="col-md-12">
                                    Description
                                  </label>
                                  <input
                                    className="col-md-12"
                                    id="description"
                                    name="description"
                                    type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                  />
                                  <label htmlFor="copies" className="col">
                                    Copies
                                  </label>
                                  <input
                                    className="col-md-12"
                                    id="copies"
                                    name="copies"
                                    type="number"
                                    max={10}
                                    value={copies}
                                    onChange={(e) => setCopies(e.target.value)}
                                  />
                                  <button
                                    className={`btn btn-primary`}
                                    id="generatebtn"
                                    onClick={(e) => {
                                      generateNFTCallback();
                                    }}
                                  >
                                    Start Generating
                                  </button>
                                </div>
                              </div>
                              <div className="col-12 dropdown">
                                <ul className="col-md-12">
                                  <li
                                    className="col glowNew btn border"
                                    aria-describedby="Save with Background or not, locked means with unlocked means without"
                                    aria-label="Save with Background or not, locked means with unlocked means without"
                                    aria-details="Save with Background or not, locked means with unlocked means without"
                                    onClick={() => {
                                      setsaveWithBackground(!saveWithBackground);
                                      if (saveWithBackground) {
                                        toast('Save without Background');
                                      } else {
                                        toast('Save with Background');
                                      }
                                    }}
                                  >
                                    <FA.FaImage size={20} />
                                    {saveWithBackground ? (
                                      <FA.FaLock size={20} />
                                    ) : (
                                      <FA.FaLockOpen size={20} />
                                    )}
                                  </li>
                                  <li
                                    className="col glowNew btn"
                                    onClick={() => {
                                      canvasRef?.current
                                        .exportImage('png')
                                        .then((data) => {
                                          saveImageToServer(data, x?.imageSrc, x?.name, index).then(
                                            () => {
                                              toast('Done');
                                            }
                                          );
                                        })
                                        .catch((e) => {
                                          toast(e?.message || JSON.stringify(e));
                                        });
                                    }}
                                  >
                                    Save <FA.FaCloudArrowUp size={20} />
                                  </li>

                                  <li
                                    className="col glowNew btn"
                                    onClick={() => {
                                      seteraseMode(!eraseMode);
                                      setTimeout(() => {
                                        canvasRef?.current.eraseMode(eraseMode);
                                      }, 100);
                                    }}
                                  >
                                    <FA.FaEraser size={20} />
                                  </li>
                                  <li
                                    className="col glowNew btn"
                                    onClick={() => {
                                      canvasRef?.current.undo();
                                    }}
                                  >
                                    <FA.FaArrowRotateLeft size={20} />
                                  </li>
                                  <li
                                    className="col glowNew btn"
                                    onClick={() => {
                                      canvasRef?.current.redo();
                                    }}
                                  >
                                    <FA.FaArrowRotateRight size={20} />
                                  </li>
                                </ul>
                              </div>
                            </section>
                            <section className="border mb-1 p-1">
                              <h4>Editor</h4>
                              <div className="col container">
                                <label htmlFor="uploadfile" className="col-md-12">
                                  Upload image
                                </label>
                                <input
                                  type="file"
                                  id="uploadfile"
                                  name="uploadfile"
                                  className="col-md-12"
                                  accept="image/png, image/jpeg, image/svg, image/jpg"
                                  onChange={(e) => {
                                    canvasRef.current.width = e.target.files[0].width;
                                    canvasRef.current.height = e.target.files[0].height;
                                    changebgImageCallback(e, x?.imageSrc, x?.name, index);
                                  }}
                                />
                              </div>
                              <div className="col container">
                                <label htmlFor="stroke" className="col-md-12">
                                  Background
                                </label>
                                <input
                                  className="col-md-12"
                                  id="bgColor"
                                  name="bgColor"
                                  type="color"
                                  value={bgColor}
                                  onChange={(e) => changeBGCallback(e, x?.name, x?.imageSrc, index)}
                                />
                              </div>
                              <div className="col container">
                                <label htmlFor="stroke" className="col-md-12">
                                  Color
                                </label>
                                <input
                                  className="col-md-12"
                                  id="stroke"
                                  name="stroke"
                                  type="color"
                                  value={strokeStyle}
                                  onChange={(e) => {
                                    // setstrokeStyle(e.target.value);
                                    changeStrokeStyleCallback(e, x?.name, x?.imageSrc, index);
                                  }}
                                />
                              </div>
                              <div className="col container">
                                <label htmlFor="stroke" className="col-md-12">
                                  Line Cap
                                </label>
                                <Select
                                  className="col-md-12"
                                  id="lineCap"
                                  name="lineCap"
                                  label="Line Cap"
                                  value={lineCap || 'round'}
                                  onChange={(e) => {
                                    // setlineCap(e.target.value);
                                    changelineCapCallback(e, x?.name, x?.imageSrc, index);
                                  }}
                                >
                                  <MenuItem value={'round'}>Round</MenuItem>
                                  <MenuItem value={'butt'}>Butt</MenuItem>
                                  <MenuItem value={'square'}>Square</MenuItem>
                                </Select>
                              </div>
                              <div className="col container">
                                <label htmlFor="lineWidth" className="col-md-12">
                                  Line Width
                                </label>
                                <input
                                  id="lineWidth"
                                  name="lineWidth"
                                  type="number"
                                  value={lineWidth}
                                  className="col-md-12"
                                  onChange={(e) => {
                                    // setlineWidth(e.target.value);
                                    changeLineWidthCallback(e, x?.name, x?.imageSrc, index);
                                  }}
                                />
                              </div>
                              <div className="col container">
                                <label htmlFor="lineWidth" className="col-md-12">
                                  Layer
                                </label>
                                <input
                                  id="layer"
                                  name="layer"
                                  type="number"
                                  min="1"
                                  value={parseInt(x?.layer?.replaceAll('layer', '')) || 1}
                                  className="col-md-12"
                                  onChange={(e) =>
                                    changeLayerCallback(e, x?.name, x?.imageSrc, index)
                                  }
                                />
                              </div>
                            </section>
                          </>
                        </section>
                        {/* <section>MetaData:{`${window.location.hostname}${x?.metadata} `}</section> */}

                        <div className="col-10 d-flex">
                          <section
                            className="col-10"
                            style={{
                              background: 'black',
                              overflow: 'auto',
                            }}
                          >
                            {/* {loadingPreview ? <Loading /> : []} */}
                            {!onCanvasChange && activeTabName === x?.name && x?.data?.length > 0 ? (
                              <ReactSketchCanvas
                                id={activeTabName}
                                key={activeTabName}
                                ref={canvasRef}
                                width={x?.canvasWidth}
                                height={x?.canvasHeight}
                                strokeWidth={lineWidth}
                                strokeColor={strokeStyle}
                                canvasColor={bgColor}
                                lineCap={lineCap}
                                style={{
                                  // width: '100%',
                                  // height: '100%',
                                  border: 'none',
                                  backgroundSize: '100% 100% !important',
                                }}
                                {...(saveWithBackground && x?.bgImage !== ''
                                  ? { backgroundImage: x?.bgImage }
                                  : {})}
                                {...(saveWithBackground
                                  ? { exportWithBackgroundImage: 'true' }
                                  : {})}
                              />
                            ) : (
                              []
                            )}
                          </section>
                          <section className="col-2">
                            <CustomAvatars
                              handleAvaCallback={(e) => handleAvaCallback(e, x?.imageSrc)}
                            />
                          </section>
                        </div>
                      </div>
                    );
                  })
                : []}
            </div>
          </>
        ) : (
          <Loading />
        )}
      </main>
    </>
  );
}
