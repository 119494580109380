/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import { userStore } from './userStore';

export const submitDetails = async (data) => {
  return new Promise((resolve, reject) => {
    return fetch(`${process.env.REACT_APP_API_CORE}/submitDetails`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    })
      .then((x) => {
        const data = x.json();
        return data;
      })
      .then((data) => {
        resolve(data?.data || data?.nfts || data);
      })
      .catch((err) => {
        reject(err);
      });
  });
  // const data = {
  //   objects: objects,
  //   total: total,
  //   uuid: JSON.parse(localStorage.uuid),
  //   canvasHeight: props.canvasHeight,
  //   canvasWidth: props.canvasWidth,
  //   folderTree: fileData,
  //   name: name,
  //   description: description,
  //   URL: URL,
  // };
};
export const getAllNFTs = async (limit = 20, offset = 0) => {
  return new Promise((resolve, reject) => {
    return fetch(`${process.env.REACT_APP_API_CORE}/random?limit=${limit}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
    })
      .then((x) => x.json())
      .then((data) => {
        userStore.setState({
          listedNFTs: data?.nfts || data,
        });
        resolve(data);
      })
      .catch((err) => {
        // setTimeout(() => {
        //   getAllNFTs();
        // }, 3000);

        resolve([]);
      });
  });
};

export const getOwnNfts = async (uuid) => {
  return new Promise((resolve, reject) => {
    return fetch(`${process.env.REACT_APP_API_CORE}/api/myownnfts`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        uuid: uuid,
      }),
    })
      .then((x) => {
        const data = x.json();
        resolve(data);
      })
      .catch((err) => {
        // setTimeout(() => {
        //   getOwnNfts(uuid);
        // }, 3000);
        resolve([]);
      });
  });
};

export const getCollectionNFTs = async (collection, limit = 10, next = 'nothing') => {
  return new Promise((resolve, reject) => {
    return fetch(
      `${process.env.REACT_APP_API_CORE}/random?collection=${collection}&limit=${limit}${
        next !== 'nothing' ? `&next=${next}` : ''
      }`,
      {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
      }
    )
      .then((x) => {
        const data = x.json();
        return data;
      })
      .then((data) => {
        userStore.setState({
          listedNFTs: data.nfts,
        });
        resolve(data);
      })
      .catch((err) => {
        reject([]);
      });
  });
};
export const getNFT = async (uuid, id, collection, chain) => {
  return new Promise((resolve, reject) => {
    return fetch(`${process.env.REACT_APP_API_CORE}/nft`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ id: id, uuid: uuid, collection: collection, chain: chain }),
    })
      .then((x) => {
        const data = x.json();
        return data;
      })
      .then((data) => {
        userStore.setState({
          listedNFTs: data?.data || data?.nfts || data,
        });
        resolve(data?.data || data?.nfts || data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createNewImage = async (uuid, layer = 0, filename = '') => {
  return new Promise((resolve, reject) => {
    return fetch(`${process.env.REACT_APP_API_CORE}/create-new-image`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        uuid: uuid,
        filename: filename,
        layer: parseInt(layer.replace('layer', '')),
      }),
    })
      .then((x) => {
        const data = x.json();
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getRandomNFTs = () => {
  return new Promise((resolve, reject) => {
    return fetch(`${process.env.REACT_APP_API_CORE}/random`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
    })
      .then((x) => {
        const data = x.json();
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
