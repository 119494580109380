/**
* Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
* https://github.com/ddkits
* https://rymilan.com
* Important: To use this code please leave the copyright in place
* Free NFT website for RYMILAN, https://rymilan.com
*/
/* eslint-disable */

import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { NumberOfCopies, ObjectContext } from './EditingPage';
import './Carousel.css';

export const DemoCarousel = () => {
  const { objects, dispatch1 } = React.useContext(ObjectContext);

  const { total, dispatch3 } = React.useContext(NumberOfCopies);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          fontWeight: 'bold',
          fontSize: '20px',
          fontFamily: 'Times New Roman, serif',
        }}
      >
        REVIEW
      </div>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          fontSize: '20px',
          fontWeight: 500,
          fontFamily: 'Times New Roman, serif',
        }}
      >
        Total Copies Generated Will Be :
      </div>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          fontSize: '25px',
          fontWeight: 500,
          fontFamily: 'Times New Roman, serif',
          animation: 'glow 2s ease-in-out infinite alternate',
          color: 'rgba(255, 255, 255, 0.904)',
        }}
      >
        <h1>{`${total.value}`}</h1>
      </div>
      <div className="d-flex">
        {objects &&
          objects.map((object, xds) => {
            return (
              <div key={xds} className="col">
                <div>
                  <img src={`${object.path}`} alt="img" style={{ maxHeight: '20vh' }} />
                </div>
                <div style={{ fontWeight: 'bold' }}>{`${object?.name} : `}</div>
                <div>{`Height - ${object.height} `}</div>
                <div>{`Width - ${object.width} `}</div>
                <div>{`Depth - ${object.depth} `}</div>
                <div>{`X - ${object.x}`}</div>
                <div>{`Y - ${object.y}`}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
