/**
* Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
* https://github.com/ddkits
* https://rymilan.com
* Important: To use this code please leave the copyright in place
* Free NFT website for RYMILAN, https://rymilan.com
*/
/* eslint-disable */

import React, { useState, useContext, useEffect } from 'react';
import { SliderComponent } from './Slider';
import { ObjectContext, ObjectSelection } from './EditingPage';
import Loading from '../core/Loading';

export const CustomEditor = (props) => {
  const { objects, dispatch1 } = useContext(ObjectContext);
  const { selection, dispatch2 } = useContext(ObjectSelection);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [selection]);

  const commonStyle = {
    margin: '10px',
    backgroundColor: '#c1c9d1',
    padding: '5px',
    borderRadius: '10px',
    boxShadow: '1px 3px 1px #afafaf',
    fontWeight: 'bolder',
    fontFamily: 'monospace',
  };

  const [currentValues, setCurrentValues] = useState([]);

  React.useEffect(() => {
    setCurrentValues(objects[objects?.findIndex((x) => x.name === selection?.name)]);
  }, [objects, selection.name]);

  return loading ? (
    <Loading />
  ) : (
    <div
      style={{
        backgroundColor: '#dee2e7',
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '1px 3px 1px #acacaf',
      }}
    >
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          fontWeight: 'bold',
          fontSize: '20px',
          fontFamily: 'monospace',
        }}
      >
        Quick Update
      </div>

      <div style={commonStyle}>
        Height:
        <SliderComponent
          name={'height'}
          value={currentValues?.length ? currentValues?.height : 0}
        />
      </div>
      <div style={commonStyle}>
        Width:
        <SliderComponent name={'width'} value={currentValues?.width ? currentValues?.width : 0} />
      </div>
      <div style={commonStyle}>
        Depth:
        <SliderComponent
          marks={true}
          name={'depth'}
          value={currentValues?.depth ? currentValues?.depth : 0}
        />
      </div>
      <div style={commonStyle}>
        Rarity:
        <SliderComponent
          marks={true}
          name={'rarity'}
          value={currentValues?.rarity ? currentValues?.rarity : 0}
        />
      </div>
    </div>
  );
};
