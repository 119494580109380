/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import {
  objectReducer,
  selectionReducer,
  totalElementsReducer,
  TreeReducer,
} from './ObjectReducer';
import { Page } from './Page';
import CssBaseline from '@material-ui/core/CssBaseline';
import { NavComponent } from './Navbar';
import axios from 'axios';
import Loading from '../core/Loading';

export const ObjectContext = React.createContext();
export const ObjectSelection = React.createContext();
export const NumberOfCopies = React.createContext();
export const TreeContext = React.createContext();

export const EditingPage = () => {
  const baseURL = process.env.REACT_APP_API_CORE + '/getFolderTree';
  const [fileData, setFileData] = React.useState(null);
  const [loading, setLoading] = useState(true);
  let selection = null;
  let objects = [];
  let total = { value: 100 };

  const getTree = async () => {
    const data = await axios.get(baseURL, {
      params: { uuid: JSON.parse(localStorage.uuid) },
    });
    //const data = await response.json();

    setFileData(data.data);
  };

  React.useEffect(() => {
    getTree();
  }, []);
  const subfoldersLength = fileData && fileData?.children && fileData?.children?.length;

  let hashCodeElement = [];
  const pathList = [];

  useEffect(() => {
    setLoading(false);
  }, [hashCodeElement]);

  for (let i = 0; i < subfoldersLength; i++) {
    fileData && fileData?.children && pathList.push(fileData?.children[i]?.path);
    if (!fileData?.children[i]?.children) {
      fileData.children[i].children = {
        rarity: fileData?.children[i]?.rarity || 50,
        height: fileData?.children[i]?.height || 400,
        width: fileData?.children[i]?.width || 400,
        depth: fileData?.children[i]?.depth || 0,
        x: fileData?.children[i]?.x || 0,
        y: fileData?.children[i]?.y || 0,
      };
    }
  }

  for (let i = 0; i < subfoldersLength; i++) {
    hashCodeElement.push({
      name: fileData?.children?.length ? fileData?.children[i].name : null,
      path: pathList[i],
      children: {
        rarity: 100,
        height: 400,
        width: 400,
        depth: 0,
        x: 0,
        y: 0,
      },
    });
  }

  const getObjects = (files) => {
    const objects = [];

    subfoldersLength &&
      files &&
      files.map((obj) => {
        objects.push({
          name: obj.name,
          path: obj.path,
          uuid: obj.uuid,
          key: Math.random(),
          height: 400,
          width: 400,
          depth: 0,
          x: 0,
          y: 0,
        });
      });
    return objects;
  };

  objects = getObjects(hashCodeElement);

  React.useEffect(() => {
    dispatchMain({ type: 'add', payload: fileData });
    dispatch1({ type: 'add', payload: objects });
    dispatch2({
      type: 'update',
      name: hashCodeElement.length ? hashCodeElement[0].name : null,
    });
  }, [fileData]);
  const newUpdateCallback = () => {
    // new files generated
    // do something
    getTree();
  };
  selection = { name: hashCodeElement[0] };
  total = { value: 100 };

  const [TreeState, dispatchMain] = React.useReducer(TreeReducer, fileData?.children);
  const [ObjectState, dispatch1] = React.useReducer(objectReducer, objects);
  const [SelectionState, dispatch2] = React.useReducer(selectionReducer, selection);
  const [NumberOfCopiesState, dispatch3] = React.useReducer(totalElementsReducer, total);

  return loading ? (
    <Loading />
  ) : (
    <section style={{ zindex: 3 }}>
      <TreeContext.Provider value={{ fileData: TreeState, dispatchMain }}>
        <ObjectContext.Provider value={{ objects: ObjectState, dispatch1 }}>
          <ObjectSelection.Provider value={{ selection: SelectionState, dispatch2 }}>
            <NumberOfCopies.Provider value={{ total: NumberOfCopiesState, dispatch3 }}>
              <CssBaseline>
                <section className="col-md-12 mt-4">
                  <Page
                    folderStructure={fileData}
                    selection={selection}
                    hashedElements={objects}
                    newUpdateCallback={() => newUpdateCallback()}
                    newEditor={false}
                  />
                </section>
              </CssBaseline>
            </NumberOfCopies.Provider>
          </ObjectSelection.Provider>
        </ObjectContext.Provider>
      </TreeContext.Provider>
    </section>
  );
};
