/**
* Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
* https://github.com/ddkits
* https://rymilan.com
* Important: To use this code please leave the copyright in place
* Free NFT website for RYMILAN, https://rymilan.com
*/
/* eslint-disable */

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { generateMyOwnNFt } from '../../EditingPage/Functions';

export default function MyOwn() {
  useEffect(() => {
    generateMyOwnNFt();
  });
  return <>Done Generating</>;
}
