/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

// Using Inline Styling
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

// Exporting Default Navbar to the App.js File
export const NavHomePage = (props) => {
  const classes = useStyles();
  const [openSubMenu, setOpenSubMenu] = useState(false);
  return (
    <nav
      className="navbar navbar-expand-lg sticky-top"
      style={{
        background: 'transparent',
        boxShadow: 'none',
      }}
    >
      <div className="container-fluid">
        <a className="navbar-brand glowNew" href="#">
          RYMILAN
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className="nav-item landingNavMenu text-white h6"
                style={{ fontFamily: 'monospace', textDecoration: 'none' }}
                to={'/'}
              >
                HOME
              </Link>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className="nav-item landingNavMenu text-white h6"
                style={{ fontFamily: 'monospace', textDecoration: 'none' }}
                onClick={() => {
                  props.setInvestOpen(true);
                }}
              >
                INVEST
              </a>
              {/* </Link> */}
              {/* on hover show the box below */}
            </li>
            <li className="nav-item">
              <Link
                className="nav-item landingNavMenu text-white h6"
                style={{ fontFamily: 'monospace', textDecoration: 'none' }}
                to={'/selection'}
              >
                DESIGN YOUR NFT
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-item landingNavMenu text-white h6"
                style={{ fontFamily: 'monospace', textDecoration: 'none' }}
                to={'/avatar-by-name'}
              >
                AVATAR BY NAME
              </Link>
            </li>
            {/* <li className="nav-item">
              <a
                href="#"
                className="nav-item landingNavMenu text-white h6"
                style={{ fontFamily: 'monospace', textDecoration: 'none' }}
                onClick={() => {
                  props.setContactOpen(true);
                }}
              >
                CONTACT
              </a>
            </li> */}

            <li className="nav-item">
              <a
                href="#"
                className="nav-item landingNavMenu text-white h6"
                style={{ fontFamily: 'monospace', textDecoration: 'none' }}
                onClick={() => {
                  props.setAboutOpen(true);
                }}
              >
                ABOUT US
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className="nav-item landingNavMenu text-white h6"
                style={{ fontFamily: 'monospace', textDecoration: 'none' }}
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
