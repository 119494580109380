import { letterToHex, locationToColor } from '../avatars/components/ColorsConvertor';

export const letterNumbers = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
];
export const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
export const colorsName = [
  'Red',
  'Orange',
  'Yellow',
  'Green',
  'Blue',
  'Indigo',
  'Violet',
  'Pink',
  'Purple',
  'Turquoise',
  'Gold',
  'Lime',
  'Maroon',
  'Navy',
  'Coral',
  'Teal',
  'Brown',
  'White',
  'Black',
  'Sky',
  'Berry',
  'Grey',
  'Straw',
  'Silver',
  'Sapphire',
  'Brown',
];
export const customColors = [
  'FF6633',
  'FFB399',
  'FF33FF',
  'FFFF99',
  '00B3E6',
  'E6B333',
  '3366E6',
  '999966',
  '99FF99',
  'B34D4D',
  '80B300',
  '809900',
  'E6B3B3',
  '6680B3',
  '66991A',
  'FF99E6',
  'CCFF1A',
  'FF1A66',
  'E6331A',
  '33FFCC',
  '66994D',
  'B366CC',
  '4D8000',
  'B33300',
  'CC80CC',
  '66664D',
  '991AFF',
  'E666FF',
  '4DB3FF',
  '1AB399',
  'E666B3',
  '33991A',
  'CC9999',
  'B3B31A',
  '00E680',
  '4D8066',
  '809980',
  'E6FF80',
  '1AFF33',
  '999933',
  'FF3380',
  'CCCC00',
  '66E64D',
  '4D80CC',
  '9900B3',
  'E64D66',
  '4DB380',
  'FF4D4D',
  '99E6E6',
  '6666FF',
];

function hex2dec(hex) {
  return hex
    .replace('#', '')
    .match(/.{2}/g)
    .map((n) => parseInt(n, 16));
}
//   hex to rgb
// function hexToRgb(hex) {
//   // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
//   var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
//   hex = hex.replace(shorthandRegex, function (m, r, g, b) {
//     return r + r + g + g + b + b;
//   });

//   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
//   return result
//     ? {
//         r: parseInt(result[1], 16),
//         g: parseInt(result[2], 16),
//         b: parseInt(result[3], 16),
//       }
//     : null;
// }

function rgb2hex(r, g, b) {
  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);
  r = Math.min(r, 255);
  g = Math.min(g, 255);
  b = Math.min(b, 255);
  return '#' + [r, g, b].map((c) => c.toString(16).padStart(2, '0')).join('');
}

function rgb2cmyk(r, g, b) {
  let c = 1 - r / 255;
  let m = 1 - g / 255;
  let y = 1 - b / 255;
  let k = Math.min(c, m, y);
  c = (c - k) / (1 - k);
  m = (m - k) / (1 - k);
  y = (y - k) / (1 - k);
  return [c, m, y, k];
}

function cmyk2rgb(c, m, y, k) {
  let r = c * (1 - k) + k;
  let g = m * (1 - k) + k;
  let b = y * (1 - k) + k;
  r = (1 - r) * 255 + 0.5;
  g = (1 - g) * 255 + 0.5;
  b = (1 - b) * 255 + 0.5;
  return [r, g, b];
}

function mix_cmyks(...cmyks) {
  let c = cmyks.map((cmyk) => cmyk[0]).reduce((a, b) => a + b, 0) / cmyks.length;
  let m = cmyks.map((cmyk) => cmyk[1]).reduce((a, b) => a + b, 0) / cmyks.length;
  let y = cmyks.map((cmyk) => cmyk[2]).reduce((a, b) => a + b, 0) / cmyks.length;
  let k = cmyks.map((cmyk) => cmyk[3]).reduce((a, b) => a + b, 0) / cmyks.length;
  return [c, m, y, k];
}
const mix = function (color_1, color_2, weight) {
  function d2h(d) {
    return d.toString(16);
  } // convert a decimal value to hex
  function h2d(h) {
    return parseInt(h, 16);
  } // convert a hex value to decimal

  weight = typeof weight !== 'undefined' ? weight : 50; // set the weight to 50%, if that argument is omitted

  var color = '#';

  for (var i = 0; i <= 5; i += 2) {
    // loop through each of the 3 hex pairs—red, green, and blue
    var v1 = h2d(color_1.substr(i, 2)), // extract the current pairs
      v2 = h2d(color_2.substr(i, 2)),
      // combine the current pairs from each source color, according to the specified weight
      val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

    while (val.length < 2) {
      val = '0' + val;
    } // prepend a '0' if val results in a single digit

    color += val; // concatenate val to our new color string
  }

  return color; // PROFIT!
};

function mix_hexes(hexes) {
  let rgbs = hexes.map((hex) => hex2dec(hex));
  let cmyks = rgbs.map((rgb) => rgb2cmyk(...rgb));
  let mixture_cmyk = mix_cmyks(...cmyks);
  let mixture_rgb = cmyk2rgb(...mixture_cmyk);
  let mixture_hex = rgb2hex(...mixture_rgb);
  return mixture_hex;
}

/**
 * getColorByLetter
 * @param {string} s
 * @param {number} location
 */
const getColorByLetter = (s, location) => {
  const index = letters.indexOf(s) > -1 ? letters.indexOf(s) : 1;
  const loc = letterNumbers[location >= 0 && location <= 25 ? location : index];
  let color1ToMix = letterToHex(index, loc);
  let color2ToMix = locationToColor(index, loc);
  const newColor = mix(color1ToMix, color2ToMix, Math.floor((index + 1) * (location + 1)));
  //   console.log('new color', s, location, index, newColor);

  return newColor;
};

/**
 * getColorByLetter
 * @param {string} str
 */
export const getColorsByName = (str) => {
  let finalColor = [];
  if (str.replaceAll(' ', '').length < 2) {
    return ['#ff0000'];
  }
  const final = str.replaceAll(' ', '')?.toUpperCase().split('');
  //   console.log(final);
  final.forEach((x, i) => {
    finalColor.push(getColorByLetter(x, i));
  });
  const fResult = mix_hexes(finalColor);
  //   console.log('fResult', fResult);
  finalColor.push(fResult);
  return finalColor;
};
