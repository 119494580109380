import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { userStore } from '../core/userStore';
import { v4 } from 'uuid';
import Loading from '../core/Loading';
import AvatarsByName from '../neweditor/createNew/avatars/AvatarByName';

export default function ByNamezone(props) {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [removeBG, setRemoveBG] = useState(false);
  const [createNew, setCreateNew] = useState(true);
  const [uploadFiles, setuploadFiles] = useState([]);
  const [avatars, setavatars] = useState([]);
  const [id, setId] = useState('');
  const sessionID = userStore((s) => s.sessionID);
  const handleClick = () => {
    window.location.href = '/editing';
  };
  const sessionNow = localStorage.getItem('uuid');
  const messageHandleBack = (e) => {
    setMessages([...messages, e.message]);
  };
  const loadingHandleBack = (e) => {
    setLoading(e);
  };
  const filesHandleBack = (e) => {
    setavatars([...avatars, e]);
  };
  useEffect(() => {
    if (sessionID && sessionID !== '' && sessionID !== 'null' && sessionID !== null) {
      localStorage.setItem('uuid', sessionID);
      setId(sessionID);
    } else {
      if (sessionNow !== '' && sessionNow !== null) {
        setId(sessionNow);
        userStore.setState({
          sessionID: sessionNow,
        });
      } else {
        const ID = { uuid: v4() };
        userStore.setState({
          sessionID: JSON.stringify(ID.uuid),
        });
        localStorage.setItem('uuid', JSON.stringify(ID.uuid));
        // axios.post(process.env.REACT_APP_API_CORE + '/saveID', ID);
        setId(ID.uuid);
      }
    }
    setLoading(false);
  }, []);

  return (
    <section className="container inner-container">
      <section className="col-md-12">
        <h1
          style={{
            fontFamily: 'monospace',
          }}
        >
          Avatar By Name
        </h1>
        <div className="bg-white text-black col-md-12 text-center">{id ? JSON.parse(id) : ''}</div>
        <div
          className="form-group col-md-12"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#1313133b',
            borderRadius: '5px',
            padding: '10px',
          }}
        >
          <div
            className="form-control col-md-12"
            style={{
              fontFamily: 'monospace',
              fontSize: '30px',
              display: 'flex',
              justifyContent: 'center',
              color: '#000',
            }}
          >
            {loading ? <Loading /> : `Create Avatar`}
          </div>

          <AvatarsByName
            className="form-control col-md-12"
            fileHandleBack={(e) => filesHandleBack(e)}
            messageHandleBack={(e) => messageHandleBack(e)}
            loadingHandleBack={(e) => loadingHandleBack(e)}
            removeBG={removeBG}
          />
        </div>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            marginTop: '50px',
            paddingBottom: '10px',
          }}
        ></div>
      </section>
    </section>
  );
}
