/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React from 'react';

export default function HeroSection(props) {
  return (
    <>
      <section className="hero-area p-5 inner-container">
        <div className="title mt-5 mb-1" style={{ fontFamily: 'monospace' }}>
          <h1
            style={{
              fontFamily: 'monospace',
              backgroundBlendMode: 'red',
            }}
          >
            RYMILAN NFTs Space
          </h1>
          <h2
            className="homepageContent"
            style={{
              backgroundBlendMode: 'red',
              bottom: '0',
              fontSize: '30px',
              fontFamily: 'monospace',
              fontWeight: '400',
              animation: 'glow 2s ease-in-out infinite alternate',
            }}
          >
            A Web3 tool to take the pain of creating NFTs
          </h2>
          <h3
            className="homepageContent"
            style={{
              backgroundBlendMode: 'yellow',
              bottom: '0',
              fontSize: '30px',
              fontFamily: 'monospace',
              fontWeight: '400',
              animation: 'glow 2s ease-in-out infinite alternate',
            }}
          >
            Away from you.
          </h3>
        </div>
      </section>
    </>
  );
}
