/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import * as React from 'react';
import { Backdrop } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import { Fade } from '@material-ui/core';

export const AboutModalComponent = (props) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.isOpen}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.isOpen}>
          <Box className="model-popup">
            <p style={{ color: '#fff', fontFamily: 'monospace' }}>
              {`
              RYMILAN NFTs Space
              A Web3 tool to take the pain of creating NFTs Away from designers.
              
              RAMILAN (Ryan and Milan), kids who are passionate and in love with animals.
               Did you ever ask yourself what the reasons led us to treat Tigers differently than
              cats,
               and dogs differently than wolfs, and why predators are different than humans, while
              both eating meat and living on it?
               Our mission is a simple message "Imagine living safer with animals among us,
              protecting us, and helping us" Imagine animals are living among us,
               Tigers are our forces, or you live safe with the power of preditors.Help us to
              share our message around,
               protecting our planet from the ideologies of humans, not a predator.`}
            </p>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
