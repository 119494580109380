import { createAvatar } from '@dicebear/core';
import {
  lorelei,
  avataaars,
  micah,
  personas,
  miniavs,
  adventurer,
  shapes,
  notionists,
  croodles,
  bottts,
  openPeeps,
} from '@dicebear/collection';
import {
  arrayOfnames,
  getrandomitem,
  optionsMicahTypes,
  optionsPersonaTypes,
} from './AvatarsOptions';
import { getColorsByName } from '../colorSelector/NameColors';
import { getType } from './components/AvatarsTypes';

const nameCustomList = [
  'Time',
  'Past',
  'Future',
  'Dev',
  'Fly',
  'Flying',
  'Soar',
  'Soaring',
  'Power',
  'Falling',
  'Fall',
  'Jump',
  'Cliff',
  'Mountain',
  'Rend',
  'Red',
  'Blue',
  'Green',
  'Yellow',
  'Gold',
  'Demon',
  'Demonic',
  'Panda',
  'Cat',
  'Kitty',
  'Kitten',
  'Zero',
  'Memory',
  'Trooper',
  'XX',
  'Bandit',
  'Fear',
  'Light',
  'Glow',
  'Tread',
  'Deep',
  'Deeper',
  'Deepest',
  'Mine',
  'Your',
  'Worst',
  'Enemy',
  'Hostile',
  'Force',
  'Video',
  'Game',
  'Donkey',
  'Mule',
  'Colt',
  'Cult',
  'Cultist',
  'Magnum',
  'Gun',
  'Assault',
  'Recon',
  'Trap',
  'Trapper',
  'Redeem',
  'Code',
  'Script',
  'Writer',
  'Near',
  'Close',
  'Open',
  'Cube',
  'Circle',
  'Geo',
  'Genome',
  'Germ',
  'Spaz',
  'Shot',
  'Echo',
  'Beta',
  'Alpha',
  'Gamma',
  'Omega',
  'Seal',
  'Squid',
  'Money',
  'Cash',
  'Lord',
  'King',
  'Duke',
  'Rest',
  'Fire',
  'Flame',
  'Morrow',
  'Break',
  'Breaker',
  'Numb',
  'Ice',
  'Cold',
  'Rotten',
  'Sick',
  'Sickly',
  'Janitor',
  'Camel',
  'Rooster',
  'Sand',
  'Desert',
  'Dessert',
  'Hurdle',
  'Racer',
  'Eraser',
  'Erase',
  'Big',
  'Small',
  'Short',
  'Tall',
  'Sith',
  'Bounty',
  'Hunter',
  'Cracked',
  'Broken',
  'Sad',
  'Happy',
  'Joy',
  'Joyful',
  'Crimson',
  'Destiny',
  'Deceit',
  'Lies',
  'Lie',
  'Honest',
  'Destined',
  'Bloxxer',
  'Hawk',
  'Eagle',
  'Hawker',
  'Walker',
  'Zombie',
  'Sarge',
  'Capt',
  'Captain',
  'Punch',
  'One',
  'Two',
  'Uno',
  'Slice',
  'Slash',
  'Melt',
  'Melted',
  'Melting',
  'Fell',
  'Wolf',
  'Hound',
  'Legacy',
  'Sharp',
  'Dead',
  'Mew',
  'Chuckle',
  'Bubba',
  'Bubble',
  'Sandwich',
  'Smasher',
  'Extreme',
  'Multi',
  'Universe',
  'Ultimate',
  'Death',
  'Ready',
  'Monkey',
  'Felix',
  'Anika',
  'Elevator',
  'Wrench',
  'Grease',
  'Head',
  'Theme',
  'Grand',
  'Cool',
  'Kid',
  'Boy',
  'Girl',
  'Vortex',
  'Paradox',
  'Felix',
  'Aneka',
];
const nameList = ['Felix', 'Aneka'];
export const avaRand = {
  lorelei: lorelei,
  openpeeps: openPeeps,
  botts: bottts,
  notionists: notionists,
  croodles: croodles,
  avataars: avataaars,
  micah: micah,
  personas: personas,
  miniavs: miniavs,
  adventurer: adventurer,
  shapes: shapes,
};
const createAvatarNew = (avaType, options) => {
  return new Promise((resolve, reject) => {
    try {
      const avatar = createAvatar(avaRand[avaType], options);
      const svg = avatar.png().toDataUri();
      resolve(svg);
    } catch (error) {
      reject(error);
    }
  });
};

export const generator = async (
  avaType,
  gender,
  count,
  uuid,
  custom = false,
  options = [],
  customChar = false
) => {
  let avatarsNow = [];
  avatarsNow.images = [];
  avatarsNow.metadata = [];
  let c = [];
  return new Promise((resolve, reject) => {
    try {
      for (let index = 0; index < count; index++) {
        var randomColor = Math.floor(Math.random() * 16777215).toString(16);
        var randomColor2 = Math.floor(Math.random() * 16777215).toString(16);
        var randomColor3 = Math.floor(Math.random() * 16777215).toString(16);
        let customname = custom !== 'false' ? custom : getrandomitem(arrayOfnames);

        c[index] =
          customChar && custom !== 'false' ? options : getType(avaType, gender, customname);
        console.log(c[index], customChar, custom);
        createAvatarNew(avaType, c[index]).then((x) => {
          avatarsNow['images'].push(x);
          const metadata = {
            details: JSON.stringify(c[index]),
            power: Math.floor(Math.random() * 10 * index),
            health: Math.floor(Math.random() * 10 + index),
            stamin: Math.floor(Math.random() * 10 + index),
            money: Math.floor(Math.random() * index),
            name: `${customname}`,
          };
          avatarsNow['metadata'].push(metadata);
        });
      }
      setTimeout(() => {
        avatarsNow.images = avatarsNow.images.filter((x) => x);
        avatarsNow.metadata = avatarsNow.metadata.filter((x) => x);
        resolve(avatarsNow);
      }, (count / 5) * 600);
    } catch (error) {
      reject(error);
    }
  });
};
