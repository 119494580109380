/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

const baseURL = process.env.REACT_APP_API_CORE + '/getFolderTree';

export const getTree = async () => {
  await fetch(baseURL, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
  }).then((x) => {
    const data = x.json();
    return data;
  });
};

export const generateMyOwnNFt = async () => {
  await fetch(`${process.env.REACT_APP_API_CORE}/createmyown`, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
  }).then((x) => {
    const data = x.json();
    return data;
  });
};
export const deleteAnImage = async (path, uuid) => {
  await fetch(`${process.env.REACT_APP_API_CORE}/api/deletefile`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ path: path, uuid: uuid, file: path }),
  }).then((x) => {
    const data = x.json();
    return data;
  });
};
export const updateImage = async (body, image, uuid) => {
  new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_CORE}/api/updateFile`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ data: body, uuid: uuid, file: image.path }),
    })
      .then((x) => {
        const data = x?.json() || x?.text();
        resolve(data);
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        reject(err);
      });
  });
};

export const getRandomString = (length) => {
  var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return '1234';
};

export const formatFile = (fileData) => {
  const subfoldersLength = fileData && fileData.children && fileData.children.length;

  const hashCodeElement = [];
  const pathList = [];

  for (let i = 0; i < subfoldersLength; i++) {
    fileData && fileData.children && pathList.push(fileData.children[i].children[0].path);
  }

  for (let i = 0; i < subfoldersLength; i++) {
    hashCodeElement.push({ name: getRandomString(4), path: pathList[i] });
  }

  const objects = [];

  subfoldersLength &&
    hashCodeElement &&
    hashCodeElement.map((obj) => {
      objects.push({
        name: obj.name,
        path: obj.path,
        height: 10,
        width: 10,
        depth: 0,
        x: 0,
        y: 0,
      });
    });

  return { objects, hashCodeElement };
};
