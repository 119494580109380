/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { userStore } from '../core/userStore';
import { useParams } from 'react-router-dom';
import HeroSection from './HeroSection';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getNFT } from '../core/APIs';
import metaTags from '../core/MetaTags';
import Loading from '../core/Loading';

export default function InvestPage(props) {
  const [mounted, setMounted] = useState(false);
  const [openAbout, setAboutOpen] = useState(false);
  const [openContact, setContactOpen] = useState(false);
  const [openInstructions, setInstructionsOpen] = useState(false);
  const [nft, setnft] = useState([]);
  const uuid = userStore((s) => s.sessionID);
  const syncedImages = userStore((s) => s.listedNFTs);
  const { collection, chain, item } = useParams();
  useEffect(() => {
    console.log(item, collection);
    getNFT(uuid, item, collection, chain).then(async (data) => {
      await setnft(data?.nft);
      const nftN = data?.nft;
      // metatags
      metaTags({
        sitename: 'RYMILAN',
        title: nftN?.name,
        author: nftN?.creator,
        description: `${nftN?.description}`,
        keywords: `${chain},${nftN?.name},${nftN?.token_standard},${nftN?.collection},rymilan, ryan, milan, nft, nfts, free nft, win nft, build free nft, mint nft, mint, opensea, win, winner, free`,
        image: nftN?.image_url,
      });
      setTimeout(() => {
        setMounted(true);
      }, 100);
    });
  }, []);

  return !mounted ? (
    <Loading />
  ) : (
    <>
      <section className="container-fluid p-5 invest justify-item-center inner-container">
        <div className="nfts-wrap">
          <img className="nfts-bg" src={nft?.image_url} alt={nft?.name} />
          <div className="container row nfts-content">
            <section className="image col-md-6">
              <img src={nft?.image_url} className="img-responsive" alt={nft?.name} />
              <img
                width={100}
                height={100}
                style={{
                  position: 'absolute',
                  opacity: '0.7',
                  width: '30px',
                  bottom: '30px',
                  right: '30px',
                }}
                src="https://opensea.io/static/images/logos/opensea-logo.svg"
              />
            </section>
            <section className="image col-md-6 bg-dark text-white">
              <div className="col-md-12 p-2 box-shadow">
                Title:
                <br /> <b>{nft?.name}</b>
              </div>
              <div className="col-md-12 p-2 box-shadow text-truncate" style={{ maxWidth: '100px' }}>
                Owner:
                <br /> <b>{nft?.creator}</b>
              </div>
              <div className="col-md-12 p-2 box-shadow">
                Description:
                <br /> <b>{nft?.description}</b>
              </div>
              <div className="col-md-12 p-2 box-shadow">
                Created:
                <br /> <b>{nft?.created_at}</b>
              </div>

              <div className="col-md-12 p-2 box-shadow">
                Token Standard:
                <br /> <b>{nft?.token_standard}</b>
              </div>

              <div className="col-md-12 p-2 box-shadow">
                Collection:
                <br /> <b>{nft?.collection}</b>
              </div>
              {/*  */}
              <a
                target="blank"
                href={`https://opensea.io/assets/${chain}/${nft?.contract}/${nft?.identifier}`}
                className="btn center btn-primary position-relative p-2 box-shadow"
              >
                <img
                  width={100}
                  height={100}
                  style={{
                    position: 'relative',
                    opacity: '1',
                    width: '30px',
                    alignSelf: 'center',
                  }}
                  src="https://opensea.io/static/images/logos/opensea-logo.svg"
                />
                Buy Now OpenSea
              </a>
            </section>
          </div>
        </div>
      </section>
    </>
  );
}
