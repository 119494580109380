/**
* Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
* https://github.com/ddkits
* https://rymilan.com
* Important: To use this code please leave the copyright in place
* Free NFT website for RYMILAN, https://rymilan.com
*/
/* eslint-disable */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../assets/logo.png';
// Using Inline Styling
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    primary: {
      main: '000',
    },
  },
}));

// Exporting Default Navbar to the App.js File
export const NavComponent = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root} style={{ color: '#212529' }}>
      <AppBar position="sticky" style={{ background: 'rgb(16, 16, 32)' }}>
        <Toolbar variant="dense">
          <img style={{ maxWidth: '50px', marginLeft: '0.5vw' }} src={logo} alt="DDKitsNFT" />

          <Typography
            variant="h6"
            color="inherit"
            style={{
              fontFamily: 'monospace',
              animation: 'glow 2s ease-in-out infinite alternate',
              marginLeft: '0.5vw',
            }}
          >
            <a className="landingNavMenu" href="/">
              DDKits NFT Generator
            </a>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};
