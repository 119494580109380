/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';

export default function NewFormDyna(props) {
  const { typeNow, x, valueNow, avaType } = props;
  const [mounted, setMounted] = useState(false);
  const [tempValue, settempValue] = useState('');
  const [tempValueArr, settempValueArr] = useState('');
  const [tempValueNumber, settempValueNumber] = useState(true);
  const setOptionsValue = (x, e, array) => {
    e.preventDefault();
    const v = e.target.value;
    // color changes
    if (x.includes('Color') && avaType !== 'adventurer') {
      props?.setOptionsValues(x, [v.replaceAll('#', '')], array);
    } else {
      props?.setOptionsValues(x, v, array);
    }
  };
  useEffect(() => {
    setMounted(true);
  }, [props]);
  const optionsByObject = () => {
    // color changes
    if (x.includes('Color') && avaType !== 'adventurer') {
      return (
        <div
          name={avaType + x}
          id={avaType + x}
          key={avaType + x + Math.random()}
          className="col-md-3 bg-white p-1 border"
        >
          <label htmlFor={avaType + x} className="form-control">
            {x}
          </label>
          <input
            name={avaType + x}
            id={avaType + x}
            type="color"
            onChange={(e) => setOptionsValue(x, e, true)}
            value={valueNow}
          />
        </div>
      );
    }
    switch (typeNow) {
      case 'string':
        return (
          <div
            name={avaType + x}
            id={avaType + x}
            key={avaType + x + Math.random()}
            className="col-md-3 bg-white p-1 border"
          >
            <label htmlFor={avaType + x} className="form-control">
              {x}
            </label>
            <input
              name={avaType + x}
              className="col-md-12 bg-white p-1 border"
              id={avaType + x}
              key={avaType + x + Math.random()}
              type="text"
              onChange={(e) => setOptionsValue(x, e, false)}
              value={valueNow}
            />
          </div>
        );
      case 'number':
        return (
          <div
            name={avaType + x}
            id={avaType + x}
            key={avaType + x + Math.random()}
            className="col-md-3 bg-white p-1 border"
          >
            {' '}
            <label htmlFor={avaType + x} className="form-control">
              {x}
            </label>
            <input
              name={avaType + x}
              id={avaType + x}
              className="col-md-12 bg-white p-1 border"
              key={avaType + x + Math.random()}
              type="range"
              min={x == 'size' ? 50 : 0}
              max={x == 'size' ? valueNow + 2000 : 100}
              onChange={(e) => setOptionsValue(x, e, false)}
              defaultValue={valueNow}
            />
          </div>
        );
      case 'object':
        return (
          <div
            name={avaType + x}
            id={avaType + x}
            key={avaType + x + Math.random()}
            className="col-md-3 bg-white p-1 border"
          >
            <label htmlFor={avaType + x} className="form-control">
              {x}
            </label>
            <select
              className="col-md-12 bg-white p-1 border"
              name={avaType + x}
              id={avaType + x}
              key={avaType + x + Math.random()}
              multiple={false}
              onChange={(e) => setOptionsValue(x, e, true)}
            >
              {typeof valueNow === 'object' &&
                valueNow.map((y) => (
                  <option
                    key={y + Math.random() + Math.random()}
                    value={y}
                    {...(valueNow === y ? { selected: true } : '')}
                  >
                    {y}
                  </option>
                ))}
            </select>
          </div>
        );

      case 'boolean':
        return (
          <div
            name={avaType + x}
            id={avaType + x}
            key={avaType + x + Math.random()}
            className="col-md-3 bg-white p-1 border"
          >
            <label htmlFor={avaType + x} className="form-control">
              {x}
            </label>
            <select
              className="col-md-12 bg-white p-1 border"
              name={avaType + x}
              multiple={false}
              onChange={(e) => setOptionsValue(x, e, true)}
              id={avaType + x}
              key={avaType + x + Math.random()}
            >
              <option value={true} {...(valueNow === x ? { selected: true } : '')}>
                TRUE
              </option>
              <option value={false} {...(valueNow === x ? { selected: true } : '')}>
                FALSE
              </option>
            </select>
          </div>
        );

      default:
        return <div className="col-md-3 p-1 bg-white border">Soon {x}</div>;
    }
  };
  if (!mounted) return <></>;
  return optionsByObject();
}
