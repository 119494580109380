/**
* Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
* https://github.com/ddkits
* https://rymilan.com
* Important: To use this code please leave the copyright in place
* Free NFT website for RYMILAN, https://rymilan.com
*/
/* eslint-disable */

import React, { useState, useContext, useRef } from 'react';
import { CustomEditor } from './CustomEditor';
import { Items } from './Items';
import { NumberOfCopies, ObjectContext, ObjectSelection } from './EditingPage';
import { EditorInput } from './EditorInput';
import TreesTemp from './FolderStructure';
import { Button } from '@material-ui/core';
import './Page.css';
import { ModalComponent } from './Modal';
import { LoadingModalComponent } from './loadingModal';
import axios from 'axios';
import { RarityModalComponent } from './RarityModal';

export const Page = (props) => {
  const { dispatch1 } = useContext(ObjectContext);
  const { selection, dispatch2 } = useContext(ObjectSelection);
  const { dispatch3 } = useContext(NumberOfCopies);
  const [totalCopies, setTotalCopies] = useState({ value: 0 });
  const [open, setOpen] = useState(false);
  const [rarityOpen, setRarityOpen] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [coord, setCoor] = useState({ x: 0, y: 0 });

  const [canvasHeight, setCanvasHeight] = useState({
    value: 400,
  });
  const [canvasWidth, setCanvasWidth] = useState({
    value: 400,
  });

  const setCurrentElement = (val) => {
    dispatch2({
      type: 'update',
      name: val,
    });
  };

  var parentRef = useRef(null);

  const handleMouseOver = (e) => {
    const parent = parentRef.current.getBoundingClientRect();
    const rect = e.target.getBoundingClientRect();

    const width = rect.width;
    const positionX = rect.left - parent.left;
    const positionY = rect.top - parent.top;

    //console.log(`width: ${width}, position: ${positionX} , ${positionY}`);
    const values = { x: positionX, y: positionY };

    return values;
  };

  const setCoord = (event, file) => {
    const curr_Coor = handleMouseOver(event);

    dispatch2({
      type: 'update',
      name: `${file.name}`,
    });
    dispatch1({
      type: 'update',
      nameToFind: selection.name,
      valueToChange: 'x',
      currentSlide: curr_Coor.x,
    });
    dispatch1({
      type: 'update',
      nameToFind: selection.name,
      valueToChange: 'y',
      currentSlide: curr_Coor.y,
    });

    setCoor({ x: curr_Coor.x, y: curr_Coor.y });
  };

  const editValues = (input1, input2, input4) => {
    if (input1.value) {
      dispatch1({
        type: 'update',
        nameToFind: selection.name,
        valueToChange: input1.name,
        currentSlide: input1.value,
      });
    }

    if (input2.value) {
      dispatch1({
        type: 'update',
        nameToFind: selection.name,
        valueToChange: input2.name,
        currentSlide: input2.value,
      });
    }

    if (input4.value) {
      dispatch3({
        type: 'update',
        value: input4.value,
      });

      setTotalCopies({ value: input4.value });
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleRarityOpen = () => {
    setRarityOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRarityClose = () => {
    setRarityOpen(false);
  };

  const openLoadingModal = () => {
    setLoadingModal(true);
  };
  const newUpdateCallback = () => {
    props?.newUpdateCallback();
  };

  return (
    <div className="row bg-dark inner-container">
      <div
        style={{
          backgroundColor: 'rgb(23, 23, 44, 0.6)',
          height: '100vh',
          padding: '5px',
          borderRadius: '10px',
          overflow: 'auto',
          transition: 'width .35s',
        }}
        className="col-md-3"
      >
        <TreesTemp folderData={props.folderStructure} />
      </div>
      <div
        style={{
          height: '100vh',
        }}
        className="col-md-7 p-1"
      >
        <div className="col-md-12 ">
          <Items
            onClick={setCurrentElement}
            files={props.folderStructure}
            hashedFolder={props.hashedElements}
            imageHeight={canvasHeight.value}
            imageWidth={canvasWidth.value}
            setCoord={setCoord}
            parent={parentRef}
            newEditor={props?.newEditor}
          />

          <div
            style={{
              backgroundColor: 'rgba(110, 110, 110, 0.658, 0.6)',
              fontFamily: 'monospace',
              paddingRight: '5px',
              position: 'absolute',
              bottom: '1px',
              left: '1px',
              right: '1px',
            }}
          >
            <p>{JSON.stringify(selection).name}</p>
          </div>
        </div>
      </div>
      <div className="col-md-2 p-1">
        <div className="row">
          <CustomEditor currentValues={props.hashedElements} />

          <div></div>
          <div>
            <EditorInput setValues={editValues} newUpdateCallback={() => newUpdateCallback()} />
          </div>
          <div>
            {/* <div style={{ justifyContent: 'center', display: 'flex' }}>
              <Button
                variant="contained"
                style={{
                  borderRadius: 35,
                  backgroundColor: '#f50057',
                  color: '#fff',
                  padding: '10px 20px',
                  fontSize: '13px',
                }}
                size="medium"
                onClick={handleRarityOpen}
              >
                Rarity
              </Button>
            </div> */}
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                marginTop: '15px',
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: '#21b6ae', color: '#fff' }}
                size="large"
                onClick={totalCopies && totalCopies.value > 10000 ? null : handleOpen}
              >
                Generate
              </Button>
            </div>
            <div>
              <RarityModalComponent
                isOpen={rarityOpen}
                handleClose={handleRarityClose}
                folderStructure={props?.folderStructure}
              />
            </div>

            <div>
              <ModalComponent
                isOpen={open}
                handleClose={handleClose}
                canvasHeight={canvasHeight.value}
                canvasWidth={canvasWidth.value}
                openLoadingModal={openLoadingModal}
              />
            </div>
            <div>
              <LoadingModalComponent isOpen={loadingModal} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
