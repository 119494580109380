/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React, { Component, useState, useEffect } from 'react';
import { NavHomePage } from './navigationBar';
import { userStore } from '../core/userStore';
import HeroSection from './HeroSection';
import HomepageSection from './HomepageSection';
import HomepageSection2 from './HomepageSection2';
import HomepageSection3 from './HomepageSection3';

export const Pages = (props) => {
  return (
    <>
      <section className="d-block container-fluid">
        <HeroSection />
      </section>
      <section className="d-block container-fluid">
        <HomepageSection2 />
      </section>
      <section className="d-block container-fluid">
        <HomepageSection />
      </section>

      {/*<section className="d-block container-fluid">
        <HomepageSection3 />
      </section> */}
    </>
  );
};
