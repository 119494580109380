/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { userStore } from './userStore';

export default function Loading(props) {
  const [mounted, setMounted] = React.useState(false);
  const width = props.width || '100%';
  const syncedImages = userStore((s) => s.listedNFTs);
  if (!mounted) {
    return <></>;
  }
  return (
    <Box sx={{ width: '100%' }} className="area inner-container zindex-5 loading-now">
      <LinearProgress className="area inner-container zindex-5" />
      {syncedImages.length > 0 ? (
        <ul className="circles area inner-container zindex-5">
          {syncedImages.map((x) => (
            <li key={x?.identifier}>
              <img src={x?.image_url} />
            </li>
          ))}
        </ul>
      ) : (
        []
      )}
    </Box>
  );
}
