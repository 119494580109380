/**
* Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
* https://github.com/ddkits
* https://rymilan.com
* Important: To use this code please leave the copyright in place
* Free NFT website for RYMILAN, https://rymilan.com
*/
/* eslint-disable */

import * as React from 'react';
import { Backdrop } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import { Fade } from '@material-ui/core';

export const InstructionsModalComponent = (props) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.isOpen}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.isOpen}>
          <Box className="model-popup">
            <p style={{ color: '#fff', fontFamily: 'monospace' }}>This is a instructions project</p>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
