/**
* Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
* https://github.com/ddkits
* https://rymilan.com
* Important: To use this code please leave the copyright in place
* Free NFT website for RYMILAN, https://rymilan.com
*/
/* eslint-disable */

import * as React from 'react';
import { Backdrop } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import { Fade } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const InvestWithUs = (props) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.isOpen}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.isOpen}>
          <Box className="model-popup align-items-center">
            <section>
              <h2
                className="center"
                style={{
                  backgroundBlendMode: 'red',
                  bottom: '0',
                  fontSize: '45px',
                  color: 'white',
                  fontFamily: 'monospace',
                  fontWeight: '400',
                  animation: 'glow 2s ease-in-out infinite alternate',
                }}
              >
                Our Collections
              </h2>
              <div className="container row">
                {/* item one */}
                <div className="col-md-3 shadow-lg m-1 p1">
                  <a href="/invest/matic/rymilan-animals-among-us">
                    <img
                      width={'100%'}
                      height={'auto'}
                      src="https://i.seadn.io/gcs/files/114bf9c98a61f3035bca7e9a77adc14a.png?auto=format&dpr=1&w=2048"
                    ></img>
                    <h3 className=" overlay-text glowNew">Chain Polygon: Animals Among Us</h3>
                  </a>
                </div>
                <div className="col-md-3 shadow-lg m-1 p1">
                  <a href="/invest/matic/rymilan-mistica">
                    <img
                      width={'100%'}
                      height={'auto'}
                      src="https://i.seadn.io/gcs/files/eb7463b2828688ec11f1ac2f049a5f40.png?auto=format&dpr=1&w=1920"
                    ></img>
                    <h3 className=" overlay-text glowNew">Chain Polygon: Mistica</h3>
                  </a>
                </div>
                <div className="col-md-3 shadow-lg m-1 p1">
                  <a href="/invest/ethereum/rymilan-eth">
                    <img
                      width={'100%'}
                      height={'auto'}
                      src="https://i.seadn.io/gcs/files/1b3a2d5a7e46d96a848310b052dbbd29.png?auto=format&dpr=1&w=256"
                    ></img>
                    <h3 className=" overlay-text glowNew">Chain Ethereum: Rymilan ETH</h3>
                  </a>
                </div>
              </div>
            </section>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
