/**
 * Built by Sam Ayoub <melayyoub@outlook.com> ddkits.com
 * https://github.com/ddkits
 * https://rymilan.com
 * Important: To use this code please leave the copyright in place
 * Free NFT website for RYMILAN, https://rymilan.com
 */
/* eslint-disable */

import React, { createRef, useState } from 'react';
import './style.css';
import { useEffect } from 'react';
import { userStore } from '../core/userStore';
import { v4 } from 'uuid';
import DrawNewImage from '../neweditor/createNew/DrawNewImage';
import { Button } from '@mui/material';

export const Selection = () => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [removeBG, setRemoveBG] = useState(false);
  const [createNew, setCreateNew] = useState(true);
  const [uploadFiles, setuploadFiles] = useState([]);
  const [id, setId] = useState('');
  const sessionID = userStore((s) => s.sessionID);
  const handleClick = () => {
    window.location.href = '/editing';
  };
  const sessionNow = localStorage.getItem('uuid');
  const messageHandleBack = (e) => {
    setMessages([...messages, e.message]);
  };
  const loadingHandleBack = (e) => {
    setLoading(e);
  };
  const fileHandleBack = (e) => {
    setMessages([...messages, e]);
  };
  useEffect(() => {
    if (sessionID && sessionID !== '' && sessionID !== 'null' && sessionID !== null) {
      localStorage.setItem('uuid', sessionID);
      setId(sessionID);
    } else {
      if (sessionNow !== '' && sessionNow !== null) {
        setId(sessionNow);
        userStore.setState({
          sessionID: sessionNow,
        });
      } else {
        const ID = { uuid: v4() };
        userStore.setState({
          sessionID: JSON.stringify(ID.uuid),
        });
        localStorage.setItem('uuid', JSON.stringify(ID.uuid));
        // axios.post(process.env.REACT_APP_API_CORE + '/saveID', ID);
        setId(ID.uuid);
      }
    }
  }, []);

  return (
    <div
      style={{
        height: 'auto',
        width: '100vw',
      }}
      className="col-md-12 container-fluid inner-container p-0 m-0 area"
    >
      {/* <button className="button btn btn-primary pull-end" onClick={() => setCreateNew(!createNew)}>
        {createNew ? 'Upload files' : 'Create New'}
      </button> */}
      {/* {createNew ? ( */}
      <section className="container-fluid inner-container">
        {/* <h1
            style={{
              fontFamily: 'monospace',
            }}
          >
            Design your NFT
          </h1> */}

        <div className=" inner-container">
          <DrawNewImage />
        </div>
      </section>
      {/* ) : (
        <section className="col-md-12">
          <h1
            style={{
              fontFamily: 'monospace',
            }}
          >
            Upload Files
          </h1>

          <div
            style={{
              width: '40vw',
              marginLeft: '30vw',
              height: '50vh',
              backgroundColor: '#1313133b',
              marginTop: '10px',
              borderRadius: '5px',
              padding: '10px',
            }}
          >
            <div
              style={{
                fontFamily: 'monospace',
                fontSize: '30px',
                display: 'flex',
                justifyContent: 'center',
                color: '#000',
              }}
            >
              {loading ? <Loading /> : `Upload Files`}
            </div>

            <MyDropzone
              fileHandleBack={(e) => fileHandleBack(e)}
              messageHandleBack={(e) => messageHandleBack(e)}
              loadingHandleBack={(e) => loadingHandleBack(e)}
              removeBG={removeBG}
            />
            <section
              style={{
                background: 'rgb(0,0,0, 0.7)',
                fontFamily: 'monospace',
                fontSize: '10px',
                display: 'block',
                justifyContent: 'left',
                color: '#FFF',
                overflow: 'auto',
                height: '30%',
              }}
            >
              Logs:
              <ul>
                {messages.length > 0 &&
                  messages.map((x, xds) => <li key={xds * Math.random()}>{x}</li>)}
              </ul>
            </section>
          </div>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              marginTop: '50px',
              paddingBottom: '10px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="center"
              disabled={loading}
              onClick={() => setRemoveBG(!removeBG)}
            >
              {removeBG ? `Remove Backgrounds of all images` : 'Background as is'}
            </Button>
            <br />
            <Button
              variant="contained"
              color="secondary"
              size="large"
              disabled={loading}
              onClick={handleClick}
            >
              Continue
            </Button>
          </div>
        </section>
      )} */}
      {/* <Button
        variant="contained"
        color="primary"
        size="large"
        className="center"
        disabled={loading}
        onClick={() => setRemoveBG(!removeBG)}
      >
        {removeBG ? `Remove Backgrounds of all images` : 'Background as is'}
      </Button>
      <br />
      <Button
        variant="contained"
        color="secondary"
        size="large"
        disabled={loading}
        onClick={handleClick}
      >
        Continue
      </Button> */}
    </div>
  );
};
